import {
  addDays,
  differenceInDays,
  differenceInYears,
  format,
  parse,
  subDays
} from 'date-fns';
import {
  DKIcons,
  INPUT_TYPE,
  TOAST_TYPE,
  showAlert,
  showToast
} from 'deskera-ui-library';
import { ToWords } from 'to-words';
import ic_backorder_list from '../Assets/Icons/ic_backorder_list.svg';
import ic_recurring from '../Assets/Icons/recurring.svg';
import ic_recurring_light from '../Assets/Icons/recurring_light.svg';
import ic_draft from '../Assets/menu/ic_draft.svg';
import { INVOICE_NOW_TYPES } from '../Components/Invoices/InvoiceHelper';
import { CLASS_TITLE } from '../Components/Settings/Classes/ClassesConstant';
import { GranularPermissionsHelper } from '../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  COMMON_CONSTANT,
  COUNTRY_CODES,
  COUNTRY_SPECIFIC_URL_CODE,
  CURRENCIES,
  CURRENCY_PRECISION,
  CUSTOMER_TYPE,
  CUSTOM_FIELD_TYPE,
  CUST_EXPORT_WO_PAY,
  CUST_EXPORT_W_PAY,
  CUST_NA,
  CUST_SEZ_WO_PAY,
  CUST_SEZ_W_PAY,
  DATE_FORMAT,
  DEEMED_EXPORT,
  DOCUMENT_TYPE,
  DOC_TYPE,
  ERROR,
  FILTER_KEYS,
  FILTER_OPERATORS,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  GST_TYPE,
  JAVASCRIPT_DATA_TYPE,
  LOCALECODE_EN_IN,
  LOCATION_CLASS_ENUM,
  MOBILE_APP_ACTIONS,
  MODULE_TYPE,
  MONTHS,
  MONTHS_FULL,
  OVERSEAS,
  PAYMENT_STATUS,
  PRODUCT_TYPE,
  RECEIVED_GOODS_STATUS,
  RECURRING_DOCUMENT_TYPE,
  REGEX,
  REGISTERED_BUSINESS_COMPOSITION,
  REGISTERED_BUSINESS_REGULAR,
  SPECIAL_ECONOMIC_ZONE,
  STATUS_TYPE,
  TAX_SYSTEM,
  TENANT_IDS_FOR_PRONTO_DASHBOARD,
  TIME_TYPES,
  TIME_TYPES_CONSTANT,
  TRACKING_TYPE,
  UNREGISTERED_BUSINESS,
  UOM_NA_ID,
  VENDOR_IMPORT,
  VENDOR_NA,
  VENDOR_SEZ_WO_PAY,
  VENDOR_SEZ_W_PAY,
  QTY_ROUNDOFF_PRECISION,
  MODULES_NAME,
  BACKGROUND_COLOR_PALLETE_DARK,
  CLASS_ASSIGNMENT,
  GOOGLE_NO_TRANSLATE_CLASS,
  REQUISTIION_ENTITY_MAPPING,
  COLUMN_TYPE,
  DECIMAL_SCALE,
  QTY_ROUNDOFF_PRECISION_BACKEND,
  GRN
} from '../Constants/Constant';
import {
  COMPLIANCE_SPECIFIC_FIELD_NAME,
  CURRENCY,
  NUMBER_FORMAT,
  PRODUCTS,
  RETURN_MODE
} from '../Constants/Enum';
import { currencyDenominations } from '../Constants/StaticData';
import { COLUMN_CODE, REMOTE_CONFIG_TABLES } from '../Constants/TableConstants';
import { TableManager } from '../Managers/TableManager';
import {
  BooksAddress,
  BooksAddressFormatted,
  IAddress
} from '../Models/Interfaces';
import { RowUpdateType } from '../Models/Table';
import { addAccountsColumnConfig } from '../Redux/Slices/AccountsSlice';
import { addAuditLogColumnConfig } from '../Redux/Slices/AuditLogSlice';
import {
  addAutomationColumnConfig,
  fetchapprovalConditionList
} from '../Redux/Slices/AutomationSlice';
import { addBankRulesColumnConfig } from '../Redux/Slices/BankRules';
import { addBankTransactionsColumnConfig } from '../Redux/Slices/BankTransactions';
import { addBanksColumnConfig } from '../Redux/Slices/Banks';
import { addBillColumnConfig } from '../Redux/Slices/BillsSlice';
import { updateFilter } from '../Redux/Slices/BookFilterSlice';
import { addCoaColumnConfig } from '../Redux/Slices/CoaSlice';
import { addContactColumnConfig } from '../Redux/Slices/ContactsSlice';
import { addCreditNoteColumnConfig } from '../Redux/Slices/CreditNoteSlice';
import { addCurrencyColumnConfig } from '../Redux/Slices/CurrencySlice';
import { addDebitNoteColumnConfig } from '../Redux/Slices/DebitNoteSlice';
import { addDepositColumnConfig } from '../Redux/Slices/DepositSlice';
import { addDeskeraTransactionsColumnConfig } from '../Redux/Slices/DeskeraTransactions';
import { addDraftColumnConfig } from '../Redux/Slices/DraftsSlice';
import { addExpenseColumnConfig } from '../Redux/Slices/ExpenseSlice';
import { addFinancialYearClosingColumnConfig } from '../Redux/Slices/FinancialYearClosingSlice';
import { addImportLogColumnConfig } from '../Redux/Slices/ImportLogSlice';
import { addInventoryColumnConfig } from '../Redux/Slices/InventorySlice';
import { addInvoiceColumnConfig } from '../Redux/Slices/InvoicesSlice';
import { addJournalColumnConfig } from '../Redux/Slices/Journal';
import { addOBInvoiceColumnConfig } from '../Redux/Slices/OBInvoicesSlice';
import { addPaymentTermsColumnConfig } from '../Redux/Slices/PaymentTermsSlice';
import { addPriceColumnConfig } from '../Redux/Slices/PriceList';
import { addProductColumnConfig } from '../Redux/Slices/ProductsSlice';
import { addOrderColumnConfig } from '../Redux/Slices/PurchaseOrdersSlice';
import { addQuoteColumnConfig } from '../Redux/Slices/QuotesSlice';
import { addSalesOrderColumnConfig } from '../Redux/Slices/SalesOrderSlice';
import { addStockAdjustmentColumnConfig } from '../Redux/Slices/StockAdjustmentSlice';
import { addStockTransferColumnConfig } from '../Redux/Slices/StockTransferSlice';
import { addTaxColumnConfig } from '../Redux/Slices/TaxSlice';
import { addUomSchemaColumnConfig } from '../Redux/Slices/UomSchemaSlice';
import { addUomColumnConfig } from '../Redux/Slices/UomSlice';
import { addWarehouseColumnConfig } from '../Redux/Slices/WarehouseSlice';
import { Store as ReduxStore, Store } from '../Redux/Store';
import AuthService from '../Services/Auth';
import DateFormatService from '../Services/DateFormat';
import NumberFormatService from '../Services/NumberFormat';
import WarehouseManagementHelper from '../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import i18n from '../i18n/i18n';
import { isTabletView } from './ViewportSizeUtils';
import RouteManager from '../Managers/RouteManager';
import { DocumentItem } from '../Models/DocumentItem';
import StepsManager from '../Components/ImportExport/import/StepsManager';
import AutomationService from '../Services/Automation';
import { addWorkOrderColumnConfig } from '../Redux/Slices/MRP/WorkOrderSlice';
import { addOperationsColumnConfig } from '../Redux/Slices/MRP/OperationSlice';
import { addOperatorColumnConfig } from '../Redux/Slices/MRP/OperatorSlice';
import { addReportSODetailsColumnConfig } from '../Redux/Slices/ReportSalesOrderSlice';
import { addMRPReportsSKUOrderTrackingDetailsColumnConfig } from '../Redux/Slices/MRP/SKUOrderTrackingSlice';
import {
  addMachineSchedulesColumnConfig,
  addMachineToolMasterColumnConfig
} from '../Redux/Slices/MRP/MachineToolMasterSlice';
import { isEmpty as _isEmpty, isNumber as _isNumber } from 'lodash';
import { addGRNColumnConfig } from '../Redux/Slices/GRNSlice';
import { addFulfillmentReportColumnConfig } from '../Redux/Slices/FufillmentReportSlice';
import { isMRPEnable } from '../Components/Automation/AutomationConstants';
import { selectUsersList } from '../Redux/Slices/PermissionsSlice';
import { STOCK_TRANSFER } from '../Components/TabletDashboard/TabletDashboardHelper';
import { addRFQColumnConfig } from '../Components/RequestForQuotation/RequestForQuotationForm/RequestForQuotationFormSlice';
import { roundOff } from '../SharedComponents/DocumentForm/NewDocumentHelper';
import { addWorkoutColumnConfig } from '../Redux/Slices/WorkOutSlice';
import { calculateQtyDiffAccordingSchema } from '../SharedComponents/FulfillmentPopup/FulfilmentGridHelper';
import { t } from 'i18next';

const toWords = new ToWords({
  localeCode: LOCALECODE_EN_IN,
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false
  }
});

const NUMBER_FORMATS: any = {
  US: 'us',
  IN: 'in',
  FR: 'fr',
  ES: 'es'
};

// const store = Store;

export function getVW(value: any) {
  //   return value / PIXEL_TO_VW + "vw";
}

export function formatNPWPNumber(npwpString: string) {
  if (!!npwpString) {
    let num = npwpString.toString().replaceAll(/[^0-9]/g, '');
    if (num.length <= 2) {
      return num.replace(/(\d{2})/, '$1');
    } else if (num.length <= 5) {
      return num.replace(/(\d{2})/, '$1.');
    } else if (num.length <= 8) {
      return num.replace(/(\d{2})(\d{3})/, '$1.$2.');
    } else if (num.length <= 9) {
      return num.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2.$3.');
    } else if (num.length <= 12) {
      return num.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3.$4-');
    } else {
      return num.replace(
        /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})/,
        '$1.$2.$3.$4-$5.'
      );
    }
  } else {
    return npwpString;
  }
}

export function getCapitalized(str: string) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function isData(data: any) {
  return data !== null && data !== undefined;
}

export function toCurrencyFormat(num: number) {
  return (
    '$ ' +
    parseFloat('' + num)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
}

export function toKFormat(num: number) {
  return Math.abs(num) > 999
    ? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'K'
    : Math.sign(num) * Math.abs(num);
}

export function getRandomAlphaNumericString(length: number) {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function getRandomHexString(length = 24) {
  const chars = '0123456789abcdef';
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function getRandomNumber(number = 1000) {
  return Math.floor(Math.random() * number);
}

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

// Get date-fns date formats from tenant's or book's date format
export const convertBooksDateFormatToDateFnsFormat = (
  dateFormat: string
): string => {
  const formatsMap: { [key: string]: string } = {
    'dd-mm-yyyy': 'dd-MM-yyyy',
    'DD-MM-YYYY': 'dd-MM-yyyy',
    'yyyy-mm-dd': 'yyyy-MM-dd'
  };

  return formatsMap[dateFormat];
};

export const convertBooksDateFormatToUILibraryFormat = (
  dateFormat: string
): string => {
  const formatsMap: { [key: string]: string } = {
    [BOOKS_DATE_FORMAT['DD-MM-YYYY']]: 'dd-MM-yyyy',
    [BOOKS_DATE_FORMAT['MM-DD-YYYY']]: 'MM-dd-yyyy',
    [BOOKS_DATE_FORMAT['YYYY-MM-DD']]: 'yyyy-MM-dd',
    [BOOKS_DATE_FORMAT['DD/MM/YYYY']]: 'dd/MM/yyyy',
    [BOOKS_DATE_FORMAT['MM/DD/YYYY']]: 'MM/dd/yyyy',
    [BOOKS_DATE_FORMAT['D MMM YYYY']]: 'd MMM yyyy'
  };

  return formatsMap[dateFormat.toUpperCase()];
};

// Get locale string from number format
export const getLocaleFromTenantNumberFormat = (
  numberFormat: NUMBER_FORMAT
) => {
  const numberFormatMap: { [key: string]: string } = {
    [NUMBER_FORMAT.ES]: 'es',
    [NUMBER_FORMAT.FR]: 'fr',
    [NUMBER_FORMAT.IN]: 'en-IN',
    [NUMBER_FORMAT.US]: 'en-US'
  };
  return numberFormatMap[numberFormat];
};

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export function isMacintosh() {
  var deviceDetect = navigator.platform;
  var appleDevicesArr = [
    'MacIntel',
    'MacPPC',
    'Mac68K',
    'Macintosh',
    'iPhone',
    'iPod',
    'iPad',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad Simulator',
    'Pike v7.6 release 92',
    'Pike v7.8 release 517'
  ];

  if (appleDevicesArr.includes(deviceDetect)) {
    return true;
  }

  return false;
}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export function swapArrayElement(
  arr: any[],
  fromIndex: number,
  toIndex: number
) {
  var b = arr[fromIndex];
  arr[fromIndex] = arr[toIndex];
  arr[toIndex] = b;
  return arr;
}

export function shiftArrayElement(
  arr: any[],
  fromIndex: number,
  toIndex: number
) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export function sortArray(arr: any[], sortOrder: string, keyToSort: string) {
  return arr.sort(function (obj1, obj2) {
    const i1 = sortOrder === 'ASC' ? obj1[keyToSort] : obj2[keyToSort];
    const i2 = sortOrder === 'ASC' ? obj2[keyToSort] : obj1[keyToSort];
    if (!isNaN(i1)) {
      return Number(i1) - Number(i2);
    }
    if (i1 < i2) {
      return -1;
    }
    if (i1 > i2) {
      return 1;
    }
    return 0;
  });
}

export default class Utility {
  static getTimeFromSeconds(seconds: any) {
    if (!this.isNumber(seconds)) {
      return 'NA';
    }
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    let HH = (hours < 10 ? '0' : '') + hours;
    let MM = (minutes < 10 ? '0' : '') + minutes;
    let SS = (remainingSeconds < 10 ? '0' : '') + remainingSeconds;

    return HH + ':' + MM + ':' + SS;
  }
  static convertMMToPx(mm: number) {
    return 3.7795275591 * mm;
  }
  static compareDateWithoutTime(date1: Date, date2: Date) {
    let d1 = this.resetTime(date1);
    let d2 = this.resetTime(date2);
    return this.compareDates(d1, d2);
  }
  static resetTime(date: Date) {
    let copy = new Date(date.getTime());
    copy.setTime(
      copy.getTime() -
        (copy.getHours() * 60 * 60 * 1000 +
          copy.getMinutes() * 60 * 1000 +
          copy.getSeconds() * 1000 +
          copy.getMilliseconds())
    );
    return copy;
  }

  static decimalCurrencies = currencyDenominations;

  static encodeString(json: any) {
    let temp = encodeURI(json);
    return btoa(temp);
  }

  static decodeString(base64String: string) {
    return JSON.parse(atob(base64String));
  }
  static compareDates(date1: Date, date2: Date) {
    if (date1.getTime() - date2.getTime() < 0) {
      return -1;
    } else if (date1.getTime() - date2.getTime() > 0) {
      return 1;
    }
    return 0;
  }
  static encodeStringForTemplate(json: any) {
    return btoa(unescape(encodeURIComponent(json)));
  }

  static decodeStringForTemplate(base64String: string) {
    return JSON.parse(decodeURIComponent(escape(atob(base64String))));
  }
  ////////////////////////////////////////////////

  ///////////////////// JSON encryption ///////////////////////////
  static encodeJSON(json: any) {
    let temp = encodeURI(JSON.stringify(json));
    return btoa(temp);
  }

  static decodeJSON(base64String: string) {
    return JSON.parse(decodeURI(atob(base64String)));
  }
  ////////////////// END - JSON encryption /////////////////////////

  ///////////////////// HTML encryption ///////////////////////////
  static encodeHTML(html: string) {
    let temp = encodeURI(html);
    return btoa(temp);
  }
  static daysInYear(year: any) {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
  }

  static decodeHTML(base64String: string) {
    return decodeURI(atob(base64String));
  }
  ////////////////// END - HTML encryption /////////////////////////

  static copyToClipBoard(textToCopy: string) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => showToast('Copied!!!', TOAST_TYPE.SUCCESS));
  }

  static mapToCurrencyHistoryList(data: any) {
    if (data === null || data === undefined) {
      return [];
    }
    return data?.map((curr: any) => {
      return {
        ...curr,
        exchangeRateDate: curr?.date,
        currencyCode: curr?.primaryCurrency,
        currencyExchangeRate: curr?.exchangeRate
      };
    });
  }

  static isDevice() {
    if (window.screen.width <= 1142) {
      return true;
    }
    return false;
  }
  static openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  static isValidNumber(num: string) {
    num = num.replace('-', '');
    return num.match(/^\d*\.?\d*$/) !== null;
  }

  static isValidEmail(email: string) {
    if (email.trim() === '') {
      return false;
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static isValidURL(str: string) {
    var res = str.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  static isValidDate(str: string | Date) {
    const date = new Date(str);
    return Boolean(date.getTime());
  }

  static checkPositiveNumber(num: any) {
    let pattern = /^(?!0\d)\d*(\.\d+)?$/gm;
    return pattern.test(num);
  }

  static sanitizeJSON(jsonString: string) {
    return jsonString.replace(REGEX.HTML_PATTERN, '');
  }

  static isString = (value: unknown) => {
    return (
      typeof value === 'string' ||
      value instanceof String ||
      Object.prototype.toString.call(value) === '[object String]'
    );
  };

  ////////////////////////////////////////////////////////
  //////////// START - LOCAL STORAGE FEATURES ////////////
  ////////////////////////////////////////////////////////

  static setPersistentValue(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  static getPersistentValue(key: string) {
    return localStorage.getItem(key);
  }

  static getURLOptionValue(urlOption: any) {
    return urlOption === undefined || urlOption === null
      ? false
      : urlOption.toLowerCase() === 'true'
      ? true
      : false;
  }

  // list of countries for which ORG creation API requires country code in URL
  static countriesRequiringCodeForCreation = ['IL'];

  static isUserOwner() {
    var userInfo;
    if (!Utility.isEmpty(Store.getState().authInfo.userInfo.data)) {
      userInfo = Store.getState().authInfo.userInfo.data[0];
    }
    const roleShortInfo = userInfo ? userInfo.roleShortInfo : {};
    let erpRole = roleShortInfo.find(
      (item: any) => item.appName.toLowerCase() === PRODUCTS.ORGANISATION
    );
    if (!Utility.isEmpty(erpRole)) {
      return erpRole.shortCode.toLowerCase().includes('owner');
    }
    return false;
  }

  static escapeRegex = (str: string) => {
    return str.replace(/([.*+?^${}()|[\]\\])/g, '\\$1');
  };

  static isEmpty = (value: any) => {
    if (value === null || value === undefined || value.length === 0)
      return true;
    if (Array.isArray(value) || typeof value === 'string') return !value.length;
    return Object.keys(value).length === 0;
  };

  static isEmpty_v2 = (value: any) => _isEmpty(value);

  static validateAgainstPattern(string: any, pattern: any) {
    return pattern.test(string);
  }
  static makeCopyOfObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static nonZeroLength(text: string | number | bigint): boolean {
    if (
      text &&
      (typeof text === JAVASCRIPT_DATA_TYPE.STRING ||
        typeof text === JAVASCRIPT_DATA_TYPE.NUMBER ||
        typeof text === JAVASCRIPT_DATA_TYPE.BIGINT) &&
      text.toString().length > 0
    ) {
      return true;
    }
    return false;
  }

  static isObject(obj: object, canBeEmpty: boolean = false): boolean {
    if (
      obj &&
      typeof obj === JAVASCRIPT_DATA_TYPE.OBJECT &&
      !Array.isArray(obj)
    ) {
      if ((!canBeEmpty && Object.keys(obj).length > 0) || canBeEmpty) {
        return true;
      }
      return false;
    }
    return false;
  }

  static nonEmptyArray(array: any[]): boolean {
    if (array && Array.isArray(array) && array.length > 0) {
      return true;
    }
    return false;
  }

  static isBoolean(bool: boolean): boolean {
    if (typeof bool === JAVASCRIPT_DATA_TYPE.BOOLEAN) {
      return true;
    }
    return false;
  }

  static areObjectsEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true;

    if (obj1 instanceof Date && obj2 instanceof Date)
      return obj1.getTime() === obj2.getTime();

    if (
      !obj1 ||
      !obj2 ||
      (typeof obj1 !== 'object' && typeof obj2 !== 'object')
    )
      return obj1 === obj2;

    if (obj1.prototype !== obj2.prototype) return false;

    const keys = Object.keys(obj1);
    if (keys.length !== Object.keys(obj2).length) return false;

    return keys.every((k) => this.areObjectsEqual(obj1[k], obj2[k]));
  };

  static generateAddress(
    contactName: string,
    address: string,
    address2: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    mobileCountryCode: string,
    mobileNumber: string,
    emailId: string
  ) {
    return {
      contactName: contactName,
      address1: address,
      address2,
      city,
      state,
      country,
      postalCode,
      preferred: true,
      mobileCountryCode,
      mobileNumber,
      emailId
    };
  }

  static getKeyOfColumn(columnConfig: any, columnCode: any) {
    return columnConfig.filter(
      (column: any) => column.columnCode === columnCode
    );
  }

  static getKeysForDraftColumns(draftsColumnConfig: any) {
    const type: any = Utility.getKeyOfColumn(
      draftsColumnConfig,
      COLUMN_CODE.DRAFTS.Type
    );
    const payload: any = Utility.getKeyOfColumn(
      draftsColumnConfig,
      COLUMN_CODE.DRAFTS.Payload
    );
    const isMaximized: any = Utility.getKeyOfColumn(
      draftsColumnConfig,
      COLUMN_CODE.DRAFTS.isMaximized
    );
    const isCenterAlign: any = Utility.getKeyOfColumn(
      draftsColumnConfig,
      COLUMN_CODE.DRAFTS.isCenterAlign
    );
    const isSaved: any = Utility.getKeyOfColumn(
      draftsColumnConfig,
      COLUMN_CODE.DRAFTS.isSaved
    );

    return { type, payload, isMaximized, isCenterAlign, isSaved };
  }

  static formatDate(date: Date, dateFormat: DATE_FORMAT) {
    let formattingOptions = {
      minimumIntegerDigits: 2,
      useGrouping: false
    };
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = '' + d.getHours().toLocaleString('en-US', formattingOptions),
      minutes = '' + d.getMinutes().toLocaleString('en-US', formattingOptions),
      seconds = d.getSeconds().toLocaleString('en-US', formattingOptions);

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    switch (dateFormat) {
      case DATE_FORMAT.DD_MM_YYYY:
        return [day, month, year].join('-');
      case DATE_FORMAT.YYYY_MM_DD:
        return [year, month, day].join('-');
      case DATE_FORMAT.DD_MM_YYYY_HH_MM_SS:
        return `${[year, month, day].join('-')} ${[hour, minutes, seconds].join(
          ':'
        )}`;
      case DATE_FORMAT.DD_MM_YYYYTHH_MM_SS:
        return `${[year, month, day].join('-')}T${[hour, minutes, seconds].join(
          ':'
        )}${this.getTimeZoneOffsetValue()}`;
      case DATE_FORMAT.HH_MM_SS:
        return `${[hour, minutes, seconds].join(':')}`;
      default:
        return [day, month, year].join('-');
    }
  }

  static roundOffToTenantDecimalScale(val: number, decimalScale?: number) {
    const tenantDecimalScale =
      Store.getState().authInfo.currentTenantInfo.data.decimalScale;
    return Utility.roundingOff(
      val,
      decimalScale ? decimalScale : tenantDecimalScale ? tenantDecimalScale : 2
    );
  }

  static roundOff(val: number, decimalScale: number = 2) {
    return Utility.roundingOff(val, decimalScale);
  }

  static roundingOff(
    val: number,
    precisionVal = COMMON_CONSTANT.CURRENCY_PRECISION
  ) {
    val = Number(val);
    val = val + 1 / Math.pow(10, precisionVal + 10);
    var newnumber =
      Math.round(val * Math.pow(10, precisionVal)) / Math.pow(10, precisionVal);
    return newnumber;
  }

  static getNumberWithFixedDecimal(val: number, precisionVal = 6) {
    // return Number(Number(val).toFixed(precisionVal));
    let factor = Math.pow(10, precisionVal);
    return Math.trunc(val * factor) / factor;
  }
  static isUSorg() {
    const country = Store.getState().authInfo.currentTenantInfo.data?.country;
    return country === 'US';
  }
  static isDPLSettingEnabled() {
    return (
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings?.[
        'DENIED_PARTIES_SETTING'
      ] ?? false
    );
  }

  static isSGOrg() {
    const country = Store.getState().authInfo.currentTenantInfo.data?.country;
    return country === COUNTRY_CODES.SG;
  }

  static isUKOrg() {
    const country = Store.getState().authInfo.currentTenantInfo.data?.country;
    return country === COUNTRY_CODES.UK;
  }

  static isNonTaxCollectingTenant() {
    const complianceEnabled =
      Store.getState().authInfo.currentTenantInfo.data?.complianceEnabled;
    return Utility.isUSorg() && complianceEnabled === false;
  }
  static isLocationEnabled() {
    const locationEnabled =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings[
        'LOCATION'
      ]?.trackLocation;
    return locationEnabled;
  }

  static isClassEnabled() {
    const classEnabled =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings[
        'CLASS'
      ]?.trackClasses;
    return classEnabled;
  }

  static isClassMandatory() {
    const isMandatory =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings[
        'CLASS'
      ]?.mandatory;
    return this.isClassEnabled() && isMandatory;
  }

  static isClassRowType() {
    const assignClasses =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings[
        'CLASS'
      ]?.assignClasses;
    return assignClasses === CLASS_ASSIGNMENT.ROW;
  }

  static isClassTransactionType() {
    const assignClasses =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings[
        'CLASS'
      ]?.assignClasses;
    return assignClasses === CLASS_ASSIGNMENT.TRANSACTION;
  }

  static isLocationMandatory() {
    const isMandatory =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings[
        'LOCATION'
      ]?.mandatory;
    return this.isLocationEnabled() && isMandatory;
  }

  static isProcessManufacturingEnabled() {
    return Store.getState().authInfo.currentTenantInfo.data.additionalSettings
      .LINK_INVENTORY_JOB_CARDS;
  }

  static updateCustomField(
    docData: any,
    customFieldCode: string,
    columnData: RowUpdateType,
    customFieldColumnConfig?: any
  ) {
    if (docData && customFieldCode && columnData) {
      let updatedDoc = deepClone(docData);
      let isCfExists: boolean = false;
      updatedDoc.customField &&
        updatedDoc.customField.forEach((cf: any) => {
          if (cf.code === customFieldCode) {
            cf.value =
              customFieldColumnConfig?.type === INPUT_TYPE.SELECT
                ? customFieldColumnConfig?.options.find(
                    (x: any) =>
                      x.id === columnData.rowData[columnData.columnKey][0]
                  )?.name
                : columnData.rowData[columnData.columnKey];
            isCfExists = true;
            return;
          }
        });
      if (!isCfExists) {
        const customField = {
          label: customFieldColumnConfig?.name,
          code: customFieldCode,
          module: null,
          value: customFieldColumnConfig?.options.find(
            (x: any) => x.id === columnData.rowData[columnData.columnKey][0]
          )?.name,
          customFieldIndex: null,
          status: STATUS_TYPE.ACTIVE
        };
        if (!docData.customField || docData.customField.length === 0) {
          updatedDoc.customField = [customField];
        } else {
          updatedDoc.customField.push(customField);
        }
      }
      return updatedDoc;
    }
    return null;
  }

  static buildAddress = (address: IAddress): any => {
    if (address) {
      return {
        contactName: address.contactName,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        country: address.country,
        postalCode: address.postalCode,
        preferred: address.preferred,
        state: address.state,
        customFields: address?.customFields
      };
    } else {
      return null;
    }
  };
  static getPreferredAddress = (entity: any, type: string) => {
    let address = null;
    if (entity && entity[type] && entity[type].length > 0) {
      address = entity[type].find((addr: any) => addr && addr.preferred);
      if (!address) {
        address = entity[type][0];
      }
      address = Utility.buildAddress(address);
    }
    return address;
  };

  static getStringAddress(entity: any, type: string = 'billingAddress') {
    const address = Utility.getPreferredAddress(entity, type);
    let addressParts = [];
    if (address) {
      for (let key in address) {
        addressParts.push(address[key]);
      }
    }
    return addressParts.length
      ? addressParts.filter((key) => !!key).join()
      : '';
  }

  static checkSezForSales(
    shipToState: string,
    contactType: string,
    gstTreatment: string,
    tenantState?: string
  ) {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    const isRegisteredToSez = tenantDetails.sezOrImportExport;
    const isRegisteredToComposition =
      tenantDetails.registeredToCompositionScheme;
    const tenantShipAddress = Utility.getPreferredAddress(
      tenantDetails,
      'shippingAddresses'
    );
    let shipFromState = tenantShipAddress.state;
    if (!Utility.isEmpty(tenantState)) {
      shipFromState = tenantState;
    }
    if (contactType === undefined || contactType === null) {
      return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
    }

    if (gstTreatment === SPECIAL_ECONOMIC_ZONE) {
      if (contactType === CUST_SEZ_W_PAY) {
        return GST_TYPE.INTER;
      }

      if (contactType === CUST_SEZ_WO_PAY) {
        return GST_TYPE.EXEMPT;
      }
    } else if (
      gstTreatment === REGISTERED_BUSINESS_REGULAR &&
      contactType === CUST_SEZ_WO_PAY
    ) {
      return GST_TYPE.EXEMPT;
    } else if (isRegisteredToSez) {
      return Utility.getSalesTaxTypeIndiaForRegisteredSez(
        contactType,
        shipFromState,
        shipToState
      );
    } else if (isRegisteredToComposition) {
      return Utility.getIndiaSalesTaxTypeForComposition(
        shipFromState,
        shipToState
      );
    } else if (
      !isRegisteredToSez &&
      !isRegisteredToComposition &&
      (CUST_NA === contactType ||
        CUST_EXPORT_W_PAY === contactType ||
        CUST_EXPORT_WO_PAY === contactType)
    ) {
      return this.getSalesTaxTypeIndiaForUnregisteredSez(
        shipFromState,
        shipToState,
        contactType
      );
    } else {
      return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
    }
  }

  static getVendorGstType(
    shipFromState: string,
    vendorType: string,
    gstTreatment: string,
    applyRCM = false,
    destinationState?: any
  ) {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    const tenantShipAddress = Utility.getPreferredAddress(
      tenantDetails,
      'shippingAddresses'
    );
    const isRegisteredToSez = tenantDetails.sezOrImportExport;

    let shipToState = tenantShipAddress.state;
    if (!Utility.isEmpty(destinationState)) {
      shipToState = destinationState;
    }
    switch (gstTreatment) {
      case REGISTERED_BUSINESS_COMPOSITION:
        if (VENDOR_NA === vendorType) {
          return GST_TYPE.EXEMPT;
        }
        break;
      case REGISTERED_BUSINESS_REGULAR:
        if (VENDOR_SEZ_WO_PAY === vendorType) {
          return GST_TYPE.EXEMPT;
        } else if (VENDOR_SEZ_W_PAY === vendorType && isRegisteredToSez) {
          return GST_TYPE.INTER;
        } else {
          return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
        }
      case UNREGISTERED_BUSINESS:
        if (applyRCM) {
          return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
        }
        return GST_TYPE.EXEMPT;
      case CUSTOMER_TYPE:
        if (VENDOR_NA === vendorType) {
          return GST_TYPE.EXEMPT;
        }
        break;
      case SPECIAL_ECONOMIC_ZONE:
        if (VENDOR_SEZ_W_PAY === vendorType) {
          return GST_TYPE.INTER;
        } else if (VENDOR_SEZ_WO_PAY === vendorType) {
          return GST_TYPE.EXEMPT;
        }
        break;
      case OVERSEAS:
        if (VENDOR_IMPORT === vendorType) {
          return GST_TYPE.INTER;
        }
        break;
      case DEEMED_EXPORT:
        if (VENDOR_NA === vendorType) {
          return GST_TYPE.EXEMPT;
        }
        break;
      default:
        return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
    }
    return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
  }

  static getSalesTaxTypeIndiaForComposition(
    shipFromState: any,
    shipToState: any
  ) {
    if (Utility.isShipFromAndShipToStateSame(shipFromState, shipToState)) {
      return GST_TYPE.INTRA;
    }
  }

  static getCustomerGstType(
    tenantDetails: any,
    shipToState: string,
    contactType: string,
    gstTreatment: string
  ) {
    const isRegisteredToSez =
      tenantDetails && tenantDetails.sezOrImportExport
        ? tenantDetails.sezOrImportExport
        : false;
    const isRegisteredToComposition =
      tenantDetails && tenantDetails.registeredToCompositionScheme
        ? tenantDetails.registeredToCompositionScheme
        : false;

    const tenantShipAddress = Utility.getPreferredAddress(
      tenantDetails,
      'shippingAddresses'
    );
    const shipFromState =
      tenantShipAddress && tenantShipAddress.state
        ? tenantShipAddress.state
        : '';

    switch (gstTreatment) {
      case SPECIAL_ECONOMIC_ZONE:
        if (CUST_SEZ_W_PAY == contactType) {
          return GST_TYPE.INTER;
        } else if (CUST_SEZ_WO_PAY == contactType) {
          return GST_TYPE.EXEMPT;
        }
        break;
      case OVERSEAS:
        if (CUST_EXPORT_W_PAY == contactType) {
          return GST_TYPE.INTER;
        } else if (CUST_EXPORT_WO_PAY == contactType) {
          return GST_TYPE.EXEMPT;
        }
        break;
      case DEEMED_EXPORT:
        if (isRegisteredToComposition) {
          return Utility.getSalesTaxTypeIndiaForComposition(
            shipFromState,
            shipToState
          );
        } else {
          return GST_TYPE.EXEMPT;
        }
        break;
      default:
        if (isRegisteredToSez) {
          return Utility.getSalesTaxTypeIndiaForRegisteredSez(
            contactType,
            shipFromState,
            shipToState
          );
        } else if (isRegisteredToComposition) {
          return Utility.getSalesTaxTypeIndiaForComposition(
            shipFromState,
            shipToState
          );
        } else {
          return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
        }
    }
  }

  static getIndiaDefaultTaxType(shipFromState: any, shipToState: any) {
    return Utility.isShipFromAndShipToStateSame(shipFromState, shipToState)
      ? GST_TYPE.INTRA
      : GST_TYPE.INTER;
  }

  static isShipFromAndShipToStateSame(shipFromState: any, shipToState: any) {
    return (
      shipFromState &&
      shipToState &&
      shipFromState.toUpperCase() === shipToState.toUpperCase()
    );
  }

  static getIndiaSalesTaxTypeForComposition(
    shipFromState: any,
    shipToState: any
  ) {
    if (Utility.isShipFromAndShipToStateSame(shipFromState, shipToState)) {
      return GST_TYPE.EXEMPT;
    } else {
      return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
    }
  }

  static getSalesTaxTypeIndiaForRegisteredSez(
    contactType: any,
    shipFromState: any,
    shipToState: any
  ) {
    if (
      CUST_SEZ_W_PAY === contactType ||
      CUST_NA === contactType ||
      CUST_EXPORT_W_PAY === contactType
    ) {
      return GST_TYPE.INTER;
    } else if (
      CUST_SEZ_WO_PAY === contactType ||
      CUST_EXPORT_WO_PAY === contactType
    ) {
      return GST_TYPE.EXEMPT;
    } else {
      return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
    }
  }

  static getSalesTaxTypeIndiaForUnregisteredSez(
    shipFromState: any,
    shipToState: any,
    contactType: any
  ) {
    if (
      CUST_NA === contactType &&
      Utility.isShipFromAndShipToStateSame(shipFromState, shipToState)
    ) {
      return GST_TYPE.INTRA;
    } else if (
      (CUST_NA === contactType &&
        Utility.isShipFromAndShipToStateSame(shipFromState, shipToState)) ||
      CUST_EXPORT_W_PAY === contactType
    ) {
      return GST_TYPE.INTER;
    } else if (CUST_EXPORT_WO_PAY === contactType) {
      return GST_TYPE.EXEMPT;
    } else {
      return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
    }
  }
  static getValue(...values: any) {
    return values.find((v: any) => !!v);
  }

  static getUomQuantity(baseQuantity: any, documentUOMSchemaDefinition: any, precision?: any) {
    if (
      !isNaN(baseQuantity) &&
      Utility.isNotEmpty(documentUOMSchemaDefinition)
    ) {
      return Utility.roundOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor,
        precision ? precision : 2
      );
    }
    return Utility.roundingOff(baseQuantity, QTY_ROUNDOFF_PRECISION_BACKEND);
  }

  static getUomQuantityWithoutRoundOff(
    baseQuantity: any,
    documentUOMSchemaDefinition: any
  ) {
    let convertedValue = baseQuantity;
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      convertedValue =
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
        documentUOMSchemaDefinition.sourceConversionFactor;
    }
    return Utility.roundingOff(convertedValue, QTY_ROUNDOFF_PRECISION);
  }

  static getUomWarehouseQuantity(
    baseQuantity: any,
    documentUOMSchemaDefinition: any
  ) {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (baseQuantity / documentUOMSchemaDefinition.sinkConversionFactor) *
          documentUOMSchemaDefinition.sourceConversionFactor,
        QTY_ROUNDOFF_PRECISION
      );
    }
    return baseQuantity;
  }

  static getUomWarehouseQuantityWithoutRoundOff(
    baseQuantity: any,
    documentUOMSchemaDefinition: any
  ) {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return (
        (baseQuantity / documentUOMSchemaDefinition.sinkConversionFactor) *
        documentUOMSchemaDefinition.sourceConversionFactor
      );
    }
    return baseQuantity;
  }

  static uomPendingToBeUomReceivedQuantity(buyItem: any) {
    return Utility.roundingOff(
      buyItem.uomQuantity - Utility.uomReceivedQuantity(buyItem),
      QTY_ROUNDOFF_PRECISION
    );
  }

  static uomReceivedQuantity(buyItem: any) {
    return (
      (buyItem.uomReceivedQuantityInBills || 0) +
      (buyItem.uomReceivedQuantityInOrder || 0)
    );
  }

  static padLeadingZeros(number: any, size: any) {
    var value = number + '';
    while (value.length < size) value = '0' + value;
    return value;
  }
  static getNonPendingsItems(documentItems: any[]) {
    return (
      documentItems &&
      documentItems.filter((item) => {
        return this.getPendingQtyForConversion(item) <= 0;
      })
    );
  }

  static isNonTrackedNA(product: any) {
    return (
      product &&
      product.type === PRODUCT_TYPE.NON_TRACKED &&
      product.stockUom === UOM_NA_ID
    );
  }

  static isAdvancedTracking(document: any): boolean {
    let advancedTracking = 0;
    if (document.items && document.items.length > 0) {
      document.items.map((item: any) => {
        if (
          item.advancedTracking === TRACKING_TYPE.BATCH ||
          item.advancedTracking === TRACKING_TYPE.SERIAL
        ) {
          return advancedTracking++;
        }
      });
    }
    if (advancedTracking > 0) {
      return true;
    }
    return false;
  }

  static convertToCSV(objArray: any) {
    let json = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let fields = Object.keys(json[0]);

    function notHeader(header: string) {
      let exclude = ['error', 'validationMessages'];
      return !exclude.includes(header);
    }

    fields = fields.filter(notHeader);

    let replacer = function (key: any, value: any) {
      return value === null ? '' : value;
    };
    let csv = json.map(function (row: any) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(',');
    });
    csv.unshift(fields.join(',')); // add header column
    csv = csv.join('\r\n');
    return csv;
  }

  //   static convertToCSV(objArray: any, isBankDoubleColumn: any = false) {
  //     var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  //     let exclude = ['error', 'validationMessages'];
  //     var str = '';
  //     let headerList: any = [];
  //     for (var index in array[0]) {
  //       if (!exclude.includes(index)) {
  //         if (index !== '') {
  //           headerList.push(index);
  //         }
  //       }
  //     }
  //     str += headerList.join() + '\r\n';
  //     array.forEach((record: any) => {
  //       let tmpRow: any[] = [];
  //       headerList.forEach((header: any) => {
  //         if (String(record[header]).includes(',')) {
  //           tmpRow.push('"' + record[header] + '"');
  //         } else {
  //           tmpRow.push(record[header]);
  //         }
  //       });
  //       str += tmpRow.join();
  //       str = str + '\r\n';
  //     });

  //     return str;
  //   }
  static convertToCSVForDraft(records: any) {
    const recordsArray =
      typeof records !== 'object' ? JSON.parse(records) : records;

    const fields: string[] = [];
    const excludeKeys = ['error', 'validationMessages'];
    recordsArray.forEach((row: any) => {
      Object.keys(row).forEach((key: string) => {
        if (fields.indexOf(key) === -1 && !excludeKeys.includes(key)) {
          fields.push(key);
        }
      });
    });

    const replacer = (key: any, value: any) => {
      return value === null ? '' : value;
    };

    const csv = recordsArray.map((row: any) => {
      return fields
        .map((fieldName) => {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(',');
    });

    csv.unshift(fields.join(','));

    return csv.join('\r\n');
  }

  static convertInTitleCase(str: string) {
    if (
      str === undefined ||
      str === null ||
      typeof str === undefined ||
      str === ''
    )
      return '';
    else str = str.toString();

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  static toKFormat(num: number) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000) + 'K'
      : Math.sign(num) * Math.abs(num);
  }

  static wholeNumberWithCommas(x: any) {
    if (x === undefined) {
      return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Revisit this
  static numberWithCommas(x: number) {
    return ('' + x).replace(
      /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
      function (m, s1, s2) {
        return s2 || s1 + ',';
      }
    );
  }

  static getAmountFormattedToPrint(
    currency: string,
    amount: any,
    amountInPercent: boolean = false,
    reconcile: boolean = false
  ) {
    return amountInPercent
      ? amount
        ? amount + '%'
        : ''
      : currency +
          ' ' +
          (amount || amount === ''
            ? Utility.numberWithCommas(
                reconcile ? Utility.roundOff(amount) : amount
              )
            : 0);
  }

  static getAmountFormatted(currencyCode: string, amount: any) {
    const currencies = Store.getState().commonData.data.currency;
    const symbol = currencies.filter(
      (currency) => currency.currencyCode === currencyCode
    )[0]?.currencySymbol;
    return (
      (symbol ? symbol : '') +
      ' ' +
      (amount || amount === ''
        ? Utility.roundOffToTenantDecimalScale(amount)
        : 0)
    );
  }

  static parseDateStringAndFormat(
    dateStr: string,
    fromFormat: string,
    toFormat: string
  ): string {
    return format(
      parse(
        dateStr,
        convertBooksDateFormatToDateFnsFormat(fromFormat),
        new Date()
      ),
      convertBooksDateFormatToDateFnsFormat(toFormat)
    );
  }

  static isFulfilledReceived(data: any, module: any): boolean {
    if (!data || (data && data.length === 1)) {
      return false;
    }

    let isFulfilledReceived = [];

    if (module === MODULE_TYPE.SELL) {
      isFulfilledReceived = data.map((value: any, index: any) => {
        return (
          value.fulfillmentStatus[0] === FULFILLMENT_STATUS.FULLY_FULFILLED
        );
      }, []);
    } else {
      isFulfilledReceived = data.map((value: any, index: any) => {
        if (value && value.rowType) {
          let receiptStatus =
            value.rowType === DOCUMENT_TYPE.BILL
              ? value.receiveGoodsStatus && value.receiveGoodsStatus.length > 0
                ? value.receiveGoodsStatus[0]
                : []
              : value.receiptStatus && value.receiptStatus.length > 0
              ? value.receiptStatus[0]
              : [];
          return receiptStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED;
        }
      }, []);
    }

    return isFulfilledReceived.includes(true);
  }

  static convertSellDocumentToOrder(document: any, isBackOrder?: boolean): any {
    let linkedDocObj: any = {
      documentType: document.documentType,
      documentCode: document.documentCode,
      documentSequenceCode: document.documentSequenceCode
    };
    // if (Utility.isEmpty(isBackOrder)) {
    //   let itemCodeQuanityMap: any = {};
    //   document?.items?.forEach((item: any) => {
    //     itemCodeQuanityMap = {
    //       ...itemCodeQuanityMap,
    //       [`${item.documentItemCode}`]: item.productQuantity
    //     };
    //   });
    //   linkedDocObj = {
    //     ...linkedDocObj,
    //     itemCodeQuantityMap: itemCodeQuanityMap
    //   };
    // }
    return {
      ...document,
      id: null,
      entityId: document.id ? document.id : undefined,
      attachmentIds: document.attachments
        ? document.attachments.map(Number)
        : null,
      documentCode: null,
      contact: isBackOrder ? document.contact : null,
      contactDto: isBackOrder ? document.contactDto : null,
      linkedDocuments: [linkedDocObj],
      items: document.items.map((item: any) => {
        return {
          ...item,
          taxAmount: 0,
          price: item.product ? item.product.purchasePrice : item.price,
          unitPrice: item.product ? item.product.purchasePrice : item.unitPrice,
          uomUnitPrice: item.product
            ? Utility.getUomPrice(
                item.product.purchasePrice,
                item.documentUOMSchemaDefinition
              )
            : item.uomUnitPrice
        };
      }),
      knockoffInfo: []
    };
  }

  static getInsufficientInventoryItems(items: any, warehouseData: any): any[] {
    return items.filter((lineItem: any) => {
      if (
        lineItem.product &&
        lineItem.product.type == PRODUCT_TYPE.NON_TRACKED
      ) {
        return false;
      } else {
        return Utility.getInsufficientQuantity(lineItem, warehouseData) > 0;
      }
    });
  }

  static getInsufficientQuantity(item: any, warehouseData: any) {
    let available: any = item.availableQuantity
      ? item.availableQuantity
      : item.product
      ? item.product.availableQuantity
      : 0;
    available = isNaN(available) ? 0 : Number(available);
    let sumOfReservedQtyInAllWarehouse = 0;
    if (Utility.isEmpty(item.reservedQuantitiesData)) {
      sumOfReservedQtyInAllWarehouse = warehouseData?.reduce(
        (prev: number, current: any) =>
          Utility.roundingOff(
            prev +
              Number(current?.productReservedQuantity?.[item.productCode] || 0),
            QTY_ROUNDOFF_PRECISION_BACKEND
          ),
        0
      );
      available = available - sumOfReservedQtyInAllWarehouse;
    }
    if (item.documentUOMSchemaDefinition && available) {
      available = Utility.getUomQuantity(
        available,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    const required = isNaN(item.productQuantity)
      ? 0
      : Number(item.productQuantity);
    return required - available;
  }

  static checkStockAvailability(itemList: any[]) {
    if (!itemList?.length) {
      return false;
    }
    let isAvailable = true;
    for (let item of itemList) {
      if (item.type === 'NONTRACKED' || item.optional) {
        continue;
      }
      let availableQuantity = this.isNumber(item.product?.availableQuantity)
        ? item.product?.availableQuantity
        : 0;
      let reservedQuantity = this.isNumber(item.product?.reservedQuantity)
        ? item.product?.reservedQuantity
        : 0;
      let productQuantity = this.isNumber(item.productQuantity)
        ? item.productQuantity
        : 0;

      if (availableQuantity - reservedQuantity < productQuantity) {
        isAvailable = false;
        break;
      }
    }

    return isAvailable;
  }

  static needDropshipPopup = (documentDetails: any) => {
    let returnValue = false;
    documentDetails?.items?.forEach((item: any) => {
      if (item.qtyConvertedToDropShip > 0) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  static isParentDocumentConverted = (fulfillmentItems: any, docType: any) => {
    let returnValue = false;
    fulfillmentItems.forEach((item: any) => {
      if (
        item.productQuantity !== item.pendingQtyToConvert &&
        docType !== DOC_TYPE.INVOICE &&
        item.pendingQtyToConvert != null
      ) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  static getQtyToFulfillAfterPartialFulfillments(qty: any, tempItem: any) {
    let item: any = deepClone(tempItem);
    if (item.documentUOMSchemaDefinition) {
      item.qtyConvertedToDropShip = Utility.getUomQuantity(
        item.qtyConvertedToDropShip,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      item.qtyFulfilledFromToDropShip = Utility.getUomQuantity(
        item.qtyFulfilledFromToDropShip,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      item.qtyConvertedToPps = Utility.getUomQuantity(
        item.qtyConvertedToPps,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      item.qtyFulfilledFromPps = Utility.getUomQuantity(
        item.qtyFulfilledFromPps,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      qty = Utility.getUomQuantity(
        qty,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    let qtyFulfilled = item.quantityFulfilled;

    if (item.fulfilledQuantity != null) {
      if (isNaN(qtyFulfilled)) {
        qtyFulfilled = item.fulfilledQuantity;
      } else if (item.fulfilledQuantity && item.fulfilledQuantity > 0) {
        qtyFulfilled = item.fulfilledQuantity;
      }
    }

    if (item.documentUOMSchemaDefinition) {
      qtyFulfilled = Utility.getUomQuantity(
        qtyFulfilled,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    let qtyLeft =
      qty -
      item.qtyConvertedToDropShip -
      qtyFulfilled -
      item.qtyConvertedToPps +
      item.qtyFulfilledFromToDropShip +
      item.qtyFulfilledFromPps;

    Utility.roundingOff(qtyLeft, QTY_ROUNDOFF_PRECISION);

    return qtyLeft;
  }

  static hasDropShipQty(document: any, docType: any) {
    let returnValue = false;
    let items: any;
    if (docType === DOC_TYPE.INVOICE) {
      items = document.salesInvoiceItems;
    } else if (docType === DOC_TYPE.QUOTE) {
      items = document.quotationItemDtoList;
    } else {
      items = document.salesOrderItems;
    }
    items.forEach((item: any) => {
      if (item.qtyConvertedToDropShip > 0) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  static hasPPSQty(document: any) {
    let returnValue = false;
    let items: any = document.items;

    items.forEach((item: any) => {
      if (item.qtyFulfilledFromPps > 0) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  static createBackOrder(document: any, warehouseProduct: any) {
    const doc: any = { ...document };
    doc.purchaseOrderItems = document.items.map((lineItem: any) => {
      lineItem.unitPrice =
        lineItem.product && lineItem.product.purchasePrice
          ? lineItem.product.purchasePrice
          : 0;
      lineItem.uomUnitPrice =
        lineItem.product && lineItem.product.purchasePrice
          ? lineItem.product.purchasePrice
          : 0;

      const productQuantity = Utility.getInsufficientQuantity(
        lineItem,
        warehouseProduct
      );
      lineItem.linkedSoItem = lineItem?.salesOrderItemCode;
      return {
        ...lineItem,
        productQuantity
      };
    });
    const order: any = Utility.convertSellDocumentToOrder(doc, true);
    order.backOrder = true;
    order.orderType = BUY_TYPE.INVENTORY;
    order.documentType = DOC_TYPE.ORDER;
    delete order['documentSequenceCode'];
    delete order['documentCode'];
    delete order['sequenceFormat'];
    return order;
  }

  static createDropShip(document: any, docType: any) {
    let order = Utility.convertSellDocumentToOrder(document);
    order.documentType = DOC_TYPE.ORDER;
    order.parentDocumentTypeForDropShip = docType;
    order.purchaseOrderItems = order.items.filter((item: any) => {
      let qtyFulfilled = item.fulfilledQuantity ?? item.quantityFulfilled;
      let qty =
        item.productQuantity -
        item.qtyConvertedToDropShip -
        qtyFulfilled -
        item.qtyConvertedToPps +
        item.qtyFulfilledFromToDropShip +
        item.qtyFulfilledFromPps;
      return (
        item.type !== PRODUCT_TYPE.BILL_OF_MATERIALS &&
        !item.optional &&
        item.productQuantity !== item.qtyConvertedToDropShip &&
        qty !== 0
      );
    });
    order.dropShip = true;
    order.orderType = BUY_TYPE.INVENTORY;
    delete order['documentSequenceCode'];
    delete order['documentCode'];
    delete order['salesInvoiceItems'];
    delete order['quotationCode'];
    delete order['quotationItemDtoList'];
    delete order['sequenceFormat'];
    delete order['salesOrderItems'];
    return order;
  }

  static isDropship(document: any): boolean {
    return (
      document.dropShip ||
      document.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP
    );
  }
  static isFaDropship(document: any): boolean {
    return false;
  }

  static getUomPrice(basePrice: any, documentUOMSchemaDefinition: any) {
    if (!isNaN(basePrice) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (basePrice * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor
      );
    }
    return basePrice;
  }

  static isDraft(str: string) {
    return str?.startsWith('<div');
  }

  static getAmoutBlockForChart(
    code: string,
    amount: string,
    extraClass?: string
  ) {
    const html = `<span class="${GOOGLE_NO_TRANSLATE_CLASS} ${
      +amount < 0 ? ' text-red' : ''
    } ${
      extraClass ? extraClass : 'text-right'
    }">${Utility.getCurrencySymbolFromCode(code)} ${
      +amount < 0
        ? '(' + Utility.shortFormatNumberWithSuffix(Math.abs(+amount)) + ')'
        : Utility.shortFormatNumberWithSuffix(+amount)
    }</span>`;
    return html;
  }

  static getLongAmoutBlockForGridCurrencyName(
    currencyName: string,
    amount: string,
    extraClass?: string
  ) {
    const html = `<div class="${GOOGLE_NO_TRANSLATE_CLASS} ${
      +amount < 0 ? ' text-red' : ''
    } ${extraClass ? extraClass : 'text-right'}">${
      currencyName ? currencyName + ' ' : ''
    }${
      +amount < 0
        ? '(' + NumberFormatService.getNumber(Math.abs(+amount)) + ')'
        : NumberFormatService.getNumber(+amount)
    }</div>`;
    return html;
  }

  static getLongAmoutBlockForGrid(
    code: string,
    amount: string,
    extraClass?: string
  ) {
    const html = `<div class="${GOOGLE_NO_TRANSLATE_CLASS} ${
      +amount < 0 ? ' text-red' : ''
    } ${extraClass ? extraClass : 'text-right'}">${
      code ? Utility.getCurrencySymbolFromCode(code) + ' ' : ''
    }${
      +amount < 0
        ? '(' + NumberFormatService.getNumber(Math.abs(+amount)) + ')'
        : NumberFormatService.getNumber(+amount)
    }</div>`;
    return html;
  }

  static getAmoutBlockForGrid(
    code: string,
    amount: string,
    extraClass?: string
  ) {
    const html = `<div class="${GOOGLE_NO_TRANSLATE_CLASS} ${
      +amount < 0 ? ' text-red' : ''
    } ${
      extraClass ? extraClass : 'text-right'
    }">${Utility.getCurrencySymbolFromCode(code)} ${
      +amount < 0
        ? '(' + Utility.shortFormatNumberWithSuffix(Math.abs(+amount)) + ')'
        : Utility.shortFormatNumberWithSuffix(+amount)
    }</div>`;
    return html;
  }

  static getLongAmoutBlockForLabel(code: string, amount: string) {
    const html = `${code ? Utility.getCurrencySymbolFromCode(code) + ' ' : ''}${
      +amount < 0
        ? '(' + NumberFormatService.getNumber(Math.abs(+amount)) + ')'
        : NumberFormatService.getNumber(+amount)
    }`;
    return html;
  }

  static getShortAmoutBlockForLabel(code: string, amount: string) {
    const html = `${code ? Utility.getCurrencySymbolFromCode(code) + ' ' : ''}${
      +amount < 0
        ? '(' + Utility.shortFormatNumberWithSuffix(Math.abs(+amount)) + ')'
        : Utility.shortFormatNumberWithSuffix(+amount)
    }`;
    return html;
  }

  static getDraftIconBlockForGrid(draftCode?: string) {
    if (draftCode?.length) {
      return (
        '<div>' +
        `<div>${draftCode}</div>` +
        `<div class="fs-s bg-chip-gray border-radius-s text-gray px-1 mt-1" style="width: fit-content;">Draft</div>` +
        '</div>'
      );
    }

    return (
      '<div class="row" title="draft">' +
      `<div class="column"><img style="width: 18px;" src="${ic_draft}" /></div>` +
      '<span class="column bg-chip-gray fs-m border-radius-s ml-s text-gray" style="padding: 2px 6px;">Draft</span>' +
      '</div>'
    );
  }

  static getIconForGrid(document: any) {
    if (!document) {
      return;
    }
    if (
      document.recurring[0] === RECURRING_DOCUMENT_TYPE.RECURRING &&
      document.recurringActivated
    ) {
      return `<div title="Recurring Document"><img style="width: 20px;" src="${ic_recurring}" /></div>`;
    } else if (
      document.recurring[0] === RECURRING_DOCUMENT_TYPE.RECURRING &&
      !document.recurringActivated
    ) {
      return `<div title="Recurring Document"><img style="width: 20px;" src="${ic_recurring_light}" /></div>`;
    } else if (
      document.backOrder &&
      (document.rowType === 'INVOICE' ||
        document.rowType === DOC_TYPE.QUOTE ||
        document.rowType === DOC_TYPE.SALES_ORDER)
    ) {
      return `<div class="flex" title="BackOrder"><img style="width: 20px;" src="${ic_backorder_list}" /></div>`;
    } else {
      return '';
    }
  }

  /**
   * Get tenant currency symbol
   */
  static getTenantCurrencySymbol() {
    const currencyCode =
      Store.getState().authInfo.currentTenantInfo.data.currency;
    const currencySymbol = Store.getState().commonData.data.currency.filter(
      (currency) => currency.currencyCode === currencyCode
    )[0]?.currencySymbol;
    return currencySymbol || ``;
  }

  /**
   * Get currency symbol from currecny code
   */
  static getCurrencySymbolFromCode(code: string) {
    const currencySymbol = Store.getState().commonData.data.currency.filter(
      (currency) => currency.currencyCode === code
    )[0]?.currencySymbol;
    return currencySymbol ?? '';
  }

  /**
   * Returns a number in short format with with K / M / B as suffix
   */
  static shortFormatNumberWithSuffix(num: number) {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    num = +num;
    if (!isNaN(num)) {
      const million = 1000 * 1000;
      const billion = 1000 * 1000 * 1000;
      const trillion = 1000 * 1000 * 1000 * 1000;

      let value = '';
      if (tenantDetails && tenantDetails.country === 'IN') {
        value = NumberFormatService.getNumber(num);
      } else if (num >= million && num < billion) {
        value = NumberFormatService.getNumber(num / million) + 'M';
      } else if (num >= billion && num < trillion) {
        value = NumberFormatService.getNumber(num / billion) + 'B';
      } else if (num >= trillion) {
        value = NumberFormatService.getNumber(num / trillion) + 'T';
      } else if (num < 1000 * 1000) {
        value = NumberFormatService.getNumber(num);
      }
      return value;
    }
    return num;
  }

  static getModuleNameFromTableName(tableName: any) {
    switch (tableName) {
      case REMOTE_CONFIG_TABLES.EXPENSE:
        return 'EXPENSE';
      case REMOTE_CONFIG_TABLES.DEPOST:
        return 'DEPOSIT';
      case REMOTE_CONFIG_TABLES.CONTACT:
        return 'CONTACT';
      case REMOTE_CONFIG_TABLES.INVOICE:
        return 'INVOICE';
      case REMOTE_CONFIG_TABLES.BILL:
        return 'BILL';
      case REMOTE_CONFIG_TABLES.COA:
        return 'COA';
      case REMOTE_CONFIG_TABLES.JOURNAL:
        return 'JOURNAL';
      case REMOTE_CONFIG_TABLES.QUOTATION:
        return 'QUOTATION';
      case REMOTE_CONFIG_TABLES.PRODUCT:
        return 'PRODUCT';
      case REMOTE_CONFIG_TABLES.DEBIT_NOTES:
        return 'DEBITNOTE';
      case REMOTE_CONFIG_TABLES.CREDIT_NOTES:
        return 'CREDITNOTE';
      case REMOTE_CONFIG_TABLES.OPERATIONS:
        return MODULES_NAME.MRP_OPERATION;
      case REMOTE_CONFIG_TABLES.WORK_ORDERS:
        return MODULES_NAME.MRP_WORK_ORDER;
      case REMOTE_CONFIG_TABLES.OPERATORS:
        return MODULES_NAME.MRP_OPERATOR;
      case REMOTE_CONFIG_TABLES.PURCHASE_REQUISITION:
        return MODULES_NAME.REQUISITION;
      default:
        return '';
    }
  }

  static getFilterParameters(queries: any, table: any) {
    let filteredQueryList: any[] = [];
    queries.forEach((query: any) => {
      const { key, condition, value, type } = query;
      let columnObj = TableManager.getColumnById(table, key);
      if (
        Utility.isEmpty(columnObj) &&
        table === 'books_stock_adjustment_draft'
      ) {
        columnObj = TableManager.getColumn(
          REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT,
          key
        );
      }
      if (
        Utility.isEmpty(columnObj) &&
        table === 'books_stock_transfer_draft'
      ) {
        columnObj = TableManager.getColumn(
          REMOTE_CONFIG_TABLES.STOCK_TRANSFER,
          key
        );
      }

      const tableLowercaseNotAllowed = [
        REMOTE_CONFIG_TABLES.PURCHASE_REQUISITION,
        REMOTE_CONFIG_TABLES.WORK_ORDERS
      ];
      const newValue = Utility.getNewValue(
        value,
        !tableLowercaseNotAllowed.includes(table)
      );

      let columnKey: any;
      let columnValue: any;

      if (Utility.isEmpty(columnObj)) {
        if (table === REMOTE_CONFIG_TABLES.BANK_DETAILS_TRANSACTION) {
          switch (newValue) {
            case FILTER_KEYS.CREDIT:
              columnKey = FILTER_KEYS.CD_TYPE;
              columnValue = FILTER_KEYS.CREDIT.toUpperCase();
              break;
            case FILTER_KEYS.DEBIT:
              columnKey = FILTER_KEYS.CD_TYPE;
              columnValue = FILTER_KEYS.DEBIT.toUpperCase();
              break;
            case FILTER_KEYS.ALL_TYPE:
              columnKey = '';
              columnValue = '';
              break;
            case FILTER_KEYS.MATCHED:
              columnKey = FILTER_KEYS.IS_MATCH_BY_USER;
              columnValue = true;
              break;
            case FILTER_KEYS.UNMATCHED:
              columnKey = FILTER_KEYS.IS_MATCH_BY_USER;
              columnValue = false;
              break;
            case FILTER_KEYS.RECONCILED:
              columnKey = FILTER_KEYS.RECONCILED;
              columnValue = true;
              break;
            case FILTER_KEYS.UNRECONCILED:
              columnKey = FILTER_KEYS.RECONCILED;
              columnValue = false;
              break;
            case FILTER_KEYS.ALLSTATUS:
              columnKey = '';
              columnValue = '';
              break;
          }
          if (columnKey !== '' && columnValue !== '') {
            filteredQueryList.push({
              [columnKey]: columnValue
            });
          }
        }

        if (table === REMOTE_CONFIG_TABLES.SALES_ORDER) {
          if (condition === 'eq' || condition === 'c') {
            if (newValue) {
              if (
                FILTER_KEYS.PENDING === newValue ||
                FILTER_KEYS.SCHEDULED === newValue ||
                FILTER_KEYS.DELAYED === newValue ||
                FILTER_KEYS.COMPLETED === newValue
              ) {
                columnKey = 'production';
                columnValue = newValue.toUpperCase();
              }
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.JOB_CARDS) {
          if (condition === 'eq' || condition === 'c') {
            switch (key) {
              case FILTER_KEYS.JOB_CARD_CODE:
                columnKey = FILTER_KEYS.DOCUMENT_SEQUENCE_CODE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.STATUS:
                columnKey = FILTER_KEYS.STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_ORDER:
                columnKey = FILTER_KEYS.WORK_ORDER;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.SALES_ORDER:
                columnKey = FILTER_KEYS.SALES_ORDER;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORKSTARION_NAME:
                columnKey = FILTER_KEYS.WORKSTARION_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.OPERATION_NAME:
                columnKey = FILTER_KEYS.OPERATION_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_ORDER_NAME:
                columnKey = FILTER_KEYS.WORK_ORDER_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.WORK_ORDERS) {
          if (condition === 'eq' || condition === 'c') {
            switch (key) {
              case FILTER_KEYS.WORK_ORDER_NUMBER:
                columnKey = FILTER_KEYS.WORK_ORDER_NUMBER;
                columnValue = newValue;
                break;
              case FILTER_KEYS.STATUS:
                columnKey = FILTER_KEYS.STATUS;
                columnValue = newValue;
                break;
              case FILTER_KEYS.WORK_ORDER:
                columnKey = FILTER_KEYS.WORK_ORDER;
                columnValue = newValue;
                break;
              case FILTER_KEYS.SALES_ORDER:
                columnKey = FILTER_KEYS.SALES_ORDER;
                columnValue = newValue;
                break;
              case FILTER_KEYS.WORK_ORDER_NAME:
                columnKey = FILTER_KEYS.WORK_ORDER_NAME;
                columnValue = newValue;
                break;
              case FILTER_KEYS.OPERATION_NAME:
                columnKey = FILTER_KEYS.OPERATION_NAME;
                columnValue = newValue;
                break;
              case FILTER_KEYS.WORKSTARION_NAME:
                columnKey = FILTER_KEYS.WORKSTARION_NAME;
                columnValue = newValue;
                break;
              case FILTER_KEYS.WORK_ORDER_PRODUCT_NAME:
                columnKey = FILTER_KEYS.WORK_ORDER_PRODUCT_NAME;
                columnValue = newValue;
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.OPERATIONS) {
          if (condition === 'eq' || condition === 'c') {
            switch (key) {
              case FILTER_KEYS.NAME:
                columnKey = FILTER_KEYS.NAME;
                columnValue = newValue;
                break;
              case FILTER_KEYS.DEFAULT_WORK_STATION:
                columnKey = FILTER_KEYS.WORK_STATION_NAME;
                columnValue = newValue;
                break;
              case FILTER_KEYS.DESCRIPTION:
                columnKey = FILTER_KEYS.DESCRIPTION;
                columnValue = newValue;
                break;
              case FILTER_KEYS.COST_PER_HOUR:
                columnKey = FILTER_KEYS.COST_PER_HOUR;
                columnValue = newValue;
                break;
              case FILTER_KEYS.FIXED_RATE:
                columnKey = FILTER_KEYS.FIXED_RATE;
                columnValue = newValue;
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.PRODUCT) {
          if (condition === 'eq' || condition === 'c') {
            switch (key) {
              case FILTER_KEYS.PURCHASE_PRICE:
                columnKey = FILTER_KEYS.PURCHASE_PRICE;
                columnValue = newValue;
                break;
              case FILTER_KEYS.CATEGORY:
                columnKey = FILTER_KEYS.CATEGORY;
                columnValue = newValue;
                if (newValue === 'bill_of_materials_wip') {
                  columnValue = 'bill_of_materials,isFinishedProduct=false';
                }
                if (newValue === 'bill_of_materials_fg') {
                  columnValue = 'bill_of_materials,isFinishedProduct=true';
                }
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          } else if (condition === 'gt' || condition === 'lt') {
            switch (key) {
              case FILTER_KEYS.PURCHASE_PRICE:
                columnKey = FILTER_KEYS.PURCHASE_PRICE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.OPERATORS) {
          if (condition === 'eq' || condition === 'c') {
            switch (key) {
              case FILTER_KEYS.NAME:
                columnKey = FILTER_KEYS.NAME;
                columnValue = newValue;
                break;
              case FILTER_KEYS.COST_PER_HOUR:
                columnKey = FILTER_KEYS.COST_PER_HOUR;
                columnValue = newValue;
                break;
              case FILTER_KEYS.FIXED_RATE:
                columnKey = FILTER_KEYS.FIXED_RATE;
                columnValue = newValue;
                break;
              case FILTER_KEYS.CONTACT_NUMBER:
                columnKey = FILTER_KEYS.CONTACT_NUMBER;
                columnValue = newValue;
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.WORK_OUT) {
          if (condition === 'eq' || condition === 'c') {
            switch (key) {
              case FILTER_KEYS.DISPATCHED_STATUS:
                columnKey = FILTER_KEYS.DISPATCHED_STATUS;
                columnValue = newValue;
                break;
              case FILTER_KEYS.RECEIPT_STATUS:
                columnKey = FILTER_KEYS.RECEIPT_STATUS;
                columnValue = newValue;
                break;
              case FILTER_KEYS.BILL_STATUS:
                columnKey = FILTER_KEYS.BILL_STATUS;
                columnValue = newValue;
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT) {
          if (condition === 'eq' || condition === 'c') {
            switch (key) {
              case 'memo':
                columnKey = 'notes';
                columnValue = newValue;
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.PURCHASE_REQUISITION) {
          if (condition === 'eq' || condition === 'c') {
            const columnKey =
              REQUISTIION_ENTITY_MAPPING[
                key as keyof typeof REQUISTIION_ENTITY_MAPPING
              ];

            if (!Utility.isEmpty(columnKey)) {
              switch (columnKey) {
                default:
                  columnValue = {
                    value: newValue,
                    condition: condition
                  };
                  break;
              }
              if (columnKey !== '' && columnValue !== '') {
                filteredQueryList.push({
                  [columnKey]: columnValue
                });
              }
            }
          }
        }

        return;
      }

      const customValue = Array.isArray(value)
        ? value[0]
        : typeof value === 'string'
        ? value
        : typeof value[0] !== 'string'
        ? +value
        : value;

      if (columnObj?.columnCode?.includes('custom_')) {
        let colType = type;
        if (Utility.isEmpty(colType)) {
          colType = columnObj?.type;
        }
        switch (customValue) {
          case customValue:
            columnKey = FILTER_KEYS.CUSTOM_FIELD;
            columnValue = {
              value:
                colType?.toLowerCase() !== 'date'
                  ? customValue
                  : DateFormatService.getDateStrFromDate(
                      customValue,
                      BOOKS_DATE_FORMAT['MM/DD/YYYY']
                    ),
              code: columnObj.columnCode.split('_')[1],
              module: [Utility.getModuleNameFromTableName(table)],
              operator:
                FILTER_OPERATORS[condition as keyof typeof FILTER_OPERATORS] ||
                '='
            };
            if (
              (table === REMOTE_CONFIG_TABLES.WORK_ORDERS ||
                table === REMOTE_CONFIG_TABLES.WORK_OUT) &&
              (colType.toLowerCase() === 'date' ||
                colType.toLowerCase() === 'number')
            ) {
              columnValue = {
                ...columnValue,
                fieldType: colType.toUpperCase()
              };
            }
            break;
        }

        filteredQueryList.push({
          [columnKey]: columnValue
        });
      }
      if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.BARCODE &&
        table === REMOTE_CONFIG_TABLES.PRODUCT
      ) {
        columnKey = 'barcode';
        columnValue = newValue;
        if (!Utility.isEmpty(columnKey) && !Utility.isEmpty(columnValue)) {
          filteredQueryList.push({
            [columnKey]: {
              value: columnValue,
              condition
            }
          });
        }
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.CREATED_BY
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.BILL:
            columnKey = FILTER_KEYS.CREATED_BY;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
          case REMOTE_CONFIG_TABLES.QUOTATION:
            columnKey = FILTER_KEYS.CREATED_BY;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = FILTER_KEYS.CREATED_BY;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
        }
        if (columnValue) {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        REMOTE_CONFIG_TABLES.BILL === table
      ) {
        switch (columnObj.columnCode) {
          case FILTER_KEYS.PURCHASE_INVOICE_TYPE:
            columnKey = FILTER_KEYS.PURCHASE_INVOICE_TYPE;
            columnValue = {
              value: newValue,
              condition: condition
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.LINKED_WORK_ORDER
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = FILTER_KEYS.LINKED_WORK_ORDER;
            columnValue = {
              value: newValue.toUpperCase(),
              condition: 'eq'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.CONTACT
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = FILTER_KEYS.CONTACT_CODE;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.PRODUCT_CODE
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = 'salesOrderItems.documentSequenceCode';
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.PRODUCT_TRANSACTION_TYPE &&
        table === REMOTE_CONFIG_TABLES.PRODUCT
      ) {
        switch (columnObj.columnCode) {
          case FILTER_KEYS.PRODUCT_TRANSACTION_TYPE:
            columnKey = 'productTransactionType';
            columnValue = newValue.toUpperCase();
            break;
        }
        if (!Utility.isEmpty(columnKey) && !Utility.isEmpty(columnValue)) {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      } else if (
        condition === 'neq' &&
        columnObj.columnCode === FILTER_KEYS.PRODUCT_TRANSACTION_TYPE &&
        table === REMOTE_CONFIG_TABLES.PRODUCT
      ) {
        switch (columnObj.columnCode) {
          case FILTER_KEYS.PRODUCT_TRANSACTION_TYPE:
            columnKey = 'productTransactionType';
            if (newValue?.toLowerCase() === 'purchase') {
              filteredQueryList.push({
                [columnKey]: 'SALES'
              });
              filteredQueryList.push({
                [columnKey]: 'BOTH'
              });
            } else if (newValue?.toLowerCase() === 'sales') {
              filteredQueryList.push({
                [columnKey]: 'PURCHASE'
              });
              filteredQueryList.push({
                [columnKey]: 'BOTH'
              });
            } else if (newValue?.toLowerCase() === 'both') {
              filteredQueryList.push({
                [columnKey]: 'PURCHASE'
              });
              filteredQueryList.push({
                [columnKey]: 'SALES'
              });
            }
            break;
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.REORDER_LEVEL &&
        table === REMOTE_CONFIG_TABLES.PRODUCT
      ) {
        if (customValue === 'PENDING_REORDER') {
          filteredQueryList.push({
            reorderLevelReached: true
          });
        } else if (customValue === 'GREATER_THAN_0') {
          filteredQueryList.push({
            reorderLevel: {
              value: 1,
              condition: 'gt'
            }
          });
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.RESTOCK_LEVEL &&
        table === REMOTE_CONFIG_TABLES.PRODUCT
      ) {
        if (customValue === 'PENDING_RESTOCK') {
          filteredQueryList.push({
            restockLevelReached: true
          });
        } else if (customValue === 'GREATER_THAN_0') {
          filteredQueryList.push({
            restockLevel: {
              value: 1,
              condition: 'gt'
            }
          });
        }
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        (columnObj.columnCode === FILTER_KEYS.DOCUMENT_SEQUENCE_CODE ||
          columnObj.columnCode === FILTER_KEYS.E_WAY_BILL_NUMBER ||
          columnObj.columnCode === FILTER_KEYS.E_WAY_BILL_STATUS) &&
        table === REMOTE_CONFIG_TABLES.INVOICE
      ) {
        switch (columnObj.columnCode) {
          case FILTER_KEYS.DOCUMENT_SEQUENCE_CODE:
            columnKey = FILTER_KEYS.DOCUMENT_SEQUENCE_CODE;
            columnValue = {
              value: newValue,
              condition: condition
            };
            break;
          case FILTER_KEYS.E_WAY_BILL_NUMBER:
            columnKey = FILTER_KEYS.E_WAY_BILL_NO;
            columnValue = {
              value: newValue,
              condition: condition
            };
            break;
          case FILTER_KEYS.E_WAY_BILL_STATUS:
            columnKey = FILTER_KEYS.E_WAY_BILL_STATUS;
            columnValue = {
              value: newValue.toString().toUpperCase(),
              condition: condition
            };
            break;
        }
        if (!Utility.isEmpty(columnKey) && !Utility.isEmpty(columnValue)) {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      }

      if (
        (condition === 'eq' || condition === 'gt' || condition === 'lt') &&
        columnObj.columnCode === FILTER_KEYS.SALES_INVOICE_DATE &&
        table === REMOTE_CONFIG_TABLES.INVOICE
      ) {
        columnKey = FILTER_KEYS.SALES_INVOICE_DATE;
        columnValue = {
          value: DateFormatService.getDateStrFromDate(
            newValue,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          condition: condition
        };
        if (!Utility.isEmpty(columnKey) && !Utility.isEmpty(columnValue)) {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        (columnObj.columnCode === FILTER_KEYS.BILL_TO ||
          columnObj.columnCode === FILTER_KEYS.SHIP_TO) &&
        (table === REMOTE_CONFIG_TABLES.SALES_ORDER ||
          table === REMOTE_CONFIG_TABLES.INVOICE ||
          table === REMOTE_CONFIG_TABLES.BILL ||
          table === REMOTE_CONFIG_TABLES.PURCHASE_ORDER ||
          table === REMOTE_CONFIG_TABLES.QUOTATION)
      ) {
        switch (columnObj.columnCode) {
          case FILTER_KEYS.BILL_TO:
            columnKey = FILTER_KEYS.BILL_TO;
            columnValue = newValue;
            break;
          case FILTER_KEYS.SHIP_TO:
            columnKey = FILTER_KEYS.SHIP_TO;
            columnValue = newValue;
            break;
        }
        if (!Utility.isEmpty(columnKey) && !Utility.isEmpty(columnValue)) {
          filteredQueryList.push({
            [columnKey]: {
              value: columnValue,
              condition: 'c'
            }
          });
        }
      }

      if (
        (condition === 'eq' || condition === 'c') &&
        (columnObj.columnCode === FILTER_KEYS.CUSTOMER_ORDER_NUMBER ||
          columnObj.columnCode === FILTER_KEYS.APPROVAL_STATUS) &&
        (table === REMOTE_CONFIG_TABLES.INVOICE ||
          table === REMOTE_CONFIG_TABLES.SALES_ORDER ||
          table === REMOTE_CONFIG_TABLES.QUOTATION)
      ) {
        switch (columnObj.columnCode) {
          case FILTER_KEYS.CUSTOMER_ORDER_NUMBER:
            columnKey = 'customerOrderNumber';
            columnValue = newValue?.toUpperCase()?.trim();
            break;
          case FILTER_KEYS.APPROVAL_STATUS:
            columnKey = FILTER_KEYS.APPROVAL_STATUS;
            columnValue = newValue.toUpperCase().split(' ').join('_');
            break;
        }
        if (!Utility.isEmpty(columnKey) && !Utility.isEmpty(columnValue)) {
          filteredQueryList.push({
            [columnKey]: {
              value: columnValue,
              condition
            }
          });
        }
      } else if (
        condition === 'neq' &&
        columnObj.columnCode === FILTER_KEYS.CUSTOMER_ORDER_NUMBER &&
        (table === REMOTE_CONFIG_TABLES.INVOICE ||
          table === REMOTE_CONFIG_TABLES.SALES_ORDER ||
          table === REMOTE_CONFIG_TABLES.QUOTATION)
      ) {
        switch (columnObj.columnCode) {
          case FILTER_KEYS.CUSTOMER_ORDER_NUMBER:
            columnKey = 'customerOrderNumber';
            columnValue = newValue.toUpperCase();
            break;
        }
      }
      if (
        (condition === 'eq' || condition === 'c') &&
        (columnObj.columnCode === FILTER_KEYS.TRANSACTIONS_TYPE ||
          columnObj.columnCode === FILTER_KEYS.STATUS) &&
        table === REMOTE_CONFIG_TABLES.BANK_DETAILS_DK
      ) {
        switch (newValue) {
          case FILTER_KEYS.CREDIT:
            columnKey = FILTER_KEYS.CD_TYPE;
            columnValue = FILTER_KEYS.CREDIT.toUpperCase();
            break;
          case FILTER_KEYS.DEBIT:
            columnKey = FILTER_KEYS.CD_TYPE;
            columnValue = FILTER_KEYS.DEBIT.toUpperCase();
            break;
          case FILTER_KEYS.ALL:
            columnKey = '';
            columnValue = '';
            break;
          case FILTER_KEYS.MATCHED:
            columnKey = FILTER_KEYS.IS_MATCH_BY_USER;
            columnValue = true;
            break;
          case FILTER_KEYS.UNMATCHED:
            columnKey = FILTER_KEYS.IS_MATCH_BY_USER;
            columnValue = false;
            break;
          case FILTER_KEYS.RECONCILED:
            columnKey = FILTER_KEYS.RECONCILED;
            columnValue = true;
            break;
          case FILTER_KEYS.UNRECONCILED:
            columnKey = FILTER_KEYS.RECONCILED;
            columnValue = false;
            break;
          case FILTER_KEYS.ALLSTATUS:
            columnKey = '';
            columnValue = '';
            break;
        }
        if (columnKey !== '' && columnValue !== '') {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.STATUS
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.CONTACT:
          case REMOTE_CONFIG_TABLES.COA:
          case REMOTE_CONFIG_TABLES.BANKS:
          case REMOTE_CONFIG_TABLES.QUOTATION:
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
          case REMOTE_CONFIG_TABLES.CUSTOM_FIELDS_LIST:
            columnKey = columnObj.columnCode;
            columnValue = newValue;
            break;

          case REMOTE_CONFIG_TABLES.PRODUCT:
            columnKey = FILTER_KEYS.ACTIVE;
            columnValue = newValue === FILTER_KEYS.ACTIVE ? true : false;
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.FIELD_TYPE
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.CUSTOM_FIELDS_LIST:
            columnKey = columnObj.columnCode;
            columnValue = newValue;
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.MODULES
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.CUSTOM_FIELDS_LIST:
            columnKey = columnObj.columnCode;
            columnValue = newValue;
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.FULFILLMENT_STATUS
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
          case REMOTE_CONFIG_TABLES.QUOTATION:
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = FILTER_KEYS.FULFILLMENT_STATUS;
            columnValue = newValue.toUpperCase();
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.RESERVED_STOCK
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
          case REMOTE_CONFIG_TABLES.QUOTATION:
            columnKey = FILTER_KEYS.RESERVED_STOCK;
            columnValue = newValue.toUpperCase() === 'RESERVED' ? true : false;
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.RECURRING
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
          case REMOTE_CONFIG_TABLES.QUOTATION:
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = FILTER_KEYS.RECURRING;
            columnValue =
              newValue.toUpperCase() === RECURRING_DOCUMENT_TYPE.RECURRING
                ? true
                : false;
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.STATUS
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
          case REMOTE_CONFIG_TABLES.QUOTATION:
            columnKey = FILTER_KEYS.TOTAL_AMOUNT;
            columnValue = {
              value: newValue,
              condition
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'gt' || condition === 'lt') &&
        columnObj.columnCode === FILTER_KEYS.TOTAL_AMOUNT
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
          case REMOTE_CONFIG_TABLES.QUOTATION:
            columnKey = FILTER_KEYS.TOTAL_AMOUNT;
            columnValue = {
              value: Utility.getFormattedNumber(newValue),
              condition
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'gt' || condition === 'lt') &&
        columnObj.columnCode === FILTER_KEYS.DUE_AMOUNT
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
            columnKey = FILTER_KEYS.DUE_AMOUNT;
            columnValue = {
              value: Utility.getFormattedNumber(newValue),
              condition
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'gt' || condition === 'lt') &&
        (columnObj.columnCode === FILTER_KEYS.INVOICE_DUE_DATE ||
          columnObj.columnCode === FILTER_KEYS.QUOTE_DUE_DATE ||
          columnObj.columnCode === FILTER_KEYS.SALES_ORDER_DUE_DATE ||
          columnObj.columnCode === FILTER_KEYS.BILL_DUE_DATE)
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
            columnKey = FILTER_KEYS.INVOICE_DUE_DATE;
            columnValue = {
              value: DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              ),
              condition
            };
            break;
          case REMOTE_CONFIG_TABLES.QUOTATION:
            columnKey = FILTER_KEYS.QUOTE_DUE_DATE;
            columnValue = {
              value: DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              ),
              condition
            };
            break;
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = FILTER_KEYS.SALES_ORDER_DUE_DATE;
            columnValue = {
              value: DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              ),
              condition
            };
            break;
          case REMOTE_CONFIG_TABLES.BILL:
            columnKey = FILTER_KEYS.BILL_DUE_DATE;
            columnValue = {
              value: DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              ),
              condition
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'gt' || condition === 'lt') &&
        columnObj.columnCode === FILTER_KEYS.SALES_ORDER_SHIP_BY_DATE
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.SALES_ORDER:
            columnKey = FILTER_KEYS.SALES_ORDER_SHIP_BY_DATE;
            columnValue = {
              value: DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              ),
              condition
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.APPROVAL_STATUS
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
          case REMOTE_CONFIG_TABLES.QUOTATION:
          case REMOTE_CONFIG_TABLES.BILL:
          case REMOTE_CONFIG_TABLES.PURCHASE_ORDER:
            columnKey = FILTER_KEYS.APPROVAL_STATUS;
            columnValue = newValue.toUpperCase();
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.PAYMENT_STATUS
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.INVOICE:
          case REMOTE_CONFIG_TABLES.BILL:
            columnKey = FILTER_KEYS.PAYMENT_STATUS;
            columnValue = newValue.toUpperCase();
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        (columnObj.columnCode === FILTER_KEYS.RECEIVED_GOODS_STATUS ||
          columnObj.columnCode === FILTER_KEYS.RECEIPT_STATUS)
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.PURCHASE_ORDER:
          case REMOTE_CONFIG_TABLES.BILL:
            columnKey = FILTER_KEYS.RECEIPT_STATUS;
            columnValue = newValue.toUpperCase();
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.TYPE &&
        table === REMOTE_CONFIG_TABLES.COA
      ) {
        let key: any;
        let value: any;
        switch (newValue) {
          case newValue:
            key = FILTER_KEYS.ACC_GROUP_ID;
            value = newValue;
            break;
        }
        filteredQueryList.push({
          [key]: value
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.TYPE
      ) {
        let key: any;
        let value: any;
        switch (newValue) {
          case FILTER_KEYS.VENDOR:
            key = FILTER_KEYS.VENDOR;
            value = true;
            break;
          case FILTER_KEYS.CUSTOMER:
            key = FILTER_KEYS.CUSTOMER;
            value = true;
            break;
          case FILTER_KEYS.BILL_OF_MATERIALS:
            key = FILTER_KEYS.TYPE;
            value = PRODUCT_TYPE.BILL_OF_MATERIALS;
            break;
          case FILTER_KEYS.TRACKED:
            key = FILTER_KEYS.TYPE;
            value = PRODUCT_TYPE.TRACKED;
            break;
          case FILTER_KEYS.NON_TRACKED:
            key = FILTER_KEYS.TYPE;
            value = PRODUCT_TYPE.NON_TRACKED.replaceAll('_', '');
            break;
        }
        filteredQueryList.push({
          [key]: value
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.DOCUMENT_TYPE
      ) {
        let key: any;
        let value: any;

        switch (newValue.toUpperCase()) {
          case FILTER_KEYS.EXPENSE:
            key = 'docType';
            value = 'EXPENSE';
            break;
          case FILTER_KEYS.EXPENSE_PREPAYMENT:
            key = FILTER_KEYS.DOC_TYPE;
            value = FILTER_KEYS.EXPENSE_PREPAYMENT;
            break;
          case FILTER_KEYS.DEPOSIT:
            key = FILTER_KEYS.DOC_TYPE;
            value = FILTER_KEYS.DEPOSIT;
            break;
          case FILTER_KEYS.DEPOSIT_ADVPAYMENT:
            key = FILTER_KEYS.DOC_TYPE;
            value = FILTER_KEYS.DEPOSIT_ADVPAYMENT;
            break;
          case DOC_TYPE.BILL:
            key = FILTER_KEYS.DOC_TYPE;
            value = DOC_TYPE.BILL;
            break;
          case '':
            key = '';
            value = '';
            break;
        }

        filteredQueryList.push({
          [key]: value
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.ST_RECONCILED_STATUS
      ) {
        switch (newValue) {
          case FILTER_KEYS.RECONCILED:
            columnKey = FILTER_KEYS.ST_RECONCILED_STATUS;
            columnValue = Utility.convertInTitleCase(FILTER_KEYS.RECONCILED);
            break;
          case FILTER_KEYS.UNRECONCILED:
            columnKey = FILTER_KEYS.ST_RECONCILED_STATUS;
            columnValue = Utility.convertInTitleCase(FILTER_KEYS.UNRECONCILED);
            break;
          case FILTER_KEYS.IN_PROGRESS:
            columnKey = FILTER_KEYS.ST_RECONCILED_STATUS;
            columnValue = Utility.convertInTitleCase(FILTER_KEYS.IN_PROGRESS);
            break;
          case FILTER_KEYS.ALL_STATUS:
            columnKey = '';
            columnValue = '';
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.PAYMENT_TYPE
      ) {
        switch (newValue) {
          case newValue:
            columnKey = FILTER_KEYS.DOCUMENT_TYPE;
            columnValue = newValue.toUpperCase();
            break;
          case FILTER_KEYS.ALL:
            columnKey = '';
            columnValue = '';
            break;
        }
        if (columnKey !== '' && columnValue !== '') {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.IS_CREATED_BY_RULE
      ) {
        switch (newValue) {
          case FILTER_KEYS.AUTO_CREATED:
            columnKey = FILTER_KEYS.CREATED_BY_RULE;
            columnValue = true;
            break;
          case FILTER_KEYS.NOT_AUTO_CREATED:
            columnKey = FILTER_KEYS.CREATED_BY_RULE;
            columnValue = false;
            break;
          case FILTER_KEYS.ALL:
            columnKey = '';
            columnValue = '';
            break;
        }
        if (columnKey !== '' && columnValue !== '') {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.RECURRING_JE
      ) {
        switch (newValue) {
          case FILTER_KEYS.RECURRING_JE.toLowerCase():
            columnKey = FILTER_KEYS.RECURRING_JE;
            columnValue = true;
            break;
          case FILTER_KEYS.NON_RECURRING_JE.toLowerCase():
            columnKey = FILTER_KEYS.RECURRING_JE;
            columnValue = false;
            break;
          case FILTER_KEYS.ALL:
            columnKey = '';
            columnValue = '';
            break;
        }
        if (columnKey !== '' && columnValue !== '') {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.INVOICE_NOW
      ) {
        let _multipleCondition = false;
        let _mutlipleConditions = [];
        switch (newValue) {
          case INVOICE_NOW_TYPES.CANCELLED.toLowerCase():
            columnKey = 'isCancelEinvoice';
            columnValue = true;
            break;
          case INVOICE_NOW_TYPES.PENDING.toLowerCase():
            columnKey = 'eInvoiceStatusIndia';
            columnValue = -1;
            break;
          case INVOICE_NOW_TYPES.FAILED.toLowerCase():
            columnKey = 'eInvoiceStatusIndia';
            columnValue = 0;
            break;
          case INVOICE_NOW_TYPES.GENERATED.toLowerCase():
            _multipleCondition = true;
            _mutlipleConditions.push(
              {
                columnKey: 'eInvoiceStatusIndia',
                columnValue: 1
              },
              {
                columnKey: 'isCancelEinvoice',
                columnValue: false
              }
            );
            break;
        }
        if (columnKey !== '' && columnValue !== '') {
          if (_multipleCondition) {
            _mutlipleConditions.forEach((condition: any) => {
              filteredQueryList.push({
                [condition.columnKey]: condition.columnValue
              });
            });
          } else {
            filteredQueryList.push({
              [columnKey]: columnValue
            });
          }
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.CODE
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT_APPROVAL_LIST:
            columnKey = FILTER_KEYS.CODE;
            columnValue = {
              value: newValue,
              condition: 'apx'
            };
            break;
          case REMOTE_CONFIG_TABLES.STOCK_TRANSFER:
          case REMOTE_CONFIG_TABLES.STOCK_TRANSFER_APPROVAL_LIST:
            columnKey = FILTER_KEYS.CODE;
            columnValue = {
              value: newValue,
              condition: 'apx'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.WAREHOUSE
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT_APPROVAL_LIST:
            columnKey = 'warehouseCode';
            columnValue = {
              value: newValue,
              condition: 'apx'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.ADJUSTMENT_REASON
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT_APPROVAL_LIST:
            columnKey = FILTER_KEYS.REASON;
            columnValue = {
              value: newValue,
              condition: 'apx'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.STOCK_TYPE
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT_APPROVAL_LIST:
            columnKey = FILTER_KEYS.STOCK_TYPE;
            columnValue = {
              value: newValue,
              condition: 'apx'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.DOCUMENT_DATE
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.EXPENSE:
            columnKey = FILTER_KEYS.PAYMENT_DATE;
            columnValue = {
              value: DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              condition: 'eq'
            };
            break;
          case REMOTE_CONFIG_TABLES.DEPOSIT:
            columnKey = FILTER_KEYS.PAYMENT_DATE;
            columnValue = {
              value: DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              condition: 'eq'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.CONTACT_NAME
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.EXPENSE:
            columnKey = FILTER_KEYS.CONTACT_NAME;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
          case REMOTE_CONFIG_TABLES.DEPOSIT:
            columnKey = FILTER_KEYS.CONTACT_NAME;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
        }
        if (columnKey) {
          filteredQueryList.push({
            [columnKey]: columnValue
          });
        }
      } else if (
        (condition === 'eq' || condition === 'c') &&
        (columnObj.columnCode === FILTER_KEYS.PAY_FROM ||
          columnObj.columnCode === FILTER_KEYS.PAY_TO)
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.EXPENSE:
            columnKey = FILTER_KEYS.PAY_FROM_ACCOUNT_CODE;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
          case REMOTE_CONFIG_TABLES.DEPOSIT:
            columnKey = FILTER_KEYS.PAY_TO_ACCOUNT_CODE;
            columnValue = {
              value: newValue,
              condition: 'eq'
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.SUPPLIER_INVOICE_NO
      ) {
        switch (table) {
          case REMOTE_CONFIG_TABLES.BILL:
            columnKey = FILTER_KEYS.SUPPLIER_INVOICE_NO;
            columnValue = {
              value: newValue?.toUpperCase()?.trim(),
              condition
            };
            break;
        }
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      } else if (
        (condition === 'eq' || condition === 'c') &&
        columnObj.columnCode === FILTER_KEYS.DISPATCHED_STATUS &&
        table === REMOTE_CONFIG_TABLES.WORK_OUT
      ) {
        columnKey = FILTER_KEYS.DISPATCHED_STATUS;
        columnValue = newValue.toUpperCase();
        filteredQueryList.push({
          [columnKey]: columnValue
        });
      }
      if (!columnObj?.columnCode?.includes('custom_')) {
        if (table === REMOTE_CONFIG_TABLES.PURCHASE_REQUISITION) {
          if (
            condition === 'eq' ||
            condition === 'c' ||
            condition === 'gt' ||
            condition === 'lt'
          ) {
            const columnKey =
              REQUISTIION_ENTITY_MAPPING[
                columnObj.columnCode as keyof typeof REQUISTIION_ENTITY_MAPPING
              ];

            if (!Utility.isEmpty(columnKey)) {
              switch (columnKey) {
                case 'shipByDate':
                case 'purchaseRequestDate':
                  columnValue = {
                    value: DateFormatService.getDateStrFromDate(
                      newValue,
                      BOOKS_DATE_FORMAT['YYYY-MM-DD']
                    ),
                    condition: condition
                  };
                  break;
                default:
                  columnValue = {
                    value: newValue,
                    condition: condition
                  };
                  break;
              }
              if (columnKey !== '' && columnValue !== '') {
                filteredQueryList.push({
                  [columnKey]: columnValue
                });
              }
            }
          }
        } else if (table === REMOTE_CONFIG_TABLES.PURCHASE_ORDER) {
          if (
            condition === 'eq' ||
            condition === 'c' ||
            condition === 'gt' ||
            condition === 'lt'
          ) {
            const columnKey = columnObj.columnCode;

            if (!Utility.isEmpty(columnKey)) {
              switch (columnKey) {
                case 'dueDate':
                  columnValue = {
                    value: DateFormatService.getDateStrFromDate(
                      newValue,
                      BOOKS_DATE_FORMAT['YYYY-MM-DD']
                    ),
                    condition: condition
                  };
                  break;
                default:
                  columnValue = {
                    value: newValue,
                    condition: condition
                  };
                  break;
              }
              if (columnKey !== '' && columnValue !== '') {
                filteredQueryList.push({
                  [columnKey]: columnValue
                });
              }
            }
          }
        }
      }

      if (!columnObj?.columnCode?.includes('custom_')) {
        if (table === REMOTE_CONFIG_TABLES.WORK_ORDERS) {
          if (
            condition === 'eq' ||
            condition === 'c' ||
            condition === 'gt' ||
            condition === 'lt'
          ) {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.WORK_ORDER_NUMBER:
                columnKey = FILTER_KEYS.WORK_ORDER_NUMBER;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.PARENT_WORK_ORDER_NUMBER_KEY:
                columnKey = FILTER_KEYS.PARENT_WORK_ORDER_NUMBER;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.STATUS:
                columnKey = FILTER_KEYS.STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_ORDER:
                columnKey = FILTER_KEYS.WORK_ORDER;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.SALES_ORDER:
                columnKey = FILTER_KEYS.SALES_ORDER;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_ORDER_NAME:
                columnKey = FILTER_KEYS.WORK_ORDER_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.OPERATION_NAME:
                columnKey = FILTER_KEYS.OPERATION_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORKSTARION_NAME:
                columnKey = FILTER_KEYS.WORKSTARION_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_ORDER_PRODUCT_NAME:
                columnKey = FILTER_KEYS.WORK_ORDER_PRODUCT_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.PRODUCT_DOC_SEQ_CODE:
                columnKey = FILTER_KEYS.PRODUCT_DOC_SEQ_CODE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WO_DELIVERY_DATE:
                columnKey = FILTER_KEYS.WO_DELIVERY_DATE;
                columnValue = {
                  value: DateFormatService.getDateStrFromDate(
                    newValue,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_ORDER_ACTUAL_START_DATE:
                columnKey = FILTER_KEYS.WORK_ORDER_ACTUAL_START_DATE;
                columnValue = {
                  value: DateFormatService.getDateStrFromDate(
                    newValue,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_ORDER_ACTUAL_END_DATE:
                columnKey = FILTER_KEYS.WORK_ORDER_ACTUAL_END_DATE;
                columnValue = {
                  value: DateFormatService.getDateStrFromDate(
                    newValue,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  condition: condition
                };
                break;
              case FILTER_KEYS.CONTACT_NAME:
                columnKey = FILTER_KEYS.CONTACT_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.WORKSTATIONS) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.WORK_STATION_NAME:
                columnKey = FILTER_KEYS.WORK_STATION_NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.DESCRIPTION:
                columnKey = FILTER_KEYS.DESCRIPTION;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.PRODUCTION_CAPACITY:
                columnKey = FILTER_KEYS.PRODUCTION_CAPACITY;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.OPERATORS) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.NAME:
                columnKey = FILTER_KEYS.NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.COST_PER_HOUR:
                columnKey = FILTER_KEYS.COST_PER_HOUR;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.FIXED_RATE:
                columnKey = FILTER_KEYS.FIXED_RATE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.CONTACT_NUMBER:
                columnKey = FILTER_KEYS.CONTACT_NUMBER;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.JOB_TITLE:
                columnKey = FILTER_KEYS.JOB_TITLE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.OPERATIONS) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.NAME:
                columnKey = FILTER_KEYS.NAME;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.WORK_STATION_NAME:
                columnKey = FILTER_KEYS.DEFAULT_WORK_STATION;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.DESCRIPTION:
                columnKey = FILTER_KEYS.DESCRIPTION;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.COST_PER_HOUR:
                columnKey = FILTER_KEYS.COST_PER_HOUR;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.FIXED_RATE:
                columnKey = FILTER_KEYS.FIXED_RATE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.WORK_OUT) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.DISPATCHED_STATUS:
                columnKey = FILTER_KEYS.DISPATCHED_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.RECEIPT_STATUS:
                columnKey = FILTER_KEYS.RECEIPT_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.BILL_STATUS:
                columnKey = FILTER_KEYS.BILL_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.BOOKS_GOODS_RECEIPT_MASTER) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.SEQUENCE_NO_GR:
                columnKey = FILTER_KEYS.CODE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.GRN_DATE:
                columnKey = FILTER_KEYS.GRN_DATE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.CREATED_BY:
                columnKey = COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.CREATED_BY;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.BOOKS_GOODS_RECEIPT_APPROVAL_LIST) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.SEQUENCE_NO_GR:
                columnKey = FILTER_KEYS.CODE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.GRN_DATE:
                columnKey = FILTER_KEYS.GRN_DATE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST
                .APPROVAL_STATUS:
                columnKey =
                  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.APPROVAL_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.CREATED_BY:
                columnKey =
                  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.CREATED_BY;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.BOOKS_FULFILLMENT_MASTER) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.SEQUENCE_NO_FF:
                columnKey = FILTER_KEYS.CODE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.FULFILLMENT_DATE:
                columnKey = FILTER_KEYS.FULFILLMENT_DATE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.CREATED_BY:
                columnKey = COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.CREATED_BY;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.BOOKS_FULFILLMENT_APPROVAL_LIST) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.SEQUENCE_NO_FF:
                columnKey = FILTER_KEYS.CODE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case FILTER_KEYS.FULFILLMENT_DATE:
                columnKey = FILTER_KEYS.FULFILLMENT_DATE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS:
                columnKey =
                  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.CREATED_BY:
                columnKey =
                  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.CREATED_BY;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT_APPROVAL_LIST) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS:
                columnKey =
                  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case 'memo':
                columnKey = 'notes';
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.STOCK_REQUEST_APPROVAL_LIST) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS:
                columnKey =
                  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
              case 'stockRequestDate':
                columnKey = 'stockRequestDate';
                columnValue = {
                  value: DateFormatService.getDateStrFromDate(
                    newValue,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
        if (table === REMOTE_CONFIG_TABLES.STOCK_TRANSFER_APPROVAL_LIST) {
          if (condition === 'eq' || condition === 'c') {
            switch (columnObj.columnCode) {
              case COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS:
                columnKey =
                  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.APPROVAL_STATUS;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }

        if (table === REMOTE_CONFIG_TABLES.REQUEST_FOR_QUOTATION) {
          if (
            condition === 'eq' ||
            condition === 'c' ||
            condition === 'gt' ||
            condition === 'lt'
          ) {
            switch (columnObj.columnCode) {
              case FILTER_KEYS.RFQ_RFQ_DATE:
                columnKey = FILTER_KEYS.RFQ_RFQ_DATE;
                columnValue = {
                  value: DateFormatService.getDateStrFromDate(
                    newValue,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  condition: condition
                };

                break;

              case FILTER_KEYS.RFQ_DOC_SEQ_CODE:
                columnKey = FILTER_KEYS.RFQ_DOC_SEQ_CODE;
                columnValue = {
                  value: newValue,
                  condition: condition
                };
                break;
            }
            if (columnKey !== '' && columnValue !== '') {
              filteredQueryList.push({
                [columnKey]: columnValue
              });
            }
          }
        }
      }
    });
    return filteredQueryList;
  }

  private static getNewValue(value: any, allowLowercase = true) {
    return Array.isArray(value)
      ? typeof value[0] !== 'string'
        ? value[0]
        : allowLowercase
        ? value[0].toLowerCase()
        : value[0]
      : value instanceof Date
      ? value
      : allowLowercase
      ? value.toLowerCase()
      : value;
  }

  static getFilteredQueryList(oldList: any[]) {
    const customFieldValueArr: any = [],
      newFilteredQueyListArr: any = [],
      customField: any = [];

    oldList.map((item: any) => {
      if (Object.keys(item)[0] === 'customfield') {
        customFieldValueArr.push(Object.values(item)[0]);
      } else {
        newFilteredQueyListArr.push(item);
      }
    });
    customField.push({ customfield: customFieldValueArr });

    return { newFilteredQueyListArr, customField };
  }

  static getQueryString(queries: any) {
    const params = queries
      .map((i: any) =>
        Object.entries(i).map(([key, val]) => {
          const k = key as any;
          const v = val as any;

          if (k === 'customfield') {
            const value = JSON.stringify(v);

            return `${k}=${encodeURIComponent(value)}`;
          } else {
            if (typeof v !== 'object') {
              let conditionSign: string = '=';
              switch (v?.condition) {
                case 'eq':
                  conditionSign = '=';
                  break;
                case 'gt':
                  conditionSign = '>=';
                  break;
                case 'lt':
                  conditionSign = '<=';
                  break;
                case 'apx':
                case 'c':
                  conditionSign = '~';
                  break;
                default:
                  break;
              }
              if (JSON.stringify(k) && JSON.stringify(v)) {
                return `${k}${conditionSign}${
                  v?.value ? v?.value : encodeURIComponent(v)
                }`;
              }
              return '';
            } else {
              if (JSON.stringify(k) && JSON.stringify(v)) {
                if (typeof v !== 'object') {
                  return `${k}=${encodeURIComponent(v)}`;
                } else {
                  let conditionSign: string = '=';
                  switch (v?.condition) {
                    case 'eq':
                      conditionSign = '=';
                      break;
                    case 'gt':
                      conditionSign = '>=';
                      break;
                    case 'lt':
                      conditionSign = '<=';
                      break;
                    case 'apx':
                    case 'c':
                      conditionSign = '~';
                      break;
                    default:
                      break;
                  }
                  return `${k}${conditionSign}${encodeURIComponent(v?.value)}`;
                }
              }
            }
            return '';
          }
        })
      )
      .join(',');
    return params;
  }

  static getTimeZoneOffsetValue() {
    var offset = new Date().getTimezoneOffset();
    var o = Math.abs(offset);
    return (
      (offset < 0 ? '+' : '-') +
      ('00' + Math.floor(o / 60)).slice(-2) +
      ':' +
      ('00' + (o % 60)).slice(-2)
    );
  }

  static getMonthName(
    date: Date | string,
    dateFormat: DATE_FORMAT,
    shortName: boolean = true
  ): string {
    let receivedDate = date;
    let expectedMonthName = '';
    if (typeof date !== 'string') {
      receivedDate = Utility.formatDate(date, dateFormat);
    }
    const [first, second, third] = receivedDate.toString().split('-');
    if (
      dateFormat === DATE_FORMAT.DD_MM_YYYY ||
      dateFormat === DATE_FORMAT.YYYY_MM_DD
    ) {
      const monthNumber = parseInt(second);

      expectedMonthName = shortName
        ? MONTHS_FULL[monthNumber - 1].name.substring(0, 3)
        : MONTHS_FULL[monthNumber - 1].name;
    }
    return expectedMonthName;
  }

  static getCloseDateFY = (isOpening = false) => {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    const tenantCloseDateFY = tenantDetails.fyClosingPeriodEndDate;
    const closeDateFY: Date = tenantCloseDateFY
      ? addDays(
          DateFormatService.getDateFromStr(
            tenantCloseDateFY,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          isOpening ? 0 : 1
        )
      : DateFormatService.getDateFromStr(
          tenantDetails.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
    return closeDateFY;
  };

  static validationForClosedDate(
    document: any,
    isOpening = false
  ): {
    isDisable: boolean;
    tooltipMsg: string;
  } {
    const reduxStore = ReduxStore;
    const tenantDetails = reduxStore.getState().authInfo.currentTenantInfo.data;
    const docDate =
      document.documentDate ||
      document.salesInvoiceDate ||
      document.salesOrderDate ||
      document.purchaseInvoiceDate ||
      document.jeDate ||
      document.cnDate ||
      document.dnDate;
    const docToDate = DateFormatService.getDateFromStr(
      docDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    const tenantCloseDateFY = tenantDetails.fyClosingPeriodEndDate;
    let response: { isDisable: boolean; tooltipMsg: string };

    let closeDateFY: Date = Utility.getCloseDateFY(isOpening);
    if (
      (tenantCloseDateFY && closeDateFY > docToDate) ||
      (!isOpening && !tenantCloseDateFY && closeDateFY > docToDate)
    ) {
      response = { isDisable: true, tooltipMsg: '' };
    } else {
      response = { isDisable: false, tooltipMsg: '' };
    }
    return response;
  }

  static getTenantSpecificApiCode(
    moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME
  ): string {
    let countrySpecificURL = '';

    const countryCode = AuthService.userDetails
      ? AuthService.userDetails.country
      : '';

    switch (countryCode) {
      case COUNTRY_CODES.IN:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.RECEIVE_PAYMENT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.MAKE_PAYMENT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.BILL ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.JOB_WORK_OUT
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.IN;
        }
        break;
      case COUNTRY_CODES.IL:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.RECEIVE_PAYMENT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.MAKE_PAYMENT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.BILL ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.BULK_MAKE_PAYMENT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.BULK_RECEIVE_PAYMENT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.IL;
        }
        break;
      case COUNTRY_CODES.US:
        break;

      case COUNTRY_CODES.SG:
        break;

      case COUNTRY_CODES.ID:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.INDONESIA;
        }
        break;
      case COUNTRY_CODES.MY:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.MALAYSIA;
        }
        break;

      case COUNTRY_CODES.PH:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.PHILIPPINES;
        }
        break;

      case COUNTRY_CODES.NZ:
        break;

      case COUNTRY_CODES.DE:
        break;

      case COUNTRY_CODES.AE:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.UAE;
        }
        break;
      case COUNTRY_CODES.UK:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.UK;
        }
        break;
      case COUNTRY_CODES.BE:
        break;
      case COUNTRY_CODES.AU:
        break;
      case COUNTRY_CODES.CA:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.CANADA;
        }
        break;

      default:
        break;
    }
    return countrySpecificURL;
  }

  static getCountryApiExtension = () => {
    var apiExtension =
      Store.getState().authInfo.currentTenantInfo.data.country?.toLowerCase();
    if (
      Store.getState().authInfo.currentTenantInfo.data.country?.toLowerCase() ===
        'sg' ||
      Store.getState().authInfo.currentTenantInfo.data.country?.toLowerCase() ===
        'us'
    ) {
      apiExtension = '';
    }
    return apiExtension;
  };

  static billedQuantity(buyItem: any) {
    return buyItem.productQuantity - buyItem.pendingQuantity;
  }

  static receivedQuantity(buyItem: any) {
    return (
      (buyItem.receivedQuantityInBills || 0) +
      (buyItem.receivedQuantityInOrder || 0) +
      (buyItem.localizedBaseQtyDiffReceivedInOrder || 0) +
      (buyItem.localizedBaseQtyDiffReceivedInBills || 0)
    );
  }

  static pendingToBeReceivedQuantity(buyItem: any) {
    return Utility.roundingOff(
      buyItem.productQuantity +
        (buyItem.localizedBaseQtyDiff || 0) -
        Utility.receivedQuantity(buyItem),
      QTY_ROUNDOFF_PRECISION
    );
  }

  static receivedButNotBilledQuantity(buyItem: any) {
    const qty =
      Utility.receivedQuantity(buyItem) - Utility.billedQuantity(buyItem);
    return qty >= 0 &&
      buyItem.receivedQuantityInOrder > buyItem.receivedQuantityInBills
      ? qty
      : 0;
  }

  static getPendingQuantity(item: any, useUomSchema: boolean = false) {
    // for Sales module only
    let pendingQty = 0;

    if (item.productQuantity && item.quantityFulfilled) {
      let fulfilledQty = item.quantityFulfilled;
      if (item?.isLocalizedUomQty) {
        fulfilledQty = calculateQtyDiffAccordingSchema(
          item.quantityFulfilled,
          item.unlocalizedDocumentUOMSchemaDefinition,
          item.documentUOMSchemaDefinition
        );
      }
      pendingQty = Utility.roundingOff(
        Number(item.productQuantity) - Number(fulfilledQty),
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    } else if (item.productQuantity && item.fulfilledQuantity) {
      let fulfilledQty = item.fulfilledQuantity;
      if (item?.isLocalizedUomQty) {
        fulfilledQty = calculateQtyDiffAccordingSchema(
          item.fulfilledQuantity,
          item.unlocalizedDocumentUOMSchemaDefinition,
          item.documentUOMSchemaDefinition
        );
      }
      pendingQty = Utility.roundingOff(
        Number(item.productQuantity) - Number(fulfilledQty),
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    } else if (item.productQuantity) {
      pendingQty = item.productQuantity;
    } else if (item.pendingQuantity) {
      pendingQty = item.pendingQuantity;
    }

    if (useUomSchema && item.documentUOMSchemaDefinition && pendingQty) {
      pendingQty = this.getUomQuantity(
        pendingQty,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    return pendingQty;
  }

  static getPendingQuantityForPopup(item: any, useUomSchema: boolean = false) {
    let pendingQty = 0;

    if (this.isNumber(item?.pendingQuantity)) {
      pendingQty = item.pendingQuantity;
    } else if (this.isNumber(item?.productQuantity)) {
      pendingQty = item.productQuantity;
    }

    if (useUomSchema && item.documentUOMSchemaDefinition && pendingQty) {
      pendingQty = this.getUomQuantity(
        pendingQty,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    return pendingQty;
  }

  static isAdditionalQuantityReceive(item: any, documentType: any) {
    return (
      documentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      item.maxQtyToReceive > item.receivedQuantityInOrder &&
      Utility.isJWOReceiveDispatchAdditionalQtyEnable()
    );
  }

  static peppolIdValidator(
    peppolNumber: string,
    uenNumber: string
  ): { hasError: boolean; errorMsg?: string } {
    let peppolId: string = peppolNumber;
    if (peppolId) {
      peppolId = peppolId.trim();
      if (peppolId.length === 0) {
        return { hasError: true, errorMsg: 'Incorrect length of peppolId' };
      } else if (
        !peppolId.toLowerCase().startsWith(COMMON_CONSTANT.PEPPOL_ID_FORMAT)
      ) {
        return { hasError: true, errorMsg: 'Incorrect Prefix of peppolId' };
      } else if (
        uenNumber?.trim().length > 0 &&
        !peppolId.endsWith(uenNumber)
      ) {
        return { hasError: true, errorMsg: 'Incorrect UEN number' };
      } else {
        return { hasError: false };
      }
    }
    return { hasError: true, errorMsg: 'Invalid Peoppol Id' };
  }

  static getAvailableQuantity = (item: any) => {
    const availableItemQuantity = item.availableProductQuantity
      ? item.availableProductQuantity
      : item.availableQuantity;
    let avalableQty = 0;
    if (item.documentUOMSchemaDefinition) {
      avalableQty = Utility.getUomQuantity(
        availableItemQuantity,
        item.documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    } else {
      avalableQty = availableItemQuantity;
    }
    return avalableQty;
  };

  static preferredWarehouse(warehouseArr: any, items: any) {
    let requiredWarehouse: any;
    let primaryWarehouse: any;
    let otherWarehouses: any = [];
    warehouseArr.forEach((warehouse: any) =>
      warehouse.primary
        ? (primaryWarehouse = warehouse)
        : otherWarehouses.push(warehouse)
    );
    const isPrimaryWarehouseTheOne = items.some((item: any) => {
      if (
        primaryWarehouse.productAvailableQuantity.hasOwnProperty(
          item.productCode
        )
      ) {
        const available =
          primaryWarehouse.productAvailableQuantity[item.productCode];
        return (
          available > item.productQuantity || available > item.pendingQuantity
        );
      } else {
        return false;
      }
    });
    if (isPrimaryWarehouseTheOne) {
      requiredWarehouse = primaryWarehouse;
    } else {
      let thisWarehouse: any;
      for (let i = 0; i < otherWarehouses.length; ++i) {
        thisWarehouse = otherWarehouses[i];
        let isThisWarehouseTheOne = items.some((item: any) => {
          if (
            thisWarehouse.productAvailableQuantity.hasOwnProperty(
              item.productCode
            )
          ) {
            const available =
              thisWarehouse.productAvailableQuantity[item.productCode];
            return (
              available > item.productQuantity ||
              available > item.pendingQuantity
            );
          } else {
            return false;
          }
        });
        if (isThisWarehouseTheOne) {
          requiredWarehouse = thisWarehouse;
          break;
        }
      }
    }
    if (!requiredWarehouse) {
      requiredWarehouse = primaryWarehouse;
    }
    return requiredWarehouse;
  }

  static getFinancialStartDate() {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    const currenctDate = new Date();
    let fromDate = new Date(tenantDetails.financialStartDate);
    let numberOfYears = differenceInYears(currenctDate, fromDate);
    fromDate.setFullYear(fromDate.getFullYear() + numberOfYears);
    fromDate = DateFormatService.updateDateWithTimezone(fromDate);
    return fromDate;
  }
  static addInvoiceDataForAutomation(
    doc: any,
    draft: any,
    currentTenant: any,
    booksDateFormat: any,
    paymentTermMap: any
  ) {
    doc.documentType = 'SALES_INVOICE';
    doc.paymentStatus = PAYMENT_STATUS.PENDING;
    doc.fulfillmentStatus = FULFILLMENT_STATUS.UNFULFILLED;
    doc.billFrom = currentTenant?.billingAddresses[0];
    doc.salesInvoiceItems = [];
    doc.contact = draft?.contactDto;
    doc.salesInvoiceDate =
      doc.salesInvoiceDate || DateFormatService.getDateStrFromDate(new Date());
    let defaultDueDate = DateFormatService.getDateFromStr(
      doc.salesInvoiceDate,
      booksDateFormat
    );
    let paymentTerm = Utility.isEmpty(doc?.contact?.paymentTermCode)
      ? 0
      : paymentTermMap.get(Number(doc?.contact.paymentTermCode)).termDays;
    defaultDueDate.setDate(defaultDueDate.getDate() + paymentTerm);
    doc.salesInvoiceDueDate =
      doc?.salesInvoiceDueDate ||
      DateFormatService.getDateStrFromDate(defaultDueDate, booksDateFormat);
    doc.documentDate = doc.salesInvoiceDate;
    doc.validTillDate = doc.salesInvoiceDueDate;
  }
  static getFinancialEndDate() {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    const currenctDate = new Date();
    let fromDate = new Date(tenantDetails.financialStartDate);
    let numberOfYears = differenceInYears(currenctDate, fromDate);
    let todate;

    fromDate.setFullYear(fromDate.getFullYear() + numberOfYears);
    todate = new Date(fromDate);
    todate.setFullYear(todate.getFullYear() + 1);
    todate.setDate(todate.getDate() - 1);

    todate = DateFormatService.updateDateWithTimezone(todate);
    return todate;
  }
  static getMonthEndDate(): Date {
    const currentDate: Date = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const nextMonth = month === 12 ? 1 : month + 1;
    const nextYear = month === 12 ? year + 1 : year;
    const firstDayOfNextMonth: Date = new Date(nextYear, nextMonth - 1, 1);
    // @ts-ignore
    const monthEndDate = new Date(firstDayOfNextMonth - 1);
    return monthEndDate;
  }
  // Due days info for main document grid
  static getDueDateInfoForGrid(dueDate: Date, isDraft: boolean) {
    let dueStatus: { class: string; text: string };
    const newDueDate = addDays(dueDate, 1);
    let dueStatusNumber = differenceInDays(newDueDate, new Date());

    if (dueStatusNumber === 0) {
      dueStatus = {
        class: `fs-m ${isDraft ? '' : 'text-red'}`,
        text: i18n.t('GRID_INFO.DUES.TODAY')
      };
      return dueStatus;
    }

    if (dueStatusNumber > 0) {
      dueStatusNumber = Math.abs(dueStatusNumber);
      const dateTextSuffix =
        dueStatusNumber > 1
          ? i18n.t('GRID_INFO.DUES.DAYS')
          : i18n.t('GRID_INFO.DUES.DAY');
      dueStatus = {
        class: 'fs-m',
        text: `${i18n.t(
          'GRID_INFO.DUES.DUE_IN'
        )} ${dueStatusNumber} ${dateTextSuffix}`
      };
    } else {
      dueStatusNumber = Math.abs(dueStatusNumber);
      const dateTextSuffix =
        dueStatusNumber > 1
          ? i18n.t('GRID_INFO.DUES.OVERDUE_PLURAL')
          : i18n.t('GRID_INFO.DUES.OVERDUE');
      dueStatus = {
        class: `fs-m ${isDraft ? '' : 'text-red'}`,
        text: `${dueStatusNumber} ${dateTextSuffix}`
      };
    }

    return dueStatus;
  }

  static isDocumentFieldTypeAndApprovalFieldSame(
    docType: any,
    approvalFieldType: string
  ) {
    if (docType === 'PURCHASE_INVOICE') {
      if (
        approvalFieldType === 'EXPENSE_PURCHASE_INVOICE' ||
        approvalFieldType === 'PURCHASE_INVOICE'
      ) {
        return true;
      }
    } else {
      if (docType === approvalFieldType) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  static isApproverButtonShow(docType: any, payload?: any, buttonType?: any) {
    if (
      payload?.approvalStatus === APPROVAL_STATUS['APPROVED'] ||
      payload?.approvalStatus === APPROVAL_STATUS['NOT_REQUIRED']
    ) {
      return false;
    }

    let approvalConditions = Store.getState().automation?.data;
    let loginUserEmail = Store.getState().authInfo?.userInfo?.data[0]?.email;
    let currentApproverCondition: any = [];
    let flag: any = false;
    let currentLevel = payload?.multiApprovalDetails?.currentLevel || 1;
    let currentNode: any;
    // let userSpecificList =
    //   !this.isEmpty(approvalConditions) &&
    //   approvalConditions.filter(
    //     (ele: any) =>
    //       ele.fields[0].field_type === docType &&
    //       ele.fields[0].approver.split(',').includes(loginUserEmail)
    //   );
    let docTypeFiltered =
      !Utility.isEmpty(approvalConditions) &&
      approvalConditions.filter(
        (ele: any) =>
          this.isDocumentFieldTypeAndApprovalFieldSame(
            docType,
            ele.fields[0].field_type
          ) &&
          (!payload?.multiApprovalDetails?.approvalRequiredFor ||
            ele.fields[0].approvalFor.includes(
              payload.multiApprovalDetails.approvalRequiredFor
            ))
      );
    let userSpecificList =
      !Utility.isEmpty(docTypeFiltered) &&
      docTypeFiltered.filter((ele: any) =>
        this.isDocumentFieldTypeAndApprovalFieldSame(
          docType,
          ele.fields[0].field_type
        ) && ele.fields[0].approver
          ? ele.fields[0].approver?.split(',').includes(loginUserEmail)
          : !Utility.isEmpty(
              ele.fields[0].multiApprovalDetails?.filter((item: any) =>
                !payload.hasOwnProperty('multiApprovalDetails')
                  ? item.level === 1 && item.approver?.includes(loginUserEmail)
                  : item.level ===
                      payload?.multiApprovalDetails?.currentLevel &&
                    item.approver?.includes(loginUserEmail)
              )
            )
      );

    if (this.isEmpty(userSpecificList)) {
      return false;
    } else if (this.isEmpty(payload) && !this.isEmpty(userSpecificList)) {
      return true;
    } else {
      currentApproverCondition = userSpecificList[0];
      for (let index = 0; index < userSpecificList.length; index++) {
        flag = Utility.isConditionApproverApply(
          [userSpecificList[index]],
          payload
        );
        if (flag) {
          currentApproverCondition = userSpecificList[index];
          break;
        }
      }
      if (currentApproverCondition?.fields[0]?.multiApprovalDetails) {
        currentNode =
          currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
            (ele: any) => ele.level === currentLevel
          );
        flag = currentNode
          ? currentNode[0]?.approver?.includes(loginUserEmail)
          : false;

        if (
          !flag &&
          payload?.multiApprovalDetails &&
          !Utility.isEmpty(payload?.multiApprovalDetails.approvalHistory)
        ) {
          let allUsersFromCurrentLevelApproved = false;
          currentNode =
            currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
              (ele: any) => ele.level === currentLevel
            );
          const remainingApprovers = currentNode[0]?.approver.filter(
            (item: any) => {
              const hi = payload?.multiApprovalDetails.approvalHistory.find(
                (historyItem: any) =>
                  historyItem.approvedBy == item &&
                  historyItem.status == 'APPROVED'
              );
              return hi != undefined;
            }
          );
          if (currentNode && currentNode[0]?.condition == 'ANY') {
            if (remainingApprovers.length > 0) {
              allUsersFromCurrentLevelApproved = true;
            }
          } else if (currentNode && currentNode[0]?.condition == 'ALL') {
            if (remainingApprovers.length == currentNode[0]?.approver.length) {
              allUsersFromCurrentLevelApproved = true;
            }
          }
          if (allUsersFromCurrentLevelApproved) {
            currentNode =
              currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
                (ele: any) => ele.level === currentLevel + 1
              );
          }

          flag = currentNode
            ? currentNode[0]?.approver?.includes(loginUserEmail)
            : false;
        }
      }

      if (
        buttonType === APPROVAL_STATUS.APPROVED &&
        flag &&
        !Utility.isEmpty(payload?.multiApprovalDetails?.approverUsers)
      ) {
        flag =
          !payload?.multiApprovalDetails?.approverUsers?.[
            payload?.multiApprovalDetails?.currentLevel
          ]?.includes(loginUserEmail);
      } else if (
        buttonType === APPROVAL_STATUS.REJECTED &&
        flag &&
        !Utility.isEmpty(payload?.multiApprovalDetails?.rejectedUsers)
      ) {
        flag =
          !payload?.multiApprovalDetails?.rejectedUsers?.[
            payload?.multiApprovalDetails?.currentLevel
          ]?.includes(loginUserEmail) &&
          !payload?.multiApprovalDetails?.approverUsers?.[
            payload?.multiApprovalDetails?.currentLevel
          ]?.includes(loginUserEmail);
      }
      // let flag = this.isConditionApproverApply(userSpecificList, payload);
      return flag;
    }
  }

  static getUserSpecificAutomation(payload: any, approver: any) {
    let approvalConditions = Store.getState().automation?.data;
    let loginUserEmail = Store.getState().authInfo?.userInfo?.data[0]?.email;
    let currentApproverCondition: any = [];
    let flag: any = false;
    let userSpecificList: any = [];
    if (approver) {
      if (!Utility.isEmpty(approvalConditions)) {
        userSpecificList = approvalConditions.filter((ele: any) => {
          if (
            ele.fields[0].field_type === payload.docType &&
            ele.fields[0].approver
          ) {
            return ele.fields[0].approver?.split(',').includes(loginUserEmail);
          } else {
            if (ele.fields[0].field_type === payload.documentType) {
              const approvalCondition =
                ele.fields[0].multiApprovalDetails?.filter((item: any) => {
                  if (payload.hasOwnProperty('multiApprovalDetails')) {
                    return (
                      item.level ===
                        payload?.multiApprovalDetails?.currentLevel &&
                      item.approver?.includes(loginUserEmail)
                    );
                  } else {
                    return (
                      item.level === 1 &&
                      item.approver?.includes(loginUserEmail)
                    );
                  }
                });

              return approvalCondition?.length > 0;
            } else {
              return false;
            }
          }
        });
      }
    } else {
      userSpecificList =
        !this.isEmpty(approvalConditions) &&
        approvalConditions.filter(
          (ele: any) =>
            ele.fields[0].field_type === payload.documentType &&
            ele.fields[0].approvalFor.split(',').includes(loginUserEmail)
        );
    }

    if (this.isEmpty(userSpecificList)) {
      return false;
    } else if (this.isEmpty(payload) && !this.isEmpty(userSpecificList)) {
      return true;
    } else {
      const appliedConditions = [];
      for (let index = 0; index < userSpecificList.length; index++) {
        flag = Utility.isConditionApproverApply(
          [userSpecificList[index]],
          payload
        );
        if (flag) {
          appliedConditions.push(userSpecificList[index]);
        }
      }

      if (appliedConditions.length === 0) {
        currentApproverCondition = userSpecificList[0];
      } else {
        // select approval condition which is closest to approval
        let approverConditionIndex = 0;
        let minLevel =
          appliedConditions[0].fields[0].multiApprovalDetails.length;

        appliedConditions.forEach((c1: any, index: number) => {
          if (minLevel > c1.fields[0].multiApprovalDetails.length) {
            minLevel = c1.fields[0].multiApprovalDetails.length;
            approverConditionIndex = index;
          }
        });

        currentApproverCondition = appliedConditions[approverConditionIndex];
      }
    }
    return currentApproverCondition;
  }

  static isApprovalColumn(documentType: any) {
    let approvalConditions = Store.getState().automation?.data;
    let userSpecificList =
      !this.isEmpty(approvalConditions) &&
      approvalConditions.filter(
        (ele: any) => ele?.fields?.[0]?.field_type === documentType
      );
    if (this.isEmpty(userSpecificList)) {
      return false;
    }

    return true;
  }

  static isApprovalColumnRequired(documentTypes: string[]): boolean {
    return documentTypes.some((type) => this.hasApprovalCondition(type));
  }

  private static hasApprovalCondition(documentType: string): boolean {
    const approvalConditions = Store.getState().automation?.data;

    if (
      !Array.isArray(approvalConditions) ||
      this.isEmpty(approvalConditions)
    ) {
      return false;
    }

    const userSpecificList = approvalConditions.filter(
      (ele: any) => ele?.fields?.[0]?.field_type === documentType
    );

    return !this.isEmpty(userSpecificList);
  }

  static isApprovedAtCurrentLevel(payload: any) {
    if (this.isEmpty(payload)) return false;
    const latestApproval = payload?.approvalHistory?.reduce(
      (prev: any, curr: any) => {
        if (prev?.level <= curr?.level) {
          return curr;
        }
        return prev;
      }
    );
    return latestApproval?.status === APPROVAL_STATUS.APPROVED;
  }

  static async isApprovalRequired(payload: any, requiredFor?: string) {
    let approvalConditions = Store.getState().automation?.data;
    if (this.isEmpty(approvalConditions)) {
      // if approvalConditions empty check if any error
      try {
        const approvalConditionResponse = await Store.dispatch(
          fetchapprovalConditionList()
        );
        approvalConditions = approvalConditionResponse.payload;
      } catch (err) {
        showAlert(
          'Error!',
          'Problem while creating document. Please try again.'
        );
        return null;
      }
      return false;
    }

    let loginUserEmail =
      requiredFor || Store.getState().authInfo?.userInfo?.data[0]?.email;
    if (this.isEmpty(approvalConditions)) {
      return false;
    }
    let userSpecificList =
      !this.isEmpty(approvalConditions) &&
      approvalConditions.filter(
        (ele: any) =>
          ele.fields[0].field_type === payload.documentType &&
          ele.fields[0].approvalFor.split(',').includes(loginUserEmail)
      );
    if (this.isEmpty(userSpecificList)) {
      return false;
    }

    let flag = this.isConditionApproverApply(userSpecificList, payload);
    return flag;
  }

  static isConditionApproverApply(userSpecificList: any, payload: any) {
    let filteredList = userSpecificList
      .filter(
        (ele: any) => ele.fields[0] && ele.fields[0].conditions.length > 0
      )
      .map((ele: any) => ele.fields[0]);

    if (this.isEmpty(filteredList)) {
      return true;
    }
    let flag = filteredList.some((field: any) => {
      if (field.logicalOperator === 'and') {
        return field.conditions.every((condition: any) => {
          let operator = condition['opr'];
          let invoiceValue = this.getValueForConditions(condition, payload);
          let conditionValue = condition['value'];
          return this.checkApprovalCondition(
            operator,
            invoiceValue,
            conditionValue
          );
        });
      } else {
        return field.conditions.some((condition: any) => {
          return this.checkApprovalCondition(
            condition['opr'],
            this.getValueForConditions(condition, payload),
            condition['value']
          );
        });
      }
    });

    if (!flag) {
      let filteredList = userSpecificList
        .filter(
          (ele: any) => ele.fields[0] && ele.fields[0].conditions.length === 0
        )
        .map((ele: any) => ele.fields[0]);

      if (!this.isEmpty(filteredList)) {
        return true;
      }
    }
    return flag;
  }

  static getApproverEmail(payload: any) {
    let currentLevel = payload?.multiApprovalDetails?.currentLevel || 1;
    let email = new Map();
    let approvalConditions = Store.getState().automation.data;
    // let loginUserEmail = Store.getState().authInfo.userInfo.data[0].email;
    let loginUserEmail =
      payload?.multiApprovalDetails?.approvalRequiredFor ||
      Store.getState().authInfo.userInfo.data[0].email;
    if (this.isEmpty(approvalConditions)) {
      return email;
    }
    let userSpecificList =
      !this.isEmpty(approvalConditions) &&
      approvalConditions.filter(
        (ele: any) =>
          ele.fields[0].field_type === payload.documentType &&
          ele.fields[0].approvalFor.split(',').includes(loginUserEmail)
      );
    if (this.isEmpty(userSpecificList)) {
      return email;
    }
    let filteredListWoConditions = userSpecificList.filter(
      (ele: any) =>
        ele.fields[0] &&
        (this.isEmpty(ele.fields[0].conditions) ||
          ele.fields[0].conditions.length === 0)
    );
    filteredListWoConditions &&
      filteredListWoConditions.length > 0 &&
      filteredListWoConditions.forEach((field: any) => {
        email.set(
          field['wf_graph_id'].toString(),
          Utility.isEmpty(field.fields[0].approver)
            ? field.fields[0].multiApprovalDetails
                .filter((ele: any) => ele.level === currentLevel)[0]
                .approver?.toString()
            : field.fields[0].approver
        );
      });

    let filteredList = userSpecificList.filter(
      (ele: any) => ele.fields[0] && ele.fields[0].conditions.length > 0
    );

    filteredList &&
      filteredList.length > 0 &&
      filteredList.forEach((field: any) => {
        if (field.fields[0].logicalOperator === 'and') {
          let flag = field.fields[0].conditions.every((condition: any) => {
            return this.checkApprovalCondition(
              condition['opr'],
              this.getValueForConditions(condition, payload),
              condition['value']
            );
          });
          if (flag) {
            email.set(
              field['wf_graph_id'].toString(),
              Utility.isEmpty(field.fields[0].approver)
                ? field.fields[0].multiApprovalDetails
                    .filter((ele: any) => ele.level === currentLevel)[0]
                    .approver?.toString()
                : field.fields[0].approver
            );
          }
        } else {
          let flag = field.fields[0].conditions.some((condition: any) => {
            return this.checkApprovalCondition(
              condition['opr'],
              this.getValueForConditions(condition, payload),
              condition['value']
            );
          });
          if (flag) {
            email.set(
              field['wf_graph_id'].toString(),
              Utility.isEmpty(field.fields[0].approver)
                ? field.fields[0].multiApprovalDetails
                    .filter((ele: any) => ele.level === currentLevel)[0]
                    .approver?.toString()
                : field.fields[0].approver
            );
          }
        }
      });
    return email;
  }

  static checkApprovalCondition(
    operator: any,
    invoiceValue: any,
    conditionValue: any
  ) {
    let flag: any = false;
    if (invoiceValue && conditionValue) {
      switch (operator) {
        case 'eq':
          if (invoiceValue && invoiceValue.toString() === conditionValue) {
            flag = true;
          } else {
            flag = false;
          }
          break;
        case 'c':
          if (
            invoiceValue
              .toString()
              .toLowerCase()
              .includes(conditionValue.toString().toLowerCase())
          ) {
            flag = true;
          } else {
            flag = false;
          }
          break;
        case 'gt':
          if (invoiceValue > conditionValue) {
            flag = true;
          } else {
            flag = false;
          }
          break;
        case 'lt':
          if (invoiceValue < conditionValue) {
            flag = true;
          } else {
            flag = false;
          }
          break;
        case 'gteq':
          let invDate = DateFormatService.getDateFromStr(
            invoiceValue,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
          let conDate = DateFormatService.getDateFromStr(
            conditionValue,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
          if (invDate.getTime() >= conDate.getTime()) {
            flag = true;
          } else {
            flag = false;
          }
          break;
      }
    }
    return flag;
  }

  static getValueForConditions(condition: any, payload: any) {
    if (condition['code'] === 'contact') {
      return payload[condition['code']]?.name;
    } else if (condition['code'] === 'purchaseRequestDate') {
      return payload['documentDate'];
    } else if (condition['code'] === 'purchaseRequestDueDate') {
      return payload['dueDate'];
    } else if (condition['code'] === 'totalAmount') {
      let itemName = 'salesInvoiceItems';
      if (payload.documentType === DOC_TYPE.INVOICE) {
        itemName = 'salesInvoiceItems';
      } else if (payload.documentType === DOC_TYPE.BILL) {
        itemName = 'purchaseInvoiceProducts';
      } else if (payload.documentType === DOC_TYPE.ORDER) {
        itemName = 'purchaseOrderItems';
      } else if (payload.documentType === DOC_TYPE.QUOTE) {
        itemName = 'quotationItemDtoList';
      } else if (payload.documentType === DOC_TYPE.REQUISITION) {
        itemName = 'purchaseRequestItems';
      } else if (payload.documentType === DOC_TYPE.SALES_ORDER) {
        itemName = 'salesOrderItems';
      }
      let sum =
        payload &&
        payload[itemName]
          ?.map((item: any) => item.totalAmount)
          ?.reduce((prev: any, curr: any) => prev + curr, 0);
      return sum;
    } else if (condition['code'].startsWith('custom_')) {
      const customFieldCode = condition['code'].split('_')[1];
      const customField = payload['customField'];
      if (Array.isArray(customField)) {
        const foundField = customField.find(
          (item) => item.code === customFieldCode
        );
        return foundField ? foundField.value : null;
      }
    } else {
      return payload[condition['code']];
    }
  }
  static showApprovalStatusCol() {
    let approvalConditions = Store.getState().automation.data;
    if (this.isEmpty(approvalConditions)) {
      return false;
    } else {
      return true;
    }
  }

  static getTenantComplianceSettings(data: any): any {
    if (!data) {
      return;
    }
    const taxSystem = data.country;
    let state = {
      complianceSetting: true,
      peppolOptIn: true
    };
    let addressPresent = false;
    if (
      data.billingAddresses &&
      Array.isArray(data.billingAddresses) &&
      data.shippingAddresses &&
      Array.isArray(data.shippingAddresses)
    ) {
      addressPresent =
        Utility.isEmpty(data.billingAddresses[0]) &&
        Utility.isEmpty(data.shippingAddresses[0]);
    }
    switch (taxSystem) {
      case TAX_SYSTEM.US:
        if (!data.complianceEnabled) {
          state.complianceSetting = true;
        } else if (data.complianceEnabled && !addressPresent) {
          state.complianceSetting = false;
        } else {
          state.complianceSetting = true;
        }
        break;
      case TAX_SYSTEM.SG:
        state.peppolOptIn = data.peppolOptIn;
        break;
      case TAX_SYSTEM.INDIA_GST:
        if (!addressPresent) {
          state.complianceSetting = false;
        }
        break;
      case TAX_SYSTEM.CANADA:
      case TAX_SYSTEM.UK:
      case TAX_SYSTEM.NZ:
      case TAX_SYSTEM.BE:
      case TAX_SYSTEM.AUSTRALIA:
      case TAX_SYSTEM.DE:
        state.complianceSetting = true;
        break;
      default:
        state.complianceSetting = true;
        break;
    }
    return state;
  }

  static isComplianceEnabled() {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    let complainceEnabled = false;
    const tenantCountry =
      Store.getState().authInfo.currentTenantInfo.data.country;
    switch (tenantCountry) {
      case TAX_SYSTEM.INDIA_GST:
        if (tenantDetails.gstRegistered) {
          complainceEnabled = true;
        }
        break;
      default:
        if (tenantDetails.complianceEnabled) {
          complainceEnabled = true;
        }
        break;
    }

    return complainceEnabled;
  }

  /**
   * Debouncer function
   * @param func - function to be debounced
   * @param wait - time to wait
   */
  static debouncer<Params extends any[]>(
    func: (...args: Params) => any,
    wait: number
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout;
    return (...args: Params) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }

  /**
   * checks for null, undefined, empty string and false
   */
  static isNullish(value: any) {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      value === false
    ) {
      return true;
    }
    return false;
  }

  static isUndefined(value: any) {
    return typeof value === 'undefined';
  }

  static amountToWords(s: any, currency: any) {
    if (currency === CURRENCIES.IN) return toWords.convert(s);

    const th = ['', 'thousand', 'million', 'billion', 'trillion'];

    const dg = [
      'zero',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine'
    ];
    const tn = [
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen'
    ];
    const tw = [
      'twenty',
      'thirty',
      'forty',
      'fifty',
      'sixty',
      'seventy',
      'eighty',
      'ninety'
    ];
    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    let x = s.indexOf('.');
    if (x == -1) x = s.length;
    if (x > 15) return 'too big';
    let n = s.split('');
    let str = '';
    let sk = 0;
    let singular = false;
    for (let i = 0; i < x; i++) {
      if ((x - i) % 3 == 2) {
        if (n[i] == '1') {
          str += tn[Number(n[i + 1])] + ' ';
          i++;
          sk = 1;
        } else if (n[i] != 0) {
          str += tw[n[i] - 2] + ' ';
          sk = 1;
        }
      } else if (n[i] != 0) {
        str += dg[n[i]] + ' ';
        if ((x - i) % 3 == 0) str += 'hundred ';
        sk = 1;
      }
      if ((x - i) % 3 == 1) {
        if (sk) str += th[(x - i - 1) / 3] + ' ';
        sk = 0;
      }
    }
    if (x == 1 && n[0] == 1) singular = true;
    let higherCurrency = this.getHigherDenomination(currency, singular);
    str += higherCurrency;
    let isDecimal = false;
    singular = false;
    str = str.trim();
    if (x != s.length) {
      isDecimal = true;
      let y = s.length;
      str += ' and ';
      for (let i = x + 1; i < y; i++) {
        if ((y - i) % 3 == 2) {
          if (n[i] == '1') {
            str += tn[Number(n[i + 1])] + ' ';
            i++;
            sk = 1;
          } else if (n[i] != 0) {
            str += tw[n[i] - 2] + ' ';
            sk = 1;
          }
        } else if (n[i] != 0) {
          str += dg[n[i]] + ' ';
          if ((y - i) % 3 == 0) str += 'hundred ';
          sk = 1;
        }
        if ((y - i) % 3 == 1) {
          if (sk) str += th[(y - i - 1) / 3] + ' ';
          sk = 0;
        }
      }
      if (n[x + 1] == 0 && n[x + 2] == 1 && y - x - 1 == 2) singular = true;

      let decimalCurrency = this.getLowerDenomination(currency, singular);
      str += decimalCurrency;
      str += ' only';
    }
    if (!isDecimal) {
      str = str.concat(' only');
    }
    str = str.substring(0, str.length);
    return str;
  }

  static getLowerDenomination(currency: any, singular: any) {
    const decimalCurrency: any = this.decimalCurrencies.find(
      (i) => i.currencyCode === currency
    );
    if (singular == true) return decimalCurrency.lowerCurrency;
    else return decimalCurrency.lowerCurrencyPlural;
  }

  static getHigherDenomination(currency: any, singular: any) {
    const higherCurrency: any = this.decimalCurrencies.find(
      (i) => i.currencyCode === currency
    );

    if (singular == true) return higherCurrency.currencyName;
    else return higherCurrency.currencyNamePlural;
  }

  static getSubtypeStructured = (accountList: any, mainId?: any) => {
    let list = accountList?.map((item1: any) => {
      return { ...item1, children: [] };
    });
    let map: any = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== mainId) {
        list[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  static getAccountsStructured = (accountList: any) => {
    if (Utility.isEmpty(accountList)) {
      return [];
    }
    let list = accountList?.map((item1: any) => {
      return { ...item1, children: [] };
    });
    let map: any = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent !== null) {
        list?.[map?.[node?.parent]]?.children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  static getAccountsData = (data: any) => {
    const accountsWithNoSubAccounts = data?.filter(
      (item: any) => !item.isSubAccount && item.status === 'ACTIVE'
    );
    const allSubAccounts = data?.filter(
      (item: any) => item.isSubAccount && item.status === 'ACTIVE'
    );

    const multiCoaList: any = [];

    accountsWithNoSubAccounts?.map((item1: any) => {
      multiCoaList.push(item1);
      allSubAccounts?.map((item2: any) => {
        if (item1.id === item2.parent) {
          multiCoaList.push(item2);
        }
      });
    });

    return multiCoaList || [];
  };

  static setupColumConfigs = (configs: any[]) => {
    configs.forEach((config) => {
      const columnsMetaData = config?.columnsMetaData;
      const tableId = config._id;
      switch (config.name) {
        case REMOTE_CONFIG_TABLES.CONTACT:
          Store.dispatch(
            addContactColumnConfig({ tableId, config: columnsMetaData })
          );
          break;

        case REMOTE_CONFIG_TABLES.INVOICE:
          Store.dispatch(
            addInvoiceColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.SALES_ORDER:
          Store.dispatch(
            addSalesOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.PRODUCT:
          Store.dispatch(
            addProductColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BILL:
          Store.dispatch(
            addBillColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_ORDER:
          Store.dispatch(
            addOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.QUOTATION:
          Store.dispatch(
            addQuoteColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.DRAFTS:
          Store.dispatch(addDraftColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.DEPOSIT:
          Store.dispatch(
            addDepositColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.COA:
          Store.dispatch(
            addCoaColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.PRICE_LIST:
          Store.dispatch(addPriceColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.EXPENSE:
          Store.dispatch(
            addExpenseColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.JOURNAL:
          Store.dispatch(
            addJournalColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.FINANCIAL_YEAR_CLOSING:
          Store.dispatch(
            addFinancialYearClosingColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.CREDIT_NOTES:
          Store.dispatch(
            addCreditNoteColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.DEBIT_NOTES:
          Store.dispatch(
            addDebitNoteColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.ACCOUNTS:
          Store.dispatch(
            addAccountsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.INVENTORY:
          Store.dispatch(
            addInventoryColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.WAREHOUSE:
          Store.dispatch(
            addWarehouseColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.OB_INVOICES:
          Store.dispatch(
            addOBInvoiceColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BANKS:
          Store.dispatch(
            addBanksColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BANK_RULES:
          Store.dispatch(
            addBankRulesColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.PAYMENT_TERMS:
          Store.dispatch(
            addPaymentTermsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.AUDIT_LOG:
          Store.dispatch(addAuditLogColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.CURRENCY:
          Store.dispatch(addCurrencyColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.TAX_TABLE:
          Store.dispatch(
            addTaxColumnConfig({ tableId, config: columnsMetaData })
          );
          break;

        case REMOTE_CONFIG_TABLES.UOM:
          Store.dispatch(
            addUomColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BANK_DETAILS_DK:
          Store.dispatch(
            addDeskeraTransactionsColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BANK_DETAILS_STATEMENT:
          Store.dispatch(
            addBankTransactionsColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.IMPORT_LOG:
          Store.dispatch(addImportLogColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.UOM_SCHEMA:
          Store.dispatch(
            addUomSchemaColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.AUTOMATION:
          Store.dispatch(
            addAutomationColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_TRANSFER:
          Store.dispatch(
            addStockTransferColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          Store.dispatch(
            addStockAdjustmentColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.WORK_ORDERS:
          Store.dispatch(
            addWorkOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.WORK_OUT:
          Store.dispatch(
            addWorkoutColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.OPERATIONS:
          Store.dispatch(
            addOperationsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.OPERATORS:
          Store.dispatch(
            addOperatorColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORTS_SALES_ORDER_DETAILS:
          Store.dispatch(
            addReportSODetailsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORT_SKU_ORDER_TRACKING:
          Store.dispatch(
            addMRPReportsSKUOrderTrackingDetailsColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORTS_MRP_MACHINE_MASTER_TOOLS:
          Store.dispatch(
            addMachineToolMasterColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.MRP_MACHINE_ASSET_MAINTAINCE_SCHEUDLE:
          Store.dispatch(
            addMachineSchedulesColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORT_GRN_REPORT:
          Store.dispatch(
            addGRNColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORT_FULFILLMENT_REPORT:
          Store.dispatch(
            addFulfillmentReportColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.REQUEST_FOR_QUOTATION:
          Store.dispatch(
            addRFQColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        default:
          break;
      }
    });
  };

  static updateLocationAndClassTitleForGrid = (tempColumnConfig: any[]) => {
    const tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
    const locationAndClassData = Store.getState().location.data.content;
    if (locationAndClassData && locationAndClassData.length) {
      const locationData = locationAndClassData?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.LOCATION
      );
      const classData = locationAndClassData?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
      );
      if (tenantInfo && locationData) {
        const locationColumnConfigIndex = tempColumnConfig.findIndex((config) =>
          config?.columnCode?.includes(locationData.id)
        );
        if (locationColumnConfigIndex !== -1) {
          tempColumnConfig[locationColumnConfigIndex] = {
            ...tempColumnConfig[locationColumnConfigIndex],
            name: tenantInfo?.additionalSettings?.LOCATION?.locationLabel
          };
        }
      }

      if (tenantInfo && classData) {
        const classColumnConfigIndex = tempColumnConfig.findIndex((config) =>
          config?.columnCode?.includes(classData.id)
        );
        if (classColumnConfigIndex !== -1) {
          tempColumnConfig[classColumnConfigIndex] = {
            ...tempColumnConfig[classColumnConfigIndex],
            name: CLASS_TITLE
          };
        }
      }
    }
    return tempColumnConfig;
  };

  // Convert user type custom field to select type to support filters
  static convertUserTypeColumnToSelectType(columnConfig: any[]) {
    const usersList: any[] =
      Store.getState().authInfo.usersListShortInfo?.data?.content;
    columnConfig = columnConfig.map((config: any) => {
      if (config.type === CUSTOM_FIELD_TYPE.USER.toLowerCase()) {
        config = {
          ...config,
          type: INPUT_TYPE.SELECT,
          options: usersList.length
            ? usersList.map((user: any) => {
                let name = user.firstName?.trim();
                if (user.lastName?.trim()) {
                  name += ` ${user.lastName?.trim()}`;
                }
                return {
                  id: user.iamUserId?.toString(),
                  name: name
                };
              })
            : []
        };
      }
      if (config.key === GRN.IS_QC_ENABLED) {
        config.name = GRN.TOLERANCE_QC_IS_NEEDED;
        config.width = 200;
      }
      return config;
    });

    return columnConfig;
  }

  static getUserOptions() {
    const usersList: any[] =
      Store.getState().authInfo.usersListShortInfo?.data?.content;

    const options: any[] = [];

    usersList.forEach((user: any) => {
      let name = user.firstName?.trim();
      if (user.lastName?.trim()) {
        name += ` ${user.lastName?.trim()}`;
      }

      options.push({
        id: user.id,
        name: name,
        color: 'data-grid-badge-color-6'
      });
    });

    return options;
  }

  static getUserIdMap() {
    const usersList: any[] =
      Store.getState().authInfo.usersListShortInfo?.data?.content;
    const options = new Map();

    usersList.forEach((user: any) => {
      options.set(user.iamUserId, user.id);
    });

    return options;
  }

  static encodeReportString(json: any) {
    return btoa(json);
  }

  static decodeReportString(base64String: any) {
    return JSON.parse(atob(base64String));
  }

  static getSharableURL(documentData: any) {
    let mainURL = String(window.location.href);

    let addressURL = mainURL;
    let indexOfQuestionMark = addressURL.indexOf('?');
    if (indexOfQuestionMark !== -1) {
      mainURL = mainURL.substring(0, indexOfQuestionMark);
    }

    let encodedString = Utility.encodeString(JSON.stringify(documentData));
    let sharableLink = mainURL + '?dt=' + encodedString;

    return sharableLink;
  }

  static getFormattedAddressObj = (
    address: BooksAddress
  ): BooksAddressFormatted => {
    const contactName =
      address && !Utility.isEmpty(address.contactName)
        ? address.contactName
        : '';
    const line1 =
      address && !Utility.isEmpty(address.address1) ? address.address1 : '';
    const line2 =
      (address && !Utility.isEmpty(address.address2)) ||
      (address && !address.address2)
        ? address.address2
        : '';
    const city = address && !Utility.isEmpty(address.city) ? address.city : '';
    const state =
      address && !Utility.isEmpty(address.state) ? address.state : '';
    const country =
      address && !Utility.isEmpty(address.country) ? address.country : '';
    const postalCode =
      address && !Utility.isEmpty(address.postalCode) ? address.postalCode : '';
    let cityAndState = '';
    let countryAndPostalCode = '';

    if (city !== '' && state !== '') {
      cityAndState = `${city}, ${state}`;
    }
    if (city !== '' && state === '') {
      cityAndState = `${city}`;
    }
    if (city === '' && state !== '') {
      cityAndState = `${state}`;
    }

    if (country !== '' && postalCode !== '') {
      countryAndPostalCode = `${country}, ${postalCode}`;
    }
    if (country !== '' && postalCode === '') {
      countryAndPostalCode = `${country}`;
    }
    if (country === '' && postalCode !== '') {
      countryAndPostalCode = `${postalCode}`;
    }

    return { contactName, line1, line2, cityAndState, countryAndPostalCode };
  };

  static getControlAccountKeys = (obj: any, onlyKeys?: boolean) => {
    if (onlyKeys) {
      return !Utility.isEmpty(obj) ? Object.keys(obj) : [];
    } else {
      return !Utility.isEmpty(obj) ? Object.values(obj) : [];
    }
  };
  static sanitizePhoneForWhatsapp(phone: any) {
    if (!Utility.isEmpty(phone)) {
      phone = phone.replaceAll(REGEX.WHATSAPP, '');
    }
    return phone;
  }
  static totalReturnedQty(returned: any, returnMode: any = RETURN_MODE.SALE) {
    let totalReturnedQty: any = [];
    if (Array.isArray(returned) && returned.length > 0) {
      returned.forEach((item) => {
        let returned =
          returnMode == RETURN_MODE.SALE
            ? item.salesReturnItems
            : item.purchaseReturnItems;
        returned.map((returnedItem: any) => {
          if (!totalReturnedQty[returnedItem.productCode]) {
            totalReturnedQty[returnedItem.productCode] =
              returnedItem.quantityToReturn;
          } else {
            totalReturnedQty[returnedItem.productCode] +=
              returnedItem.quantityToReturn;
          }
        });
      });
    }
    return totalReturnedQty;
  }
  static totalFulfilledQty(fulfilled: any, returnMode: any = RETURN_MODE.SALE) {
    let totalFulfillQty: any = [];
    if (fulfilled && Array.isArray(fulfilled) && fulfilled?.length > 0) {
      fulfilled.forEach((item) => {
        let fulfilledData =
          returnMode == RETURN_MODE.SALE
            ? item.fulfillmentItems
            : item.purchaseReturnItems;
        if (returnMode == RETURN_MODE.SALE) {
          fulfilledData.map((ele: any) => {
            if (!totalFulfillQty[ele.productCode]) {
              totalFulfillQty[ele.productCode] = ele.fulfilledQuantity;
            } else {
              totalFulfillQty[ele.productCode] += ele.fulfilledQuantity;
            }
          });
        } else {
          fulfilledData.map((ele: any) => {
            if (!totalFulfillQty[ele.productCode]) {
              totalFulfillQty[ele.productCode] = ele.quantityToReturn;
            } else {
              totalFulfillQty[ele.productCode] += ele.quantityToReturn;
            }
          });
        }
      });
    }
    return totalFulfillQty;
  }
  static hasQtyToReturn(
    returned: any,
    returnMode: any = RETURN_MODE.SALE,
    fulfilledData?: any
  ) {
    let items =
      returnMode == RETURN_MODE.SALE
        ? returned[returned.length - 1].salesReturnItems
        : returned[returned.length - 1].purchaseReturnItems;
    let totalReturnedQty = this.totalReturnedQty(returned, returnMode);
    let totalFulfillQty = this.totalFulfilledQty(fulfilledData, returnMode);
    let returnedItems = items.filter((item: any) => {
      const qty =
        returnMode == RETURN_MODE.SALE
          ? totalFulfillQty[item.productCode]
          : item.quantityReceived;
      return qty - totalReturnedQty[item.productCode] === 0;
    });
    if (items.length === returnedItems.length) {
      return false;
    }
    return true;
  }

  static canGenerateEWayBill() {
    if (
      Utility.isComplianceEnabled() &&
      !Store.getState().authInfo.appCustomization.data.hiddenApps?.includes(
        'E_WAY'
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  static checkActiveDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    dateLabel: any,
    docType?: any
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === docType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `${dateLabel} should not be less than back date : ${formatedDate}.`
          );
          return false;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        return true;
      } else {
        showAlert(
          'Invalid Date',
          `${dateLabel} should be in active date range - From Date : ${startDate} To Date : ${endDate}.`
        );
        return false;
      }
    } else {
      return true;
    }
  };

  static checkClosingDate = (
    date: Date,
    dateLabel: string,
    isOpening = false
  ) => {
    let closeDateFY: Date = Utility.getCloseDateFY(isOpening);
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    const tenantCloseDateFY = tenantDetails.fyClosingPeriodEndDate;
    if (tenantCloseDateFY && closeDateFY.getTime() > date.getTime()) {
      showAlert(
        'Invalid Date',
        `${dateLabel} should not before financial year close date`
      );
      return false;
    } else {
      return true;
    }
  };

  static setFilterDataInStore = (
    type: any,
    config: any,
    SearchTerm?: any,
    queries?: any,
    filterDates?: any
  ) => {
    Store.dispatch(
      updateFilter({
        config,
        SearchTerm,
        queries,
        type,
        filterDates
      })
    );
  };

  static getFullAddress = (address: any) => {
    let _address = '';

    if (address) {
      if (!Utility.isEmpty(address.address1)) {
        _address = address.address1;
      }

      if (!Utility.isEmpty(address.address2)) {
        _address = !Utility.isEmpty(_address)
          ? _address + ', ' + address.address2
          : ' ' + address.address2;
      }

      if (!Utility.isEmpty(address.city)) {
        _address = !Utility.isEmpty(_address)
          ? _address + ', ' + address.city
          : ' ' + address.city;
      }

      if (!Utility.isEmpty(address.state)) {
        _address = !Utility.isEmpty(_address)
          ? _address + ', ' + address.state
          : ' ' + address.state;
      }

      if (!Utility.isEmpty(address.country)) {
        _address = !Utility.isEmpty(_address)
          ? _address + ', ' + address.country
          : ' ' + address.country;
      }

      if (!Utility.isEmpty(address.postalCode)) {
        _address = !Utility.isEmpty(_address)
          ? _address + ' - ' + address.postalCode
          : ' ' + address.postalCode;
      }
    }
    return _address;
  };

  static checkFilterTypeDate = (filterData: any) => {
    let allFilterData = deepClone(filterData);
    allFilterData.forEach((filter: any) => {
      if (filter.type === 'Date') {
        filter.value = new Date(filter.value);
      }
    });
    return allFilterData;
  };

  static getCompanyName = () => {
    const name = Store.getState().authInfo.currentTenantInfo.data?.name;
    return name;
  };

  static getFormattedNumber = (number: any) => {
    const storeNumberFormat: any =
      Store.getState()?.authInfo?.currentTenantInfo.data?.numberFormat.toUpperCase();
    const format: any = NUMBER_FORMATS[storeNumberFormat];
    let numString = `${number}`;

    let num: any;
    switch (format) {
      case NUMBER_FORMATS.ES:
        num = numString?.replaceAll('.', '')?.replaceAll(',', '.');
        return num;

      case NUMBER_FORMATS.FR:
        //Intl.NumberFormat return space as `8239` char code in FR case, this check is specific for it
        numString = numString
          .split('')
          .map((c) => (c.charCodeAt(0) === 8239 ? ' ' : c))
          .join('');
        num = numString.replaceAll(',', '.').replaceAll(' ', '');
        return num;

      case NUMBER_FORMATS.IN:
        num = numString.replaceAll(',', '');
        num = parseFloat(num).toFixed(
          Store.getState()?.authInfo?.currentTenantInfo?.data?.decimalScale
            ? Store.getState()?.authInfo?.currentTenantInfo?.data.decimalScale
            : 2
        );
        return num;

      case NUMBER_FORMATS.US:
        num = numString.replaceAll(',', '');
        num = parseFloat(num).toFixed(
          Store.getState()?.authInfo?.currentTenantInfo?.data?.decimalScale
            ? Store.getState()?.authInfo?.currentTenantInfo?.data.decimalScale
            : 2
        );
        return num;

      default:
        return number;
    }
  };

  static getTenantDateFormat = () => {
    const dateFormat =
      Store.getState().authInfo.currentTenantInfo.data?.dateFormat ||
      BOOKS_DATE_FORMAT['DD-MM-YYYY'];
    return dateFormat;
  };

  static getUomQuantityDecimalPrecision(
    baseQuantity: number,
    documentUOMSchemaDefinition: any,
    precisionVal = CURRENCY_PRECISION
  ) {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundingOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor,
        precisionVal
      );
    }
    return baseQuantity;
  }

  static getUomQtyIntoBaseQtyDecimal(
    inputQuantity: number,
    documentUOMSchemaDefinition: any,
    precisionVal = CURRENCY_PRECISION
  ) {
    if (!isNaN(inputQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundingOff(
        (inputQuantity * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor,
        precisionVal
      );
    }
    return inputQuantity;
  }

  static isSalesDocument(doc: any) {
    return (
      doc.documentType === DOC_TYPE.INVOICE ||
      doc.documentType === DOC_TYPE.QUOTE ||
      doc.documentType === DOC_TYPE.SALES_ORDER
    );
  }

  static isAnyDocumentLinked = (doc: any) => {
    let isLinked = false;
    const docType = doc?.documentType;
    if (docType === DOC_TYPE.SALES_ORDER) {
      isLinked =
        Utility.isNotEmpty(doc?.linkedQuotationDocuments) &&
        doc?.linkedQuotationDocuments?.length > 0;
    } else if (docType === DOC_TYPE.INVOICE) {
      const hasQuotationDocLinked = doc?.linkedDocuments?.find(
        (linkedDoc: any) => linkedDoc.documentType === DOC_TYPE.QUOTE
      );
      isLinked =
        doc?.linkedDocuments?.length > 0 &&
        Utility.isNotEmpty(hasQuotationDocLinked);
    }
    return isLinked;
  };

  static cancelRequest = (controller: AbortController) => {
    controller !== null && controller && controller.abort();
  };

  static createAbortController = () => {
    return new AbortController();
  };

  static cancelRequestMessage = (response: any) => {
    return typeof response === 'string' && response === ERROR ? true : false;
  };
  static filterReturnRecords(data: any, documentType: any) {
    let filteredData: any = [];
    if (data) {
      filteredData = data.filter((ele: any) =>
        ele.linkedDocuments.find(
          (item: any) => item.documentType === documentType
        )
      );
    }
    return filteredData;
  }

  static calculateTime = (time: any) => {
    let seconds = time * 60;
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
    var hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
    var mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
    var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';

    return { dDisplay, hDisplay, mDisplay, sDisplay };
  };

  static convertTimeForGrid(time: any) {
    let { dDisplay, hDisplay, mDisplay, sDisplay } =
      Utility.calculateTime(time);
    let timeDuration = dDisplay + hDisplay + mDisplay + sDisplay;
    if (
      Utility.isEmpty(dDisplay) &&
      Utility.isEmpty(hDisplay) &&
      Utility.isEmpty(mDisplay) &&
      Utility.isEmpty(sDisplay)
    ) {
      timeDuration = `0 second`;
    }
    return timeDuration;
  }

  static checkTimeType(time: any) {
    let { dDisplay, hDisplay, mDisplay, sDisplay } =
      Utility.calculateTime(time);
    let timeType = TIME_TYPES[1];
    if (!Utility.isEmpty(dDisplay)) {
      timeType = TIME_TYPES[3];
    } else if (!Utility.isEmpty(hDisplay)) {
      timeType = TIME_TYPES[2];
    } else if (!Utility.isEmpty(mDisplay)) {
      timeType = TIME_TYPES[1];
    } else if (!Utility.isEmpty(sDisplay)) {
      timeType = TIME_TYPES[0];
    }
    return timeType;
  }

  //Mobile app specific action handling
  static postMobileAppActions(action: any) {
    // @ts-ignore
    if (isTabletView() && action === MOBILE_APP_ACTIONS.HOME) {
      window.open(process.env.REACT_APP_ERP_URL, '_self');
    } else {
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(action);
    }
  }

  static getCommonOperators = (condition: string) => {
    switch (condition) {
      case 'eq':
        return '=';
      case 'c':
        return '~';
      case 'sw':
        return '=';
      case 'ew':
        return '=';
      case 'lt':
        return '<';
      case 'gt':
        return '>';
      default:
        return '=';
    }
  };

  static randstr(prefix: any) {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  }

  static amountFormatter = (amount: number, currency: any) =>
    `${Utility.getCurrencySymbolFromCode(currency)} ${
      amount < 0 ? '(' : ''
    }${NumberFormatService.getNumber(Math.abs(amount))}${
      amount < 0 ? ')' : ''
    }`;

  static amountFormatterWithCode = (
    amount: number,
    currencyCode: keyof typeof CURRENCY,
    decimalScale = DECIMAL_SCALE.TWO
  ) =>
    `${CURRENCY[currencyCode] ?? ''} ${
      amount < 0 ? '(' : ''
    }${Utility.roundingOff(amount, decimalScale)}${amount < 0 ? ')' : ''}`;

  static calculateMinutesInHours = (time: number) => time / 60;
  static calculateTimeInMinutes = (time: number, type: any) => {
    switch (type) {
      case TIME_TYPES_CONSTANT.SECONDS:
        return time / 60;
      case TIME_TYPES_CONSTANT.MINUTES:
        return time / 1;
      case TIME_TYPES_CONSTANT.HOURS:
        return time * 60;
      case TIME_TYPES_CONSTANT.DAYS:
        return time * 1440;
    }
  };

  static convertMinutesInFormat = (
    minutes: number,
    type: any,
    decimalRoundOff: boolean = false
  ) => {
    let result = 0;
    switch (type) {
      case TIME_TYPES_CONSTANT.SECONDS:
        result = minutes * 60;
        break;
      case TIME_TYPES_CONSTANT.MINUTES:
        result = minutes;
        break;
      case TIME_TYPES_CONSTANT.HOURS:
        result = minutes / 60;
        break;
      case TIME_TYPES_CONSTANT.DAYS:
        result = minutes / 1440;
        break;
      default:
        result = 0;
    }
    return decimalRoundOff
      ? result
      : Utility.roundOffToTenantDecimalScale(result);
  };

  static getDetailTimeConvertion = (
    operationTime: any,
    operationTimeType: any
  ) => {
    return (!Utility.isEmpty(operationTime) || !isNaN(operationTime)) &&
      Number(operationTime) !== 0
      ? Utility.convertTimeForGrid(
          Utility.calculateTimeInMinutes(
            operationTime,
            operationTimeType?.value || TIME_TYPES[1].value
          )
        )
      : `0 ${operationTimeType?.value || TIME_TYPES[1].value}`;
  };

  static isMRP = (): boolean => {
    return (
      JSON.parse(process.env.REACT_APP_ENABLE_MRP || 'false') &&
      GranularPermissionsHelper.hasMrpModulePermission()
    );
  };

  static isMRPWithURLCheck = (): boolean => {
    const isMRP = window.location.href?.includes(
      process.env.REACT_APP_URL_MRP + ''
    );
    return (
      isMRP &&
      JSON.parse(process.env.REACT_APP_ENABLE_MRP || 'false') &&
      GranularPermissionsHelper.hasMrpModulePermission()
    );
  };

  static isWarehouseTaggingEnabled = (): boolean => {
    const wareHouseTaggingEnabled =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ENABLE_WO_WAREHOUSE_TAGGING;
    return Utility.isMRPWithURLCheck() && wareHouseTaggingEnabled !== undefined
      ? wareHouseTaggingEnabled
      : false;
  };
  static isRRBTaggingEnabled = (): boolean => {
    const rrbTaggingEnabled =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ENABLE_WO_WAREHOUSE_BIN_TAGGING;
    return Utility.isMRPWithURLCheck() && rrbTaggingEnabled !== undefined
      ? rrbTaggingEnabled
      : false;
  };
  static isBinAllocationMandatory = (): boolean => {
    const isBinAllocationMandatory =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ENABLE_COMPONENT_AVAILABILITY_IN_TAGGED_BIN;
    return (
      Utility.isMRPWithURLCheck() &&
      Utility.isRRBTaggingEnabled() &&
      isBinAllocationMandatory
    );
  };

  static isComponentDetailsForFGOnInvoiceSOQuote = (
    returnName: boolean = false
  ): boolean => {
    const isComponentDetailsForFGOnDocuments =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.COMPONENT_DETAILS_FOR_FG?.componentDetailsEnable;
    if (returnName) {
      return Store.getState().authInfo?.currentTenantInfo?.data
        ?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
        ?.componentDetailsGroupName;
    }

    return Utility.isMRPWithURLCheck() && isComponentDetailsForFGOnDocuments;
  };

  static isOperationDetailsForFGOnInvoiceSOQuote = (
    returnName: boolean = false
  ): boolean => {
    const isOperationDetailsForFGOnDocuments =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.COMPONENT_DETAILS_FOR_FG?.operationDetailsEnable;

    if (returnName) {
      return Store.getState().authInfo?.currentTenantInfo?.data
        ?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
        ?.operationDetailsGroupName;
    }

    return Utility.isMRPWithURLCheck() && isOperationDetailsForFGOnDocuments;
  };

  static isAdditionalChargesDetailsForFGOnInvoiceSOQuote = (
    returnName: boolean = false
  ): boolean => {
    const isAddChargesDetailsForFGOnDocuments =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.COMPONENT_DETAILS_FOR_FG?.additionalCostDetailsEnable;

    if (returnName) {
      return Store.getState().authInfo?.currentTenantInfo?.data
        ?.additionalSettings?.COMPONENT_DETAILS_FOR_FG?.additionalCostGroupName;
    }

    return Utility.isMRPWithURLCheck() && isAddChargesDetailsForFGOnDocuments;
  };

  static isProductGroupingEnabled = (): boolean => {
    const productGroupingEnabled =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.PRODUCT_GROUP_ENABLED;
    return productGroupingEnabled;
  };

  static isBlockedWOEnabled = (): boolean => {
    const blockedWOEnabled =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.RESERVE_WO_CHILD_QUANTITY;
    return Utility.isMRPWithURLCheck() && blockedWOEnabled;
  };

  static isBankFileGenerationEnabled = (): boolean => {
    let bankFileGenerationEnabled = false;
    if (
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.BANK_FILE_GENERATION
    ) {
      bankFileGenerationEnabled =
        Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
          ?.BANK_FILE_GENERATION.enabled;
    }

    return bankFileGenerationEnabled;
  };

  static addPreferredVendorEnabled = (): boolean => {
    return Store.getState().authInfo?.currentTenantInfo?.data
      ?.additionalSettings?.ENABLE_PREFERRED_VENDOR_DETAILS_PRODUCT;
  };

  static getFormattedDate(myDate: any) {
    let abbrMonths = MONTHS;
    return (
      myDate.getDate() +
      ' ' +
      abbrMonths[myDate.getMonth()] +
      ' ' +
      myDate.getFullYear()
    );
  }

  static getFullName = (user: any) =>
    !this.isEmptyObject(user) ? `${user?.firstName} ${user?.lastName}` : 'NA';

  static isEmptyObject = (objectToCheck: any) => {
    if (
      objectToCheck === null ||
      objectToCheck === undefined ||
      objectToCheck.length === 0
    )
      return true;
    if (Array.isArray(objectToCheck)) return !objectToCheck.length;
    if (typeof objectToCheck === 'string') return !objectToCheck.trim().length;
    return Object.keys(objectToCheck).length === 0;
  };
  static isNotEmpty = (objectToCheck: any) =>
    !this.isEmptyObject(objectToCheck);

  static isNotEmptyDocumentUOMSchemaDefinition = (objectToCheck: any) => {
    if (!this.isEmptyObject(objectToCheck)) {
      if (
        objectToCheck?.hasOwnProperty('sinkConversionFactor') &&
        objectToCheck.hasOwnProperty('sourceConversionFactor')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  static isValidNumAndNonZero = (number: number | string) => {
    let value: number;
    if (typeof number === 'string') {
      value = parseFloat(number);
    } else {
      value = number;
    }

    if (
      value !== undefined &&
      value !== null &&
      value !== 0 &&
      typeof value === 'number'
    ) {
      return true;
    } else {
      return false;
    }
  };
  static getCurrentYearTaxes(taxes: any[]) {
    const todaysDate = new Date();
    let filteredTaxes: any[] = [];
    taxes.forEach((tax: any) => {
      if (
        typeof tax.effectiveEndDate !== 'undefined' &&
        tax.effectiveEndDate !== null
      ) {
        if (
          todaysDate >
            DateFormatService.getDateFromStr(
              tax.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          todaysDate <
            DateFormatService.getDateFromStr(
              tax.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          filteredTaxes.push(tax);
        }
      } else {
        if (
          todaysDate >
          DateFormatService.getDateFromStr(
            tax.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          filteredTaxes.push(tax);
        }
      }
    });
    return filteredTaxes;
  }

  static getFileIcon = (fileName: any) => {
    let fileType = fileName?.split('.');
    switch (fileType.pop()) {
      case 'txt':
        return DKIcons.doc_type.ic_txt;

      case 'pdf':
        return DKIcons.doc_type.ic_pdf;

      case 'jpg':
      case 'jpeg':
        return DKIcons.doc_type.ic_jpg;
      case 'png':
      case 'svg':
      case 'bmp':
        return DKIcons.doc_type.ic_png;

      case 'doc':
      case 'docx':
        return DKIcons.doc_type.ic_doc;

      case 'xlsx':
      case 'xls':
        return DKIcons.doc_type.ic_xls;
      case 'csv':
        return DKIcons.doc_type.ic_csv;

      case 'pptx':
      case 'ppt':
        return DKIcons.doc_type.ic_ppt;
      default:
        return DKIcons.ic_attachment;
    }
  };

  static getFormattedTime(date: Date) {
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let x = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let time = hours + ':' + minutes + ' ' + x;
    return time;
  }

  static getUOMForStockUOMId(stockUom: any) {
    let uomList = Store.getState().commonData.data.uoms;
    let stockUomObj = uomList.find((item: any) => item.id === stockUom);
    return stockUomObj;
  }

  static checkQCEnabledForModule = (moduleName: string) => {
    let moduleStatus: boolean = false;
    let additionalSettings =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings;
    if (!Utility.isEmpty(additionalSettings?.QC)) {
      let allQCModules = additionalSettings?.QC;
      let moduleIndex = allQCModules.findIndex(
        (qc: any) => qc.module === moduleName
      );
      if (moduleIndex > -1) {
        if (allQCModules?.[moduleIndex]?.enable) {
          moduleStatus = true;
        }
      }
    }

    return moduleStatus;
  };

  static isProtoDashboardAvailable() {
    const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
    const hostname = window.location?.host;
    if (
      tenantDetails &&
      (!hostname?.toLowerCase()?.includes('deskera.com') ||
        (hostname?.toLowerCase()?.includes('deskera.com') &&
          TENANT_IDS_FOR_PRONTO_DASHBOARD.includes(tenantDetails.id)))
    ) {
      return true;
    } else {
      return false;
    }
  }

  static isDeskeraEmail() {
    const userEmail = Utility.getLoginUserInfo()?.email;
    return (
      Utility.isNotEmpty(userEmail) &&
      (userEmail.includes('@deskera.com') || userEmail.includes('@deskera.org'))
    );
  }

  static fetchIRASRedirectURL() {
    let redirectURL = process.env.REACT_APP_IRAS_REDIRECT_URL;
    if (!this.isEmpty(redirectURL)) {
      return redirectURL;
    }
    return '';
  }

  static generateUniqueNumber() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  static getLoginUserInfo() {
    let userInfo: any = {};
    if (!Utility.isEmpty(Store.getState().authInfo.userInfo.data)) {
      userInfo = Store.getState().authInfo.userInfo.data[0];
    }
    return userInfo;
  }

  static getUniqueWHCodes = (arr: any[]) => {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < arr.length; i++) {
      if (!unique[arr[i].warehouseCode]) {
        distinct.push(arr[i].warehouseCode);
        unique[arr[i].warehouseCode] = 1;
      }
    }
    return distinct;
  };

  static getExistingWHInventoryData = (row: any, whData: any) => {
    const existingWarehouseInventoryData = whData;
    return existingWarehouseInventoryData?.find(
      (existingData: any) =>
        existingData.warehouseCode == row.warehouseCode &&
        existingData.rowCode == row.rowCode &&
        existingData.rackCode == row.rackCode &&
        existingData.binCode == row.binCode
    );
  };

  static masterAutoAllocateNoneTrackedWithoutRRB(
    commonWarehouseByProduct: any,
    selectedProduct: any,
    unmodifiedWarehouseInventoryData: any
  ) {
    const whDataConstant = !Utility.isEmpty(unmodifiedWarehouseInventoryData)
      ? deepClone(unmodifiedWarehouseInventoryData)
      : [];
    const existingWarehouseInventoryData =
      unmodifiedWarehouseInventoryData ?? [];
    let existingWHCodes = Utility.getUniqueWHCodes(
      existingWarehouseInventoryData
    );
    let copyWarehouseData: any[] = [];
    [...existingWHCodes].forEach((whCode: string) => {
      const whByProduct = [...commonWarehouseByProduct].find(
        (wh: any) => wh.code === whCode
      );
      if (!Utility.isEmpty(whByProduct)) {
        copyWarehouseData.push(whByProduct);
      }
    });
    const remainingWH = [...commonWarehouseByProduct].filter(
      (wh: any) => !existingWHCodes.includes(wh.code)
    );
    copyWarehouseData = [...copyWarehouseData, ...remainingWH];
    const existingTotalAllocatedQty = existingWarehouseInventoryData.reduce(
      (prev: number, next: any) => prev + next.quantity,
      0
    );

    let result: any[] = [];
    let totalAllocatedQuantity = 0;
    const productCode = selectedProduct?.productCode ?? '';
    const requiredQuantity = selectedProduct?.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          selectedProduct?.pendingQuantity,
          selectedProduct.documentUOMSchemaDefinition
        )
      : selectedProduct?.pendingQuantity;
    let localRequiredQuantity = requiredQuantity - existingTotalAllocatedQty;

    copyWarehouseData?.every((warehouse: any) => {
      let availableQuantity: any =
        warehouse?.productAvailableQuantity?.[productCode] -
        warehouse?.productReservedQuantity?.[productCode];
      availableQuantity =
        Utility.getAvailableQtyConsideringDocReservedQtyForNoneTracked(
          warehouse,
          productCode,
          selectedProduct
        );
      availableQuantity = availableQuantity
        ? Utility.getUomQuantity(
            availableQuantity,
            selectedProduct?.documentUOMSchemaDefinition
          )
        : 0;
      let resultItem = {
        warehouseCode: warehouse.code,
        warehouseName: warehouse.name,
        availableQty: availableQuantity,
        quantity: 0,
        invalidFields: []
      };

      const existingLineInventoryData = whDataConstant?.find((whItem: any) => {
        return whItem?.warehouseCode == resultItem.warehouseCode;
      });

      if (!Utility.isEmpty(existingLineInventoryData)) {
        resultItem = { ...existingLineInventoryData };
        totalAllocatedQuantity += resultItem.quantity;
      }

      resultItem.invalidFields = [];

      if (totalAllocatedQuantity < requiredQuantity) {
        let differenceOfRequired = requiredQuantity - totalAllocatedQuantity;
        if (differenceOfRequired > 0) {
          let availableQuantityInWarehouse =
            warehouse?.productAvailableQuantity?.[productCode] -
              warehouse?.productReservedQuantity?.[productCode] ?? 0;
          availableQuantityInWarehouse =
            Utility.getAvailableQtyConsideringDocReservedQtyForNoneTracked(
              warehouse,
              productCode,
              selectedProduct
            );
          if (selectedProduct?.documentUOMSchemaDefinition) {
            availableQuantityInWarehouse = Utility.getUomQuantity(
              availableQuantityInWarehouse ?? 0,
              selectedProduct?.documentUOMSchemaDefinition
            );
          }

          if (availableQuantityInWarehouse > 0) {
            if (differenceOfRequired > availableQuantityInWarehouse) {
              if (Utility.isEmpty(existingLineInventoryData)) {
                resultItem.quantity = availableQuantityInWarehouse;
                totalAllocatedQuantity =
                  totalAllocatedQuantity + availableQuantityInWarehouse;
              } else {
                let qtyToAllocate =
                  availableQuantityInWarehouse > localRequiredQuantity
                    ? localRequiredQuantity
                    : availableQuantityInWarehouse;
                localRequiredQuantity -= qtyToAllocate;
                resultItem.quantity = qtyToAllocate + resultItem.quantity;
                totalAllocatedQuantity += qtyToAllocate;
              }
              result.push(resultItem);
            } else {
              if (Utility.isEmpty(existingLineInventoryData)) {
                resultItem.quantity = differenceOfRequired;
                totalAllocatedQuantity =
                  totalAllocatedQuantity + differenceOfRequired;
              } else {
                let qtyToAllocate =
                  differenceOfRequired > localRequiredQuantity
                    ? localRequiredQuantity
                    : differenceOfRequired;
                localRequiredQuantity -= qtyToAllocate;
                resultItem.quantity = qtyToAllocate + resultItem.quantity;
                totalAllocatedQuantity += qtyToAllocate;
              }
              result.push(resultItem);
            }
          } else {
            if (!Utility.isEmpty(existingLineInventoryData)) {
              result.push(resultItem);
            }
          }
          return true;
        }
      } else {
        if (!Utility.isEmpty(existingLineInventoryData)) {
          result.push(resultItem);
        }
        return false;
      }
    });
    return result;
  }

  static getAvailableQtyConsideringDocReservedQtyForNoneTracked = (
    wareHouseDefaultData: any,
    productCode: any,
    selectedProduct: any
  ) => {
    let availableProductQty =
      wareHouseDefaultData?.productAvailableQuantity?.[productCode] ?? 0;
    if (Utility.isEmpty(selectedProduct?.reservedQuantitiesData)) {
      availableProductQty =
        availableProductQty -
        (wareHouseDefaultData?.productReservedQuantity?.[productCode] ?? 0);
    } else {
      const prodReservedQtyInWH =
        wareHouseDefaultData?.productReservedQuantity?.[productCode] ?? 0;
      let prodReservedQtyInDoc = 0;

      if (!Utility.isEmpty(selectedProduct?.reservedQuantitiesData)) {
        const prodReservedQtyInfoInDoc =
          selectedProduct?.reservedQuantitiesData?.find(
            (resData: any) =>
              wareHouseDefaultData?.code === resData?.warehouseCode &&
              productCode === resData?.productCode
          );

        if (!Utility.isEmpty(prodReservedQtyInfoInDoc)) {
          prodReservedQtyInDoc = prodReservedQtyInfoInDoc?.reservedQuantity;
        }
      }

      availableProductQty =
        availableProductQty - prodReservedQtyInWH + prodReservedQtyInDoc;
    }
    return availableProductQty;
  };

  static masterAutoAllocateNoneTrackedWithRRB = (
    commonWarehouseByProduct: any,
    selectedProduct: any,
    unmodifiedWarehouseInventoryData: any
  ) => {
    const whDataConstant = !Utility.isEmpty(unmodifiedWarehouseInventoryData)
      ? deepClone(unmodifiedWarehouseInventoryData)
      : [];
    const existingWarehouseInventoryData =
      unmodifiedWarehouseInventoryData ?? [];
    let existingWHCodes = Utility.getUniqueWHCodes(
      existingWarehouseInventoryData
    );
    let copyWarehouseData: any[] = [];
    [...existingWHCodes].forEach((whCode: string) => {
      const whByProduct = [...commonWarehouseByProduct].find(
        (wh: any) => wh.code === whCode
      );
      if (!Utility.isEmpty(whByProduct)) {
        copyWarehouseData.push(whByProduct);
      }
    });
    const remainingWH = [...commonWarehouseByProduct].filter(
      (wh: any) => !existingWHCodes.includes(wh.code)
    );
    copyWarehouseData = [...copyWarehouseData, ...remainingWH];
    const existingTotalAllocatedQty = existingWarehouseInventoryData.reduce(
      (prev: number, next: any) => prev + next.quantity,
      0
    );
    let result: any[] = [];
    let totalAllocatedQuantity = 0;
    const productCode = selectedProduct?.productCode ?? '';
    const requiredQuantity = selectedProduct?.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          selectedProduct?.pendingQuantity,
          selectedProduct.documentUOMSchemaDefinition
        )
      : selectedProduct?.pendingQuantity;
    let localRequiredQuantity = requiredQuantity - existingTotalAllocatedQty;

    const productAvailableQuantityInfo = copyWarehouseData.reduce(
      (prev: any[], current: any) => {
        let rrbDtoForProduct = current?.rowRackBinProductAvailableQuantityDtos;
        if (rrbDtoForProduct?.length) {
          rrbDtoForProduct = rrbDtoForProduct
            ?.filter(
              (item: any) =>
                item.productCode === productCode && item.availableQuantity > 0
            )
            .map((item: any) => ({
              ...item,
              warehouseCode: current.code,
              warehouseName: current.name
            }));
        } else {
          let qtyAvailability =
            Utility.isBinAllocationMandatory() &&
            WarehouseManagementHelper.isRRBEnabledForWarehouse(
              selectedProduct.targetWarehouse
            )
              ? Utility.isNotEmpty(rrbDtoForProduct)
              : true;
          if (qtyAvailability) {
            rrbDtoForProduct = [
              {
                availableQuantity:
                  current?.productAvailableQuantity?.[productCode],
                binCode: null,
                binName: null,
                productCode: productCode,
                rackCode: null,
                rackName: null,
                reservedQuantity:
                  current?.productReservedQuantity?.[productCode],
                rowCode: null,
                rowName: null,
                warehouseCode: current.code,
                warehouseName: current.name
              }
            ];
          }
        }
        return [...prev, ...rrbDtoForProduct];
      },
      []
    );

    if (productAvailableQuantityInfo.length) {
      productAvailableQuantityInfo?.every((rrbDtoForProduct: any) => {
        const warehouse = copyWarehouseData?.find(
          (x: any) => x.code === rrbDtoForProduct.warehouseCode
        );

        const availableQtyConsideringUOM =
          selectedProduct.documentUOMSchemaDefinition
            ? Utility.getUomQuantity(
                rrbDtoForProduct.availableQuantity,
                selectedProduct.documentUOMSchemaDefinition
              )
            : rrbDtoForProduct.availableQuantity;

        const row = rrbDtoForProduct;
        const rack = rrbDtoForProduct;
        const bin = rrbDtoForProduct;

        const availableQuantity =
          Utility.getAvailableQuantityRRBNoneTrackedProduct(
            [rrbDtoForProduct],
            row?.rowCode,
            rack?.rackCode,
            bin?.binCode,
            warehouse,
            selectedProduct
          );

        let resultItem = {
          warehouse: warehouse,
          warehouseCode: warehouse.code,
          warehouseName: warehouse.name,
          availableQty: availableQuantity,
          quantity: 0,
          row: row,
          rowCode: row.rowCode,
          rowName: row.rowName,
          rack: rack,
          rackCode: rack.rackCode,
          rackName: rack.rackName,
          bin: bin,
          binCode: bin.binCode,
          binName: bin.binName,
          invalidFields: []
        };

        const existingLineInventoryData = whDataConstant?.find(
          (whItem: any) => {
            return (
              whItem?.warehouseCode == resultItem.warehouseCode &&
              whItem?.rowCode == resultItem.rowCode &&
              whItem?.rackCode == resultItem.rackCode &&
              whItem?.binCode == resultItem.binCode
            );
          }
        );

        if (!Utility.isEmpty(existingLineInventoryData)) {
          resultItem = {
            ...existingLineInventoryData
          };
          totalAllocatedQuantity += resultItem.quantity;
        }

        resultItem.invalidFields = [];

        if (totalAllocatedQuantity < requiredQuantity) {
          let differenceOfRequired = requiredQuantity - totalAllocatedQuantity;
          if (differenceOfRequired > 0) {
            let availableQuantityInWarehouse = availableQuantity ?? 0;
            if (availableQuantityInWarehouse > 0) {
              if (differenceOfRequired > availableQuantityInWarehouse) {
                if (Utility.isEmpty(existingLineInventoryData)) {
                  resultItem.quantity = availableQuantityInWarehouse;
                  totalAllocatedQuantity =
                    totalAllocatedQuantity + availableQuantityInWarehouse;
                } else {
                  let qtyToAllocate =
                    availableQuantityInWarehouse > localRequiredQuantity
                      ? localRequiredQuantity
                      : availableQuantityInWarehouse;
                  localRequiredQuantity -= qtyToAllocate;
                  resultItem.quantity = qtyToAllocate + resultItem.quantity;
                  totalAllocatedQuantity += qtyToAllocate;
                }
                result.push(resultItem);
              } else {
                if (Utility.isEmpty(existingLineInventoryData)) {
                  resultItem.quantity = differenceOfRequired;
                  totalAllocatedQuantity =
                    totalAllocatedQuantity + differenceOfRequired;
                } else {
                  let qtyToAllocate =
                    differenceOfRequired > localRequiredQuantity
                      ? localRequiredQuantity
                      : differenceOfRequired;
                  localRequiredQuantity -= qtyToAllocate;
                  resultItem.quantity = qtyToAllocate + resultItem.quantity;
                  totalAllocatedQuantity += qtyToAllocate;
                }
                result.push(resultItem);
              }
            } else {
              if (!Utility.isEmpty(existingLineInventoryData)) {
                result.push(resultItem);
              }
            }
            return true;
          }
        } else {
          if (!Utility.isEmpty(existingLineInventoryData)) {
            result.push(resultItem);
          }
          return false;
        }
      });
      return result;
    }
  };

  static getAvailableQuantityRRBNoneTrackedProduct = (
    dto: any = [],
    rowCode = null,
    rackCode = null,
    binCode = null,
    warehouse?: any,
    selectedProduct?: any
  ) => {
    const isMrpFlow = true;
    const {
      reservedQuantitiesData = [],
      productCode,
      documentUOMSchemaDefinition = {}
    } = selectedProduct;
    let availableQty = dto.reduce((accumulator: any, currentValue: any) => {
      if (
        (!rowCode || currentValue.rowCode === rowCode) &&
        (!rackCode || currentValue.rackCode === rackCode) &&
        (!binCode || currentValue.binCode === binCode)
      ) {
        if (isMrpFlow || Utility.isEmpty(reservedQuantitiesData)) {
          return (
            accumulator +
            (currentValue?.availableQuantity ?? 0) -
            (currentValue.reservedQuantity ?? 0)
          );
        } else {
          const currentProdQty = currentValue?.availableQuantity ?? 0;
          const prodReservedQtyInWH = currentValue?.reservedQuantity ?? 0;
          const prodReservedQtyInfoInDoc = reservedQuantitiesData?.find(
            (resData: any) =>
              resData.productCode === productCode &&
              resData?.warehouseCode == warehouse?.code &&
              resData.rowCode == rowCode &&
              resData.rackCode == rackCode &&
              resData.binCode == binCode
          );
          let prodReservedQtyInDoc = 0;
          if (!Utility.isEmpty(prodReservedQtyInfoInDoc)) {
            prodReservedQtyInDoc =
              prodReservedQtyInfoInDoc?.reservedQuantity ?? 0;
          }
          const productQuantity =
            currentProdQty - prodReservedQtyInWH + prodReservedQtyInDoc;
          return accumulator + productQuantity;
        }
      } else {
        return accumulator;
      }
    }, 0);
    availableQty = Utility.isNotEmpty(documentUOMSchemaDefinition)
      ? Utility.getUomQuantity(availableQty, documentUOMSchemaDefinition)
      : availableQty;
    return availableQty;
  };

  static getAvailableStockData = (
    advancedTrackingData: any[],
    selectedProduct: any
  ) => {
    const isMRP = true;

    const {
      reservedQuantitiesData = [],
      productCode,
      documentUOMSchemaDefinition = {}
    } = selectedProduct;

    if (isMRP || Utility.isEmpty(reservedQuantitiesData)) {
      return advancedTrackingData?.filter(
        (serial: any) =>
          serial.batchSizeFulfilled < serial.batchSize &&
          serial.reservedQuantity < serial.batchSize
      );
    } else {
      // Not MRP flow and doc has reserved quantities
      return advancedTrackingData?.filter((serial: any) => {
        const currentProdQty = serial?.batchSize ?? 0;
        const prodReservedQtyInWH = serial?.reservedQuantity ?? 0;

        const reservedQtyAdvTrackingData = reservedQuantitiesData
          ?.filter((data: any) => data.productCode === productCode)
          ?.flatMap((data: any) => data.advancedTrackingMetaDtos);

        const prodReservedQtyInfoInDoc = reservedQtyAdvTrackingData?.find(
          (resData: any) =>
            resData?.warehouseCode == serial?.warehouseCode &&
            resData?.serialBatchNumber == serial?.serialBatchNumber
        );

        let prodReservedQtyInDoc = 0;
        if (!Utility.isEmpty(prodReservedQtyInfoInDoc)) {
          prodReservedQtyInDoc =
            prodReservedQtyInfoInDoc?.reservedQuantity ?? 0;
        }

        const availableBatchSize =
          currentProdQty - prodReservedQtyInWH + prodReservedQtyInDoc;

        return (
          serial.batchSizeFulfilled < serial.batchSize &&
          availableBatchSize > 0 &&
          availableBatchSize <= serial.batchSize
        );
      });
    }
  };

  static masterAutoAllocateSerialTracked = (
    commonWarehouseByProduct: any,
    selectedProduct: any,
    unmodifiedWarehouseInventoryData: any,
    code: any
  ) => {
    let productAvailableQuantity = commonWarehouseByProduct?.reduce(
      (prev: any[], current: any) => {
        let advTracking: any[] = [];
        if (!Utility.isEmpty(current?.productAdvancedTrackingMeta?.[code])) {
          advTracking = current?.productAdvancedTrackingMeta?.[code]?.map(
            (item: any) => {
              return {
                ...item,
                warehouseName: current.name
              };
            }
          );
        }

        return [...prev, ...advTracking];
      },
      []
    );
    const existingWarehouseInventoryData =
      unmodifiedWarehouseInventoryData ?? [];
    let serialData = Utility.getAvailableStockData(
      productAvailableQuantity,
      selectedProduct
    );
    if (
      Utility.isBinAllocationMandatory() &&
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        selectedProduct.targetWarehouse
      )
    ) {
      serialData = serialData?.filter(
        (ele: any) =>
          ele.rowCode == selectedProduct.targetWarehouse?.rowCode &&
          ele.rackCode == selectedProduct.targetWarehouse?.rackCode &&
          ele.binCode == selectedProduct.targetWarehouse?.binCode
      );
    }

    //  productAvailableQuantity?.filter(
    //   (serial: any) =>
    //     serial.batchSizeFulfilled < serial.batchSize &&
    //     serial.reservedQuantity < serial.batchSize
    // );

    let serialTrackingData = serialData?.map((item: any, i: any) => {
      let object = {
        qtyToFulfil: 1,
        batchSize: item?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(item?.acquiredCost) / Number(item?.batchSize)
        ),
        serialBatchNumber: item.serialBatchNumber,
        warehouseCode: item.warehouseCode,
        warehouseName: item.warehouseName,
        destinationWarehouseCode: item?.destinationWarehouseCode,
        destinationWarehouseName: item?.destinationWarehouseName,
        rowCode: item?.rowCode ?? null,
        rowName: item?.rowName ?? null,
        rackCode: item?.rackCode ?? null,
        rackName: item?.rackName ?? null,
        binCode: item?.binCode ?? null,
        binName: item?.binName ?? null,
        customField: item?.customField
      };
      return {
        ...object,
        advancedTrackingData: [{ ...object }]
      };
    });
    let requiredQuantityValue =
      Utility.getRequireSerialQuantity(selectedProduct);
    serialTrackingData = serialTrackingData?.slice(
      0,
      requiredQuantityValue - existingWarehouseInventoryData?.length
    );
    const mappedSerialTrackedData = existingWarehouseInventoryData?.map(
      (item: any) => ({
        ...item,
        qtyToFulfil: item?.['advancedTrackingData']?.[0]?.qtyToFulfil,
        batchSize: item?.['advancedTrackingData']?.[0]?.batchSize,
        serialBatchNumber:
          item?.['advancedTrackingData']?.[0]?.serialBatchNumber
      })
    );
    serialTrackingData.push(...deepClone(mappedSerialTrackedData));
    if (selectedProduct.documentUOMSchemaDefinition) {
      const uomQty = Utility.getUomQuantityWithoutRoundOff(
        serialTrackingData.length,
        selectedProduct.documentUOMSchemaDefinition
      );
      if (!Number.isInteger(uomQty)) {
        return [];
      }
    }
    return serialTrackingData;
  };

  static masterAutoAllocateBatchTracked = (
    productWarehouse: any,
    selectedProduct: any,
    unmodifiedWarehouseInventoryData: any,
    code: any
  ) => {
    let requiredQuantity = selectedProduct?.requiredQuantity ?? 0;
    const whDataConstant = !Utility.isEmpty(unmodifiedWarehouseInventoryData)
      ? deepClone(unmodifiedWarehouseInventoryData)
      : [];
    const existingWarehouseInventoryData =
      unmodifiedWarehouseInventoryData ?? [];
    let existingWHCodes = Utility.getUniqueWHCodes(
      existingWarehouseInventoryData
    );
    let copyWarehouseData: any[] = [];
    [...existingWHCodes].forEach((whCode: string) => {
      let whByProduct = [...productWarehouse].find(
        (wh: any) => wh.code === whCode
      );

      whByProduct = {
        ...whByProduct,
        advancedTrackingMeta: whByProduct?.productAdvancedTrackingMeta?.[code]
      };

      if (!Utility.isEmpty(whByProduct)) {
        copyWarehouseData.push(whByProduct);
      }
    });
    const remainingWH = [...productWarehouse].filter(
      (wh: any) => !existingWHCodes.includes(wh.code)
    );
    copyWarehouseData = [...copyWarehouseData, ...remainingWH];

    const existingTotalAllocatedQty = existingWarehouseInventoryData.reduce(
      (prev: number, next: any) => prev + next.quantity,
      0
    );

    let localRequiredQuantity = requiredQuantity - existingTotalAllocatedQty;

    let productAvailableQuantity = copyWarehouseData.reduce(
      (prev: any[], current: any) => {
        let advTracking = [];
        if (!Utility.isEmpty(current?.productAdvancedTrackingMeta?.[code])) {
          advTracking = current?.productAdvancedTrackingMeta?.[code]?.map(
            (item: any, index: any) => {
              return {
                ...item,
                warehouseName: current.name,
                batchSize: selectedProduct?.documentUOMSchemaDefinition
                  ? Utility.getUomQuantity(
                      Utility.roundingOff(
                        Utility.getAvailableQtyConsideringDocReservedQty(
                          current,
                          item,
                          selectedProduct
                        ),
                        QTY_ROUNDOFF_PRECISION
                      ),
                      selectedProduct?.documentUOMSchemaDefinition
                    )
                  : Utility.roundingOff(
                      Utility.getAvailableQtyConsideringDocReservedQty(
                        current,
                        item,
                        selectedProduct
                      ),
                      QTY_ROUNDOFF_PRECISION
                    )
              };
            }
          );
        }

        return [...prev, ...advTracking];
      },
      []
    );

    if (!Utility.isEmpty(existingWarehouseInventoryData)) {
      let existingAllocatedWHBatches: any[] = [];
      existingWarehouseInventoryData?.forEach((existingInvetoryData: any) => {
        const existingIndex = productAvailableQuantity?.findIndex(
          (whData: any) =>
            existingInvetoryData.warehouseCode == whData.warehouseCode &&
            existingInvetoryData.advancedTrackingData?.[0]?.serialBatchNumber ==
              whData.serialBatchNumber &&
            existingInvetoryData?.rowCode == whData?.rowCode &&
            existingInvetoryData?.rackCode == whData?.rackCode &&
            existingInvetoryData?.binCode == whData?.binCode
        );
        if (existingIndex !== -1) {
          existingAllocatedWHBatches.push(
            productAvailableQuantity[existingIndex]
          );
          productAvailableQuantity.splice(existingIndex, 1);
        }
      });
      productAvailableQuantity = [
        ...existingAllocatedWHBatches,
        ...productAvailableQuantity
      ];
    }
    if (
      Utility.isBinAllocationMandatory() &&
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        selectedProduct.targetWarehouse
      )
    ) {
      productAvailableQuantity = productAvailableQuantity?.filter(
        (ele: any) =>
          ele.rowCode == selectedProduct.targetWarehouse?.rowCode &&
          ele.rackCode == selectedProduct.targetWarehouse?.rackCode &&
          ele.binCode == selectedProduct.targetWarehouse?.binCode
      );
    }

    let allocated = 0;
    let batchTrackingData: any[] = [];
    productAvailableQuantity?.every((element: any) => {
      let availableQtyToAssign = selectedProduct?.documentUOMSchemaDefinition
        ? Utility.getUomWarehouseQuantity(
            parseFloat(element.batchSize),
            selectedProduct?.documentUOMSchemaDefinition
          )
        : parseFloat(element.batchSize);
      // : parseFloat(element.batchSize) - parseFloat(element.reservedQuantity);

      //for RRB
      let warehouseData = copyWarehouseData;
      let inventoryWarehouse = warehouseData?.find(
        (warehouse: any) => warehouse.code === element?.warehouseCode
      );
      let uniqueRows: any[] = [];
      let uniqueRacks: any[] = [];
      let uniqueBins: any[] = [];
      if (inventoryWarehouse?.productAdvancedTrackingMeta?.[code]) {
        const rrbDtoForProduct = [
          ...inventoryWarehouse?.productAdvancedTrackingMeta?.[code]
        ];
        //rows
        uniqueRows = WarehouseManagementHelper.getUniqueRows(
          rrbDtoForProduct ?? [],
          selectedProduct?.rackCode,
          selectedProduct?.binCode
        );
        //racks
        uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
          rrbDtoForProduct ?? [],
          selectedProduct?.rowCode,
          selectedProduct?.binCode
        );
        //bins
        uniqueBins = WarehouseManagementHelper.getUniqueBins(
          rrbDtoForProduct ?? [],
          selectedProduct?.rowCode,
          selectedProduct?.rackCode
        );
      }
      const row = uniqueRows?.find((x: any) => x.rowCode === element?.rowCode);
      const rack = uniqueRacks?.find(
        (x: any) => x.rackCode === element?.rackCode
      );
      const bin = uniqueBins?.find((x: any) => x.binCode === element?.binCode);
      const warehouse = copyWarehouseData?.find(
        (wh: any) => wh.code === element?.warehouseCode
      );
      let lineItem = {
        acquiredCost: element?.acquiredCost || 0,
        batchSize: element?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(element?.acquiredCost) / Number(element?.batchSize)
        ),
        qtyToFulfil: 0,
        batchSizeFulfilled: 0,
        serialBatchNumber: element,
        manufacturingDate: DateFormatService.getDateFromStr(
          element.manufacturingDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        expiryDate: DateFormatService.getDateFromStr(
          element.expiryDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        row: row ?? null,
        rowName: row?.rowName ?? null,
        rowCode: element?.rowCode ?? null,
        rack: rack ?? null,
        rackName: rack?.rackName ?? null,
        rackCode: element?.rackCode ?? null,
        bin: bin ?? null,
        binName: bin?.binName ?? null,
        binCode: element?.binCode,
        warehouseCode: warehouse ?? null,
        warehouseName: warehouse?.name,
        invalidFields: [],
        customField: element?.customField
      };

      let existingLineInventoryData = null;

      for (let i = 0; i < whDataConstant.length; i++) {
        existingLineInventoryData = whDataConstant[
          i
        ]?.advancedTrackingData?.find((whItem: any) => {
          return (
            whItem?.serialBatchNumber ==
              lineItem.serialBatchNumber?.serialBatchNumber &&
            whDataConstant?.[i]?.warehouseCode ==
              lineItem?.warehouseCode?.code &&
            whDataConstant?.[i]?.rowCode == lineItem?.rowCode &&
            whDataConstant?.[i]?.rackCode == lineItem?.rackCode &&
            whDataConstant?.[i]?.binCode == lineItem?.binCode
          );
        });

        if (Utility.isNotEmpty(existingLineInventoryData)) {
          break;
        }
      }

      if (!Utility.isEmpty(existingLineInventoryData)) {
        lineItem.qtyToFulfil = existingLineInventoryData?.qtyToFulfil ?? 0;
        lineItem.batchSizeFulfilled =
          selectedProduct.documentUOMSchemaDefinition
            ? Utility.getUomQuantity(
                lineItem.qtyToFulfil,
                selectedProduct.documentUOMSchemaDefinition
              )
            : lineItem.qtyToFulfil;
        allocated += lineItem.qtyToFulfil;
      }

      lineItem.invalidFields = [];

      if (allocated < requiredQuantity) {
        if (requiredQuantity - allocated > 0) {
          if (availableQtyToAssign > 0) {
            if (availableQtyToAssign < requiredQuantity - allocated) {
              if (Utility.isEmpty(existingLineInventoryData)) {
                lineItem.qtyToFulfil = availableQtyToAssign;
                allocated = allocated + lineItem.qtyToFulfil;
              } else {
                let qtyToAllocate =
                  availableQtyToAssign > localRequiredQuantity
                    ? localRequiredQuantity
                    : availableQtyToAssign;
                localRequiredQuantity -= qtyToAllocate;
                lineItem.qtyToFulfil = qtyToAllocate + lineItem.qtyToFulfil;
                allocated += qtyToAllocate;
              }
              lineItem.batchSizeFulfilled = lineItem.qtyToFulfil;
              batchTrackingData.push(lineItem);
            } else {
              if (Utility.isEmpty(existingLineInventoryData)) {
                lineItem.qtyToFulfil =
                  requiredQuantity - allocated + lineItem.qtyToFulfil;
                allocated = allocated + lineItem.qtyToFulfil;
              } else {
                const differenceOfRequired = requiredQuantity - allocated;
                let qtyToAllocate =
                  differenceOfRequired > localRequiredQuantity
                    ? localRequiredQuantity
                    : differenceOfRequired;
                localRequiredQuantity -= qtyToAllocate;
                lineItem.qtyToFulfil = qtyToAllocate + lineItem.qtyToFulfil;
                allocated += qtyToAllocate;
              }
              lineItem.batchSizeFulfilled = lineItem.qtyToFulfil;
              batchTrackingData.push(lineItem);
            }
          } else {
            if (!Utility.isEmpty(existingLineInventoryData)) {
              batchTrackingData.push(lineItem);
            }
          }
          return true;
        }
      } else {
        if (!Utility.isEmpty(existingLineInventoryData)) {
          batchTrackingData.push(lineItem);
        }
        return false;
      }
    });

    return Utility.onBatchMasterAllocateSave(
      batchTrackingData,
      selectedProduct
    );
  };

  static getAvailableQtyConsideringDocReservedQty = (
    wareHouseDefaultData: any,
    item: any,
    selectedProduct: any
  ) => {
    const copyOfWareHouseDefaultData = { ...wareHouseDefaultData };
    const isMrpFlow = true;

    const { code } = copyOfWareHouseDefaultData;
    const advancedTrackingMeta =
      copyOfWareHouseDefaultData?.productAdvancedTrackingMeta?.[
        selectedProduct?.productCode
      ] || [];
    const {
      reservedQuantitiesData = [],
      productCode,
      documentUOMSchemaDefinition = {}
    } = selectedProduct;

    item = {
      ...item,
      rowCode: item?.['row']?.rowCode || item?.rowCode,
      binCode: item?.['bin']?.binCode || item?.binCode,
      rackCode: item?.['rack']?.rackCode || item?.rackCode
    };

    const { serialBatchNumber = null, rowCode, rackCode, binCode } = item;
    let advancedTrackingData: any = {};
    advancedTrackingData = advancedTrackingMeta?.find(
      (item: any) =>
        item['warehouseCode'] == code &&
        item['serialBatchNumber'] ==
          (serialBatchNumber?.serialBatchNumber || serialBatchNumber) &&
        item['rowCode'] == rowCode &&
        item['rackCode'] == rackCode &&
        item['binCode'] == binCode
    );
    let {
      batchSize: availableProductQty = 0,
      reservedQuantity: productReservedQuantity = 0,
      reservedQuantityFulfilled = 0,
      batchSizeFulfilled = 0
    } = advancedTrackingData || {};

    if (Utility.isNotEmpty(documentUOMSchemaDefinition)) {
      availableProductQty = Utility.getUomQuantity(
        availableProductQty,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      productReservedQuantity = Utility.getUomQuantity(
        productReservedQuantity,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      batchSizeFulfilled = Utility.getUomQuantity(
        batchSizeFulfilled,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      reservedQuantityFulfilled = Utility.getUomQuantity(
        reservedQuantityFulfilled,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    if (isMrpFlow || Utility.isEmpty(reservedQuantitiesData)) {
      return (
        availableProductQty -
        productReservedQuantity -
        batchSizeFulfilled +
        reservedQuantityFulfilled
      );
    }

    if (Utility.isNotEmpty(reservedQuantitiesData)) {
      const prodReservedQtyInWH = productReservedQuantity;
      const prodReservedQtyInfoInDoc = reservedQuantitiesData?.find(
        (resData: any) => {
          if (Utility.isNotEmpty(resData?.advancedTrackingMetaDtos?.[0])) {
            const {
              serialBatchNumber: innerSerialBatchNumber,
              rowCode: innerRowCode,
              rackCode: innerRackCode,
              binCode: innerBinCode
            } = resData?.advancedTrackingMetaDtos?.[0];
            return (
              code === resData?.warehouseCode &&
              productCode === resData.productCode &&
              innerSerialBatchNumber === serialBatchNumber?.serialBatchNumber &&
              innerRowCode == rowCode &&
              innerRackCode == rackCode &&
              innerBinCode == binCode
            );
          }
        }
      );

      let prodReservedQtyInDoc = 0;
      if (Utility.isNotEmpty(prodReservedQtyInfoInDoc)) {
        prodReservedQtyInDoc = prodReservedQtyInfoInDoc?.reservedQuantity;
      }
      if (Utility.isNotEmpty(documentUOMSchemaDefinition)) {
        prodReservedQtyInDoc = Utility.getUomQuantity(
          prodReservedQtyInDoc,
          documentUOMSchemaDefinition,
          QTY_ROUNDOFF_PRECISION_BACKEND
        );
      }

      return (
        availableProductQty -
        batchSizeFulfilled +
        reservedQuantityFulfilled -
        prodReservedQtyInWH +
        prodReservedQtyInDoc
      );
    }

    return availableProductQty;
  };

  static onBatchMasterAllocateSave = (
    updatedBatchTrackingData: any,
    selectedProduct: any
  ) => {
    const updatedGridData = updatedBatchTrackingData;
    const batchTrackingData = updatedGridData.map((item: any, i: any) => {
      let object = {
        acquiredCost: item?.serialBatchNumber?.acquiredCost || 0,
        qtyToFulfil: selectedProduct.documentUOMSchemaDefinition
          ? Utility.getUomWarehouseQuantityWithoutRoundOff(
              parseFloat(item.batchSizeFulfilled),
              selectedProduct.documentUOMSchemaDefinition
            )
          : parseFloat(item.batchSizeFulfilled),
        batchSize: item?.serialBatchNumber?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(item?.serialBatchNumber?.acquiredCost) /
            Number(item?.serialBatchNumber?.batchSize)
        ),
        serialBatchNumber: item.serialBatchNumber.serialBatchNumber,
        warehouseCode: item?.warehouseCode?.code,
        warehouseName: item?.warehouseCode?.name,
        destinationWarehouseCode: item?.destinationWarehouseCode,
        destinationWarehouseName: item?.destinationWarehouseName,
        manufacturingDate: DateFormatService.getDateStrFromDate(
          item.manufacturingDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        expiryDate: DateFormatService.getDateStrFromDate(
          item.expiryDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        row: item?.row,
        rowCode: item?.row?.rowCode ?? null,
        rowName: item?.row?.rowName ?? null,
        rack: item?.rack,
        rackCode: item?.rack?.rackCode ?? null,
        rackName: item?.rack?.rackName ?? null,
        bin: item?.bin,
        binCode: item?.bin?.binCode ?? null,
        binName: item?.bin?.binName ?? null,
        customField: item?.customField
      };
      return { ...object, advancedTrackingData: [{ ...object }] };
    });

    return batchTrackingData;
  };

  static getRequireSerialQuantity = (selectedProduct: any) => {
    return selectedProduct?.documentUOMSchemaDefinition
      ? Utility.getUomWarehouseQuantityWithoutRoundOff(
          selectedProduct?.productQuantity,
          selectedProduct?.documentUOMSchemaDefinition
        )
      : selectedProduct?.productQuantity;
  };

  static isAllNonTracked = (items: any[]) => {
    const nonTrackItems = items.filter(
      (lineItem: any) => lineItem.type === PRODUCT_TYPE.NON_TRACKED
    );
    return items.length === nonTrackItems.length;
  };
  static isSupportedCountriesForImport = (country: any) => {
    switch (country) {
      case COUNTRY_CODES.IN:
      case COUNTRY_CODES.US:
      case COUNTRY_CODES.SG:
      case COUNTRY_CODES.IL:
        //   case COUNTRY_CODES.UK:
        return true;
      default:
        return true;
    }
  };

  static isEmptyValue = (value: any) => {
    // Work for String, Object, Array, Number
    if (value === null || value === undefined || value.length === 0)
      return true;
    if (
      typeof value === 'object' &&
      Object.prototype.toString.call(value) === '[object Date]'
    )
      return false;
    if (typeof value === 'number' && value > 0) return false;
    if (Array.isArray(value) || typeof value === 'string') return !value.length;
    return Object.keys(value).length === 0;
  };

  static isEmbedPrintEnabled = () => {
    return JSON.parse(process.env.REACT_APP_ENABLE_EMBED_PRINT || 'false');
  };

  static isIsoDate = (str: string) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
  };

  static addDaysInDate = (date: Date, days: number) => {
    let duedocumentDate = DateFormatService.getDateStrFromDate(
      date || new Date(),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    let leadTimeDate = addDays(
      DateFormatService.getDateFromStr(
        duedocumentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      days
    );
    return leadTimeDate;
  };
  static incrementDateBy = (date: Date, days: string) => {
    let incrementedDate = new Date(date);
    switch (days) {
      case 'YEAR':
        incrementedDate.setFullYear(date.getFullYear() + 1);
    }
    return incrementedDate;
  };

  static alertWithRedirection = (
    alertType: any,
    alertMessage: any,
    redirectUrl: any
  ) => {
    const buttons = [
      {
        title: 'Ok',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          RouteManager.navigateToPage(redirectUrl);
        }
      }
    ];
    showAlert(alertType, alertMessage, buttons);
  };

  static isNumber(value: any) {
    return !isNaN(parseFloat(value));
  }

  static _isValidNumber(value: any) {
    return _isNumber(value);
  }

  static getPendingQtyForConversion = (item: DocumentItem) => {
    // first check if pendingQtyToConvert is present (for new documents)
    // else check for pendingQuantity (for older documents, i.e. before adding pendingQtyToConvert field)

    let pendingQty = 0;
    if (this.isNumber(item.pendingQtyToConvert)) {
      pendingQty = Number(item.pendingQtyToConvert);
    } else if (this.isNumber(item.pendingQuantity)) {
      pendingQty =
        Number(item.pendingQuantity) +
        Number(item.localizedBaseQtyDiff || 0) -
        Number(item.localizedBaseQtyDiffInBills || 0);
      if (
        item.type === PRODUCT_TYPE.NON_TRACKED &&
        item.product?.stockUom === UOM_NA_ID
      ) {
        pendingQty = 1;
      }
    } else {
      pendingQty =
        Number(item.productQuantity) +
        Number(item.localizedBaseQtyDiff || 0) -
        Number(item.localizedBaseQtyDiffInBills || 0);
    }
    return pendingQty;
  };

  static getFulfilledQtyForConversion = (item: DocumentItem) => {
    const pendingQty = this.getPendingQtyForConversion(item);

    if (pendingQty > 0 && this.isNumber(item.fulfilledQuantity)) {
      const fulfilledQty = Number(item.fulfilledQuantity);
      const convertedQty = Number(item.productQuantity) - pendingQty;

      return fulfilledQty > convertedQty
        ? Utility.roundingOff(
            fulfilledQty - convertedQty,
            QTY_ROUNDOFF_PRECISION
          )
        : pendingQty;
    }
    return pendingQty;
  };

  static getReceivedQtyForConversion = (item: DocumentItem) => {
    const pendingQty = this.getPendingQtyForConversion(item);

    if (pendingQty > 0 && this.isNumber(item.receivedQuantityInOrder)) {
      const convertedQty =
        item.productQuantity + (item.localizedBaseQtyDiff || 0) - pendingQty;
      const receivedQty =
        Number(item.receivedQuantityInOrder) +
        Number(item.localizedBaseQtyDiffReceivedInOrder || 0);

      return receivedQty > convertedQty
        ? receivedQty - convertedQty
        : pendingQty;
    }
    return pendingQty;
  };

  static getDocumentFulfilledQty(items: DocumentItem[]) {
    var fulFilledQty = 0;
    if (items.length) {
      items.forEach((item) => (fulFilledQty += item?.fulfilledQuantity || 0));
    }
    return fulFilledQty;
  }

  static getDocumentReceivedQty(items: DocumentItem[]) {
    var receivedQty = 0;
    if (items.length) {
      items.forEach(
        (item) => (receivedQty += item?.receivedQuantityInOrder || 0)
      );
    }
    return receivedQty;
  }
  static isRRBEnabled() {
    let ROW_RACK_BIN =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ROW_RACK_BIN;
    return ROW_RACK_BIN?.length > 0 ? true : false;
  }

  static isArchiveProductEnabled() {
    let ENABLE_ARCHIVE_PRODUCT =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ENABLE_ARCHIVE_PRODUCT;

    return ENABLE_ARCHIVE_PRODUCT ? true : false;
  }

  static toFixedWithoutRounding(number: number, digit: number) {
    return Math.trunc(number * Math.pow(10, digit)) / Math.pow(10, digit);
  }

  static isRRBEnabledForCurrentTenant = () => {
    const isEnabled =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings?.ROW_RACK_BIN?.some(
        (item: any) => item?.enable
      );

    return isEnabled;
  };

  static isLinkedInventoryEnabled = () => {
    return Store.getState().authInfo.currentTenantInfo.data.additionalSettings
      .LINK_INVENTORY_JOB_CARDS;
  };

  static isQuotePriceEnabled = () => {
    return Store.getState().authInfo.currentTenantInfo.data.additionalSettings
      .UPLIFT_DOWNLIFT_QUOTE_PRICE_ENABLED;
  };

  static removeEmpty(obj: Record<string, any>) {
    return Object.entries(obj).reduce(
      (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
      {}
    );
  }
  static defaultIfEmpty(value: any, defaultValue: any) {
    return this.isEmpty_v2(value) ? defaultValue : value;
  }

  static blankIfEmpty(value: any) {
    return this.isEmpty_v2(value) ? '' : value;
  }

  static getDottedText = (text: string, length: number) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  static getRandomDKBgColor(): string {
    const keys = Object.keys(BACKGROUND_COLOR_PALLETE_DARK);
    const randomKey: string = keys[Math.floor(Math.random() * keys.length)];
    return BACKGROUND_COLOR_PALLETE_DARK[
      randomKey as keyof typeof BACKGROUND_COLOR_PALLETE_DARK
    ];
  }
  static isToleranceSettingsEnabled = () => {
    let isToleranceEnabled: boolean =
      Store.getState().authInfo.currentTenantInfo?.data?.additionalSettings
        ?.TOLERANCE_ENABLED || false;
    const toleranceConfig: any =
      Store.getState().authInfo.currentTenantInfo?.data?.additionalSettings
        ?.TOLERANCE;
    if (Utility.isEmptyObject(toleranceConfig)) {
      isToleranceEnabled = false;
    }
    return isToleranceEnabled;
  };

  static isJWOReceiveDispatchAdditionalQtyEnable = () => {
    let isJWOReceiveDispatchAdditionalQty: boolean =
      Store.getState().authInfo.currentTenantInfo?.data?.additionalSettings
        ?.JWO_ADDITIONAL_QTY_ALLOWED || false;
    return isJWOReceiveDispatchAdditionalQty;
  };

  static randomIndexFromArray = (array: any[]): number => {
    return Math.floor(Math.random() * array.length);
  };

  /**
   * Generate a new array of a specified length filled with a given value.
   * @param {number} length The length of the array to generate.
   * @param {any} value The value to fill the array with.
   * @returns {Array<any>} The new array filled with the specified value.
   */
  static createFilledArray(length: number, value: any): Array<any> {
    return Array.from({ length }, () => value);
  }

  static isIndiaOrganisation = () => {
    return (
      Store.getState().authInfo.currentTenantInfo?.data?.country ===
        COUNTRY_CODES.IN || false
    );
  };

  static isToleranceEnableByDocumentType = (documentType: any) => {
    return (
      Utility.isToleranceSettingsEnabled() &&
      TOLERANCE_ENABLED_DOCUMENT.find((docType) => docType === documentType)
    );
  };

  static isSellsToleranceSettingsEnabled = (
    documentType: string = '',
    parentDocumentDetails: any
  ) => {
    if (!Utility.isEmptyObject(parentDocumentDetails)) {
      if (
        (Array.isArray(parentDocumentDetails['linkedQuotationDocuments']) &&
          parentDocumentDetails['linkedQuotationDocuments'].length > 0 &&
          !(
            parentDocumentDetails['backOrder'] &&
            Utility.checkOnlyDocumentType(
              DOC_TYPE.ORDER,
              parentDocumentDetails['linkedQuotationDocuments']
            )
          )) ||
        (Array.isArray(parentDocumentDetails['linkedSalesInvoices']) &&
          parentDocumentDetails['linkedSalesInvoices'].length > 0) ||
        (Array.isArray(parentDocumentDetails['linkedSalesInvoiceDocuments']) &&
          parentDocumentDetails['linkedSalesInvoiceDocuments'].length > 0) ||
        parentDocumentDetails['reservedStock']
      ) {
        return false;
      } else {
        return (
          Utility.isToleranceSettingsEnabled() &&
          documentType === DOC_TYPE.SALES_ORDER
        );
      }
    } else {
      return (
        Utility.isToleranceSettingsEnabled() &&
        documentType === DOC_TYPE.SALES_ORDER
      );
    }
  };

  static isPurchaseToleranceSettingsEnabled = (
    documentType: string = '',
    parentDocumentDetails: any
  ) => {
    if (!Utility.isEmptyObject(parentDocumentDetails)) {
      if (
        Array.isArray(parentDocumentDetails['linkedPurchaseInvoices']) &&
        parentDocumentDetails['linkedPurchaseInvoices'].length > 0
      ) {
        return false;
      } else {
        return (
          Utility.isToleranceSettingsEnabled() &&
          documentType === DOC_TYPE.ORDER
        );
      }
    } else {
      return (
        Utility.isToleranceSettingsEnabled() && documentType === DOC_TYPE.ORDER
      );
    }
  };

  static getPurchaseLinkedDocument = (
    documentType: string = '',
    parentDocumentDetails: any
  ) => {
    let isBillLinked = false;
    if (
      Utility.isToleranceSettingsEnabled() &&
      documentType === DOC_TYPE.ORDER &&
      !Utility.isEmptyObject(parentDocumentDetails)
    ) {
      if (
        Array.isArray(parentDocumentDetails['linkedPurchaseInvoices']) &&
        parentDocumentDetails['linkedPurchaseInvoices'].length > 0
      ) {
        isBillLinked = true;
      }
    }
    return { isBillLinked };
  };

  static getSellsLinkedDocument = (
    documentType: string = '',
    parentDocumentDetails: any
  ) => {
    let isInvoiceLinked = false,
      isQuotationLinked = false,
      isReservedQuantity = false;
    if (
      Utility.isToleranceSettingsEnabled() &&
      documentType === DOC_TYPE.SALES_ORDER &&
      !Utility.isEmptyObject(parentDocumentDetails)
    ) {
      if (
        Array.isArray(parentDocumentDetails['linkedQuotationDocuments']) &&
        parentDocumentDetails['linkedQuotationDocuments'].length > 0 &&
        !(
          parentDocumentDetails['backOrder'] &&
          Utility.checkOnlyDocumentType(
            DOC_TYPE.ORDER,
            parentDocumentDetails['linkedQuotationDocuments']
          )
        )
      ) {
        isQuotationLinked = true;
      } else if (
        Array.isArray(parentDocumentDetails['linkedSalesInvoices']) &&
        parentDocumentDetails['linkedSalesInvoices'].length > 0
      ) {
        isInvoiceLinked = true;
      } else if (
        Array.isArray(parentDocumentDetails['linkedSalesInvoiceDocuments']) &&
        parentDocumentDetails['linkedSalesInvoiceDocuments'].length > 0
      ) {
        isInvoiceLinked = true;
      } else if (parentDocumentDetails['reservedStock']) {
        isReservedQuantity = true;
      }
    }
    return { isInvoiceLinked, isQuotationLinked, isReservedQuantity };
  };

  static checkOnlyDocumentType = (
    documentType: string = '',
    documents: any
  ) => {
    const sameDocs = documents.filter(
      (doc: any) => doc.documentType === documentType
    );
    if (Array.isArray(sameDocs) && sameDocs.length === documents.length) {
      return true;
    }
    return false;
  };

  static parseDraftDocumentPayload = (payload: unknown) => {
    return Utility.isString(payload)
      ? Utility.decodeJSON(payload as string)
      : typeof payload === 'object' && Utility.isNotEmpty(payload)
      ? deepClone(payload)
      : payload;
  };

  static isProcessManufacturingAndAdhocEnabledForMRP = () => {
    return (
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings
        ?.LINK_INVENTORY_JOB_CARDS &&
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings
        ?.ADHOC_BOM?.enable
    );
  };

  static isAdhocEnabledForMRP = () => {
    return Store.getState().authInfo.currentTenantInfo.data?.additionalSettings
      ?.ADHOC_BOM?.enable;
  };

  static getInventoryTransactionsApprovalDoc = () => {
    const tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
    let options: any = [];
    if (
      Utility.isNotEmpty(tenantInfo) &&
      Utility.isNotEmpty(
        tenantInfo?.additionalSettings?.INVENTORY_APPROVAL_SETTING
      )
    ) {
      tenantInfo?.additionalSettings?.INVENTORY_APPROVAL_SETTING.forEach(
        (setting: any) => {
          let moduleName = '';
          switch (setting.module) {
            case DOC_TYPE.STOCK_ADJUSTMENT:
              moduleName = 'Stock Adjustment';
              break;
            case DOC_TYPE.STOCK_TRANSFER:
              moduleName = 'Stock Transfer';
              break;
            case DOC_TYPE.STOCK_ISSUE:
              moduleName = 'Stock Issue';
              break;
            case DOC_TYPE.STOCK_REQUEST:
              moduleName = 'Stock Request';
              break;
          }

          options.push({ id: setting.module, label: moduleName });
        }
      );
    }
    return options;
  };

  static isDraftReadOnly = (draft: any) => {
    if (draft?.approvalStatus === APPROVAL_STATUS.REJECTED) {
      return false;
    }

    const approverUsers = draft?.cells?.multiApprovalDetails?.approverUsers;
    if (!this.isEmptyObject(approverUsers)) {
      for (let level of Object.keys(approverUsers)) {
        if (!this.isEmptyObject(approverUsers[level])) {
          return true;
        }
      }
    }

    const rejectedUsers = draft?.cells?.multiApprovalDetails?.rejectedUsers;
    if (!this.isEmptyObject(rejectedUsers)) {
      for (let level of Object.keys(rejectedUsers)) {
        if (!this.isEmptyObject(rejectedUsers[level])) {
          return true;
        }
      }
    }

    return false;
  };
  static isBatchOrSerialAdvanceTracking = (products: any) => {
    return (
      products?.find(
        (bomRawProduct: any) =>
          bomRawProduct?.advancedTracking === TRACKING_TYPE.BATCH ||
          bomRawProduct?.advancedTracking === TRACKING_TYPE.SERIAL
      ) || false
    );
  };
  static filterBatchOrSerialAdvanceTracking = (products: any) => {
    return (
      products?.filter(
        (bomRawProduct: any) =>
          bomRawProduct?.advancedTracking === TRACKING_TYPE.BATCH ||
          bomRawProduct?.advancedTracking === TRACKING_TYPE.SERIAL
      ) || []
    );
  };

  static getCustomFieldColumnId = (cf: any) => {
    return `${COLUMN_TYPE.CUSTOM}_${cf.code}_${cf.id}`;
  };

  static checkSameWarehouseSelected = (
    props: any,
    selectProductJWOList: any,
    destinationWarehouse: any,
    sourceWarehouse: any
  ) => {
    const selectedWarehouseRM = props?.stockTransferData?.find(
      (stk: any) => stk.stockTransferWarehouseInventoryData
    );

    const selectedRMData = props?.stockTransferData?.find(
      (stk: any) =>
        stk?.documentSequenceCode === props.itemDetails?.documentSequenceCode
    );

    if (selectProductJWOList && selectProductJWOList.length !== 0) {
      const isExist = selectProductJWOList.find(
        (availSerial: any) =>
          availSerial.stockTransferItems &&
          availSerial.stockTransferItems.length > 0 &&
          availSerial.stockTransferItems[0].stockTransferWarehouseInventoryData
            ?.destWarehouseCode === destinationWarehouse.code
      );
      if (!isExist) {
        showAlert('Warning!', t('JOB_WORK_OUT.SAME_WAREHOUSE_ERROR'));
        return false;
      }
    } else if (
      selectedWarehouseRM &&
      selectedWarehouseRM.stockTransferWarehouseInventoryData &&
      selectedWarehouseRM.stockTransferWarehouseInventoryData
        .destWarehouseCode !== destinationWarehouse.code
    ) {
      showAlert('Warning!', t('JOB_WORK_OUT.SAME_WAREHOUSE_ERROR'));
      return false;
    } else if (
      selectedWarehouseRM &&
      selectedWarehouseRM.stockTransferWarehouseInventoryData &&
      (selectedRMData?.stockTransferWarehouseInventoryData ||
        sourceWarehouse?.RRB?.target?.row?.value?.code)
    ) {
      const row =
        sourceWarehouse?.RRB?.target?.row?.value?.code ||
        selectedRMData?.stockTransferWarehouseInventoryData?.destRowCode;
      const rack =
        sourceWarehouse?.RRB?.target?.rack?.value?.code ||
        selectedRMData.stockTransferWarehouseInventoryData?.destRackCode;
      const bin =
        sourceWarehouse?.RRB?.target?.bin?.value?.code ||
        selectedRMData.stockTransferWarehouseInventoryData?.destBinCode;

      if (
        selectedWarehouseRM.stockTransferWarehouseInventoryData.destRowCode !==
          row ||
        selectedWarehouseRM.stockTransferWarehouseInventoryData.destRackCode !==
          rack ||
        selectedWarehouseRM.stockTransferWarehouseInventoryData.destBinCode !==
          bin
      ) {
        showAlert(
          'Warning!',
          'Please select the same Target Row, Rack and Bin that was used for dispatching the other partial raw material.'
        );
        return false;
      }
    }
    return true;
  };
}

export const TOLERANCE_ENABLED_DOCUMENT = [
  DOCUMENT_TYPE.PURCHASE_ORDER,
  DOC_TYPE.ORDER,
  DOC_TYPE.SALES_ORDER
];
export function checkAndParseFloat(
  text: any,
  fractionDigit = 0,
  defaultValue?: any
): Number {
  return !isNaN(text) ? parseFloat(text).toFixed(fractionDigit) : defaultValue;
}

/**
 *
 * @param array - array from which total quantity needs to be calculated
 * @param key - key that need to be summed up
 * @returns - total of given key in array (on error returns 0)
 */
export const reduceArrayByKey = (array: any[], key: string): number => {
  try {
    if (Utility.isEmptyObject(array) || Utility.isEmptyObject(key)) {
      return 0;
    }
    return array.reduce((acc: number, item: any) => acc + Number(item[key]), 0);
  } catch (error) {
    return 0;
  }
};

export const sortSerialNumbers = (serialNumbers: any[]) => {
  if (Utility.isEmptyObject(serialNumbers)) return [];
  return serialNumbers?.sort((a: any, b: any) => a?.sequence - b?.sequence);
};

export const getUserNameById = (userId: number) => {
  const allUsers = selectUsersList(Store.getState()) || [];
  const userById = allUsers.find((user) => user.id === userId);
  return userById
    ? `${userById.firstName || ''} ${userById.lastName || ''}`
    : `Other user`;
};

export const findApprovalLevels = (docType: string) => {
  const result: Record<string, string> = {};
  let inputData: any[] = Store.getState().automation?.data;

  const levelHandled = new Set();
  inputData.forEach((item) => {
    item.fields.forEach((field: any) => {
      if (field.field_type === docType) {
        field.multiApprovalDetails?.forEach((approvalDetail: any) => {
          if (!levelHandled.has(approvalDetail.level)) {
            const key = `${field.field_type}_PENDING_${approvalDetail.level}_${approvalDetail.levelLabel}`;
            const rejectedKey = `${field.field_type}_REJECTED_${approvalDetail.level}_${approvalDetail.levelLabel}`;
            result[key] = `Pending at ${approvalDetail.levelLabel}`;
            result[rejectedKey] = `Rejected at ${approvalDetail.levelLabel}`;
            levelHandled.add(approvalDetail.level);
          }
        });
      }
    });
  });

  const sortedResult: Record<string, string> = {};
  Object.keys(result)
    .sort((a, b) => {
      if (a.includes('REJECTED') && b.includes('PENDING')) {
        return -1;
      }
      if (a.includes('PENDING') && b.includes('REJECTED')) {
        return 1;
      }
      return 0;
    })
    .forEach((key) => {
      sortedResult[key] = result[key];
    });

  return sortedResult;
};

export const getApprovalLevelAndLevelLabel = (key: string) => {
  const values = key.split('_');
  return {
    level: values[values.length - 2],
    levelLabel: values[values.length - 1]
  };
};

export const getPrecedingURLForEmailBody = (rfqCode: any) => {
  const commonURLString = 'public/quotation-form/';
  if (Utility.isMRPWithURLCheck()) {
    switch (process.env.REACT_APP_ENV_NAME) {
      case 'development':
        return `${process.env.REACT_APP_URL_MRP}/${commonURLString}${rfqCode}`;

      case 'prestaging':
        return `${process.env.REACT_APP_URL_MRP}/${commonURLString}${rfqCode}`;

      case 'staging':
        return `${process.env.REACT_APP_URL_MRP}/${commonURLString}${rfqCode}`;

      case 'production':
        return `${process.env.REACT_APP_URL_MRP}/${commonURLString}${rfqCode}`;

      default:
        return `${process.env.REACT_APP_URL_MRP}/${commonURLString}${rfqCode}`;
    }
  } else {
    switch (process.env.REACT_APP_ENV_NAME) {
      case 'development':
        return `${process.env.REACT_APP_ERP_URL}${commonURLString}${rfqCode}`;

      case 'prestaging':
        return `${process.env.REACT_APP_ERP_URL}${commonURLString}${rfqCode}`;

      case 'staging':
        return `${process.env.REACT_APP_ERP_URL}${commonURLString}${rfqCode}`;

      case 'production':
        return `${process.env.REACT_APP_ERP_URL}${commonURLString}${rfqCode}`;

      default:
        return `${process.env.REACT_APP_ERP_URL}${commonURLString}${rfqCode}`;
    }
  }
};

export const getDateDifferenceFromIso = (givenDate: string) => {
  let tempDate = DateFormatService.getDateFromISOStr(givenDate);
  let dueStatusNumber = differenceInDays(tempDate, new Date());
  if (dueStatusNumber > 0) {
    return false;
  }
  return true;
};

export const parseDateFromStringForTimezoneDiff = (dateStr: string) => {
  if (!Utility.isValidDate(dateStr)) return '';

  try {
    /** Receiving date in below format in staging & Prod */
    if (new RegExp(REGEX.WO_TZ_DATE_FORMAT).test(dateStr)) {
      /* Using date fns lib to get date without timezone,
      to avoid ZEN-12233 */
      const date = DateFormatService.getDateFromStr(
        dateStr.split('T')[0],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      return date;
    } else if (dateStr.includes('T')) {
      return new Date(dateStr);
    } else {
      const date = DateFormatService.getDateFromStr(
        dateStr,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );

      return date;
    }
  } catch (err) {
    return new Date(dateStr);
  }
};

export const isLockedDocumentAfterApprovalAdvancedSettingOn = (
  documentType: any
) => {
  const LockedDocumentAfterApproval: any =
    Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
      ?.LOCK_DOCUMENT_AFTER_APPROVAL;
  switch (documentType) {
    case DOC_TYPE.ORDER:
      return LockedDocumentAfterApproval?.PURCHASE_ORDER === true;
    case DOCUMENT_TYPE.SALES_ORDER:
      return LockedDocumentAfterApproval?.SALES_ORDER === true;
    default:
      return false;
  }
};

export const getSelectedLanguage = () => {
  const languageSelect: any = document.querySelector('.goog-te-combo');
  if (languageSelect) {
    return languageSelect?.value; // Returns the language code, e.g., 'en' or 'zh-CN'
  }
  return null; // If the element is not found
};

export const isDocumentLocked = (documentData: any) => {
  const userEmail = Store?.getState()?.authInfo.userInfo.data[0]?.email;
  const isLockedDocAfterApprovalAdvancedSettingOn: any =
    isLockedDocumentAfterApprovalAdvancedSettingOn(documentData.documentType);
  let finalApproverEmail = '';

  if (!Utility.isEmpty(documentData?.multiApprovalDetails?.approvalHistory)) {
    let approvalHistory =
      documentData?.multiApprovalDetails?.approvalHistory.filter(
        (approvalData: any) =>
          approvalData?.status.toLowerCase() ===
          APPROVAL_STATUS.APPROVED?.toLowerCase()
      );
    finalApproverEmail = !Utility.isEmpty(approvalHistory)
      ? approvalHistory?.reduce((maxi: any, obj: any) =>
          obj?.level > maxi?.level ? obj : maxi
        )?.approvedBy
      : '';
  }

  if (
    Utility.isEmpty(finalApproverEmail) ||
    Utility.isEmpty(userEmail) ||
    Utility.isEmpty(documentData?.approvalStatus)
  ) {
    return false;
  }

  return (
    isLockedDocAfterApprovalAdvancedSettingOn &&
    finalApproverEmail !== userEmail &&
    documentData?.approvalStatus?.toLowerCase() ===
      APPROVAL_STATUS.APPROVED?.toLowerCase()
  );
};
